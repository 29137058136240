import { useMemo } from 'react';
import { useNavigate, NavigateOptions } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();

  const router = useMemo(
    () => ({
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      push: (href: string, state?: NavigateOptions) =>
        /^((http|https|ftp):\/\/)/.test(href)
          ? window.location.replace(href)
          : state
            ? navigate(href, state)
            : navigate(href),
      replace: (href: string) =>
        /^((http|https|ftp):\/\/)/.test(href)
          ? window.location.replace(href)
          : navigate(href, { replace: true }),
    }),
    [navigate]
  );

  return router;
}

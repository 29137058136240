/* eslint-disable react/style-prop-object */
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function TotalAssociatedIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 1080 1080"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <svg
        id="uuid-7f8ef70d-291f-4122-95ea-85d434c963d0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1080 1080"
      >
        <defs>
          <clipPath id="uuid-014bceb9-10a8-48e8-96dc-e3d467de96c2">
            <circle cx="540" cy="540" r="490" style={{ fill: 'none' }} />
          </clipPath>
          <clipPath id="uuid-992f1a2c-5b74-44e6-8337-4f68dc304aa8">
            <path
              d="m559.4,1030c270.62,0,490-219.38,490-490S830.02,50,559.4,50,69.4,269.38,69.4,540s219.38,490,490,490"
              style={{ fill: 'none' }}
            />
          </clipPath>
        </defs>
        <g style={{ clipPath: 'url(#uuid-014bceb9-10a8-48e8-96dc-e3d467de96c2)', fill: 'none' }}>
          <path
            d="m379.42,231.43s28.47-6.8,55.69,28.64c27.37,35.64,22.33,48.56,30.23,60.78,13.89,21.48,39.11,26.28,40.42,41.37,1.66,19.17-6.17,15.82-2.23,25.46,3.94,9.64,21.62,15.45,23.23,27.69,3.04,23.16-15.55,15.43-2.86,28.64,11.46,11.93,15.61,38.48-13.84,34.85-8.28-1.02,15.75,21.96-18.62,37.23-32.01,14.23-99.41,25.8-153.46,17-54.05-8.8-63.55-32.75-63.55-32.75,0,0-14.88,4.96-28.08-5.9-13.19-10.85,7.46-30.86-.15-33.47-7.62-2.61-14.25-2.33-18.06-13.36-5.9-17.06,11.27-24.85,5.46-29.46-5.81-4.61-7.55-14.79.38-23.43,7.49-8.16,18.23-7.27,10.92-14.59-7.32-7.32-5.79-25.93,12.76-38.13,11.25-7.4,21.84-13.52,25.65-35.16,3.81-21.64,12.09-61.1,55.18-74.89,18.73-5.99,40.92-.54,40.92-.54h0Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m446.04,797l-186.84-.49s-9.64,56.38-18.05,100.34c-3.96,20.73-12.07,63.44-13.88,76-2.38,16.44-35.7,268.1-35.7,268.1,0,0-3.6,57.79-5.64,77.59-7.62,73.91-53.84,273.79-53.84,273.79l66.84,5.42s58.43-152.37,70.53-218.14c4.51-24.49,23.4-114.54,23.4-114.54l70.74-228.14c7.61,68.46,17.11,155.16,21.28,197.94,1.45,14.88,3.09,60.43,3.09,60.43l-6.13,315.82,48.23,1.74s51.46-196.51,51.46-279.18c0-25.26,4.34-83.83,4.34-83.83,0,0,26.49-247.89,26.49-282.51s-66.32-170.34-66.32-170.34Z"
            style={{ fill: '#fff' }}
          />
          <path
            d="m484.78,496.2s-102.47-66.41-111.92-64.81c-30.19,5.09-101.2,64.81-101.2,64.81,0,0-27.93,67.71-29.21,103.35-1.8,49.82,26.02,208.27,26.02,208.27l178.68-.87,37.63-310.75Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <polygon
            points="449.94 832.84 442.99 804.13 265.12 804.13 261.64 832.84 449.94 832.84"
            style={{ fill: '#232b35' }}
          />
          <polygon
            points="402.57 804.13 394.58 804.13 396.43 832.84 404.42 832.84 402.57 804.13"
            style={{ fill: '#e6e6e6' }}
          />
          <polygon
            points="299.94 804.13 298.09 832.84 305.47 832.84 307.31 804.13 299.94 804.13"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m357.04,544.98c-20.77-1.85-35.82-25.31-35.82-56.62,0-15.35,5.3-29.06,10.91-39.39,16.13-9.03,29.96-15.76,40.74-17.58,3.46-.58,19.58,6.76,38.64,16.29,3.05,13.37,5.13,27.8,0,45.79-9,31.55-33.33,53.39-54.46,51.51h0Z"
            style={{ fill: '#febca5' }}
          />
          <path
            d="m359.64,546.2c-.9,0-1.8-.04-2.7-.12-21.34-1.91-36.83-26.18-36.83-57.72h2.21c0,30.37,14.64,53.72,34.81,55.52.84.08,1.69.11,2.54.11,20.53,0,42.45-21.69,50.76-50.82,4.76-16.67,3.44-30.14-.01-45.24l2.16-.49c3.52,15.43,4.87,29.21-.02,46.34-8.57,30.05-31.42,52.42-52.92,52.42Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m422.33,352.99c-5.04-40.09-43.74-59.2-45.5-85.64-10.77,22.79-69.22,23.26-67.74,64.48.53,14.65-.83,30.04,3.1,42.72,7.01,22.61,18.72,38.37,30.06,47.04l-4.06,45.59,71.23.32-5.33-63.39c9.27-13.05,16.04-30.55,18.24-51.13Z"
            style={{ fill: '#febca5' }}
          />
          <path
            d="m515.96,914.79s-63.99-24.36-90.45-124.63c-9.57-36.18-12.05-164.86-10.02-209.45,2.33-51.17-5-112.68-7.73-133.26,0-.04,0-.1-.02-.15l-.86-11.15c5.14,3.36,59.99,43.34,99.54,63.49,4.11,28.81-36.59,166.62-39.03,230.07-.86,21.86,48.58,185.08,48.58,185.08Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m533.92,942.24c3.74-2.82-2.79-17.77-14.59-33.4-11.79-15.62-24.82-24.26-28.56-21.44-3.74,2.82,3.23,16.03,15.02,31.66,11.79,15.62,24.38,26,28.12,23.18h0Z"
            style={{ fill: '#252a4f' }}
          />
          <path
            d="m340.35,439.99l-.22,5.57c-7.24,13.14-35.17,80.37-40.08,139.29-4.09,49.17-8.9,156.9-10.52,190.35-2.63,54.44-15.79,135.74-30.32,165.61-8.59,17.68-36.76,28.24-37.64,28.55.53-2.33,41.68-179.18,39.54-240.7-1.95-56.18-17.26-119-14.74-138.76,2.5-19.76-.34-86.04,16.1-95.17,20.85-11.59,69.27-48.78,77.88-54.74Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m130.43,623.02l-54.07-78.18c-1.79-2.58-4.72-4.12-7.86-4.12H23.72c-2.38,0-3.78,2.67-2.43,4.63l57.6,83.29h48.59c2.89,0,4.58-3.24,2.94-5.61h0Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m515.95,915.89c-.13,0-.26-.02-.39-.07-.65-.24-64.8-25.72-91.12-125.37-9.66-36.58-12.04-166.1-10.05-209.78,2.81-61.67-7.15-136.18-7.25-136.92-.08-.6.34-1.16.95-1.24.61-.1,1.16.34,1.25.95.1.75,10.09,75.45,7.27,137.32-1.99,43.58.37,172.72,9.98,209.11,13.59,51.47,37.28,82.41,54.75,99.3,18.93,18.3,34.86,24.51,35.02,24.57.57.22.86.86.64,1.43-.17.44-.59.71-1.03.71Z"
            style={{ fill: '#252a4f' }}
          />
          <path
            d="m130.46,688.15c3.43-14.86-.4-27-4.16-27.87-3.77-.87-8.74,9.14-9.65,26.43-.8,15.23-.69,26.22.73,26.95,3.44,1.76,9.65-10.64,13.08-25.5h0Z"
            style={{ fill: '#252a4f' }}
          />
          <path
            d="m131.3,667.43l-16.32-10.36-3.97-30.87s.65-6.28,2.51-12.32c1.25-4.07,3.72-7.57,4.36-10.66.5-2.41.59-10.16-4.54-9.9,3.28-11.27-1.33-11.71-4.35-12.48,1.77-6.09-.97-8.74-3.55-9.39-7.59-1.92-26.06,31.74-26.06,31.74,0,0-2.03.29-1.1-2.98.92-3.28,5.21-12.99,7.15-17.39,2.08-4.71,7.76-11.17,8.95-14.01,3.65-8.71-2.08-12.94-4.75-11.39-8.25,4.79-25.27,37.79-26.48,52.92-1.41,17.63,14.78,54.54,22.83,63.9,8.25,9.59,38.27,33.55,38.27,33.55l7.05-40.35Z"
            style={{ fill: '#febca5' }}
          />
          <path
            d="m222.21,969.98c-.45,0-.88-.28-1.04-.73-.21-.58.09-1.21.67-1.42.28-.1,27.93-10.14,36.37-27.5,14.55-29.92,27.59-111.06,30.21-165.18l.75-15.81c2.01-42.7,6.2-131.55,9.78-174.57,5.23-62.91,37.9-136.67,39.29-139.78.25-.56.9-.81,1.46-.56.56.25.81.9.56,1.46-1.38,3.09-33.91,76.52-39.1,139.06-3.57,42.98-7.76,131.81-9.77,174.49l-.75,15.82c-2.62,54.33-15.76,135.88-30.43,166.04-8.84,18.18-36.45,28.2-37.62,28.62-.12.04-.25.06-.37.06Z"
            style={{ fill: '#252a4f' }}
          />
          <polygon
            points="498.16 886.08 484.78 909.72 511.81 961.44 526.45 906.42 498.16 886.08"
            style={{ fill: '#febca5' }}
          />
          <path
            d="m506.35,499.68s13.75,6.95,21.2,28.35c4.74,13.63,46.45,197.03,46.45,218.86,0,72.07-39.58,195.45-39.58,195.45,0,0-10.62-44.13-43.63-55.21,10.44-53.57,13.18-137.84,13.18-137.84l-32.76-105.19,35.15-144.41Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m264.04,493.87s-28.46,10.29-35.14,31.3c-3.92,12.33-14.17,110-19.16,159.12l-83.4-23.99s2.58,7.93,0,25.21c-3.34,22.39-8.96,28.18-8.96,28.18,0,0,133.37,78.47,147.69,41.51,14.32-36.96-1.03-261.32-1.03-261.32Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m89.01,612.38c-.13,0-.25-.02-.38-.07-.57-.21-.87-.84-.66-1.42,4.18-11.49,11.17-21.92,20.22-30.13.45-.41,1.15-.38,1.56.08.41.45.38,1.15-.08,1.56-8.78,7.98-15.57,18.09-19.63,29.25-.16.45-.59.73-1.04.73Z"
            style={{ fill: '#e48779' }}
          />
          <path
            d="m101.16,618.75c-.09,0-.19-.01-.28-.04-.59-.16-.94-.76-.79-1.35,2.13-8.03,5.39-18.07,11.82-24.5.43-.43,1.13-.43,1.56,0,.43.43.43,1.13,0,1.56-6.06,6.06-9.19,15.75-11.25,23.51-.13.5-.58.82-1.07.82Z"
            style={{ fill: '#e48779' }}
          />
          <path
            d="m352.37,834.92c-8.97,0-16.26-7.37-16.26-16.44s7.29-16.44,16.26-16.44,16.26,7.37,16.26,16.44-7.29,16.44-16.26,16.44Zm0-29.57c-7.14,0-12.96,5.89-12.96,13.14s5.81,13.13,12.96,13.13,12.96-5.89,12.96-13.13-5.81-13.14-12.96-13.14Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m362.74,429.7c-6.45,0-12.78-1.65-18.21-5.12-.51-.33-.67-1.01-.34-1.53.33-.51,1.02-.66,1.53-.34,14.23,9.09,35.14,4.85,46.6-9.45.38-.48,1.08-.55,1.55-.17.48.38.55,1.08.17,1.55-7.8,9.73-19.75,15.05-31.31,15.05Z"
            style={{ fill: '#e48779' }}
          />
          <path
            d="m478.93,667.45c-.07,0-.14,0-.21-.02-.6-.12-.99-.7-.87-1.3,3.31-16.99,6.52-35.92,9.61-54.23,6.45-38.13,12.54-74.14,19.02-89.75.23-.57.88-.84,1.45-.6.56.23.83.88.6,1.44-6.38,15.39-12.46,51.28-18.88,89.28-3.1,18.32-6.31,37.27-9.63,54.28-.1.53-.57.89-1.08.89Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m260.88,755.88s-.05,0-.07,0c-.61-.04-1.07-.57-1.03-1.18,1.84-27.86-2.26-52.23-6.59-78.03-3.5-20.85-7.12-42.41-7.82-67.2-.79-28.07,2.73-56.86,10.48-85.57.16-.59.77-.94,1.36-.78.59.16.94.77.78,1.36-7.69,28.49-11.19,57.07-10.41,84.93.7,24.64,4.3,46.12,7.79,66.89,4.36,25.93,8.47,50.43,6.62,78.55-.04.59-.52,1.03-1.1,1.03Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m242.37,716.86c-.49,0-.93-.33-1.07-.82-2.73-10.16-16.7-25.12-30.61-29.18-.59-.17-.92-.78-.75-1.37.17-.59.79-.93,1.37-.75,14.83,4.32,29.19,19.79,32.13,30.73.16.59-.19,1.2-.78,1.35-.1.03-.19.04-.29.04Z"
            style={{ fill: '#252a4f' }}
          />
          <path
            d="m419.16,714.07c-.07,0-.14,0-.22-.02-.6-.12-.99-.7-.87-1.3l31.26-158.27-15.22-5.17c-.39-.13-.67-.47-.74-.88-.06-.41.1-.82.44-1.06l13.51-9.98c-1.35-6.02-11.74-50.18-34.92-88.75-.32-.52-.15-1.2.38-1.52.52-.31,1.2-.14,1.52.38,25.01,41.61,35.24,89.65,35.34,90.13.09.42-.08.86-.43,1.12l-12.38,9.15,14.14,4.8c.52.18.84.72.73,1.26l-31.45,159.22c-.1.53-.57.89-1.08.89Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m290.81,714.07c-.55,0-1.03-.41-1.1-.97l-19.63-164.7c-.07-.6.35-1.15.95-1.23l14.21-1.94-11.29-5.79c-.52-.27-.74-.89-.51-1.43.18-.41,18.42-41.49,50.57-83.66.37-.49,1.06-.58,1.55-.21.49.37.58,1.07.21,1.55-29.27,38.4-46.95,75.88-49.87,82.26l13.48,6.92c.43.22.67.69.59,1.17-.08.48-.46.84-.94.91l-16.62,2.27,19.5,163.62c.07.61-.36,1.16-.97,1.23-.04,0-.09,0-.13,0Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m365.69,792.38c-10.57,0-25.21-12.99-32.13-20.69-9.86-10.96-13.72-26.55-17.13-40.3-2.98-12.03-5.8-23.39-12.07-29.36-.44-.42-.46-1.12-.04-1.56.42-.44,1.12-.46,1.56-.04,6.74,6.41,9.63,18.08,12.69,30.43,3.35,13.5,7.14,28.8,16.63,39.35,8.64,9.6,24.67,22.71,33.42,19.47.57-.21,1.21.08,1.42.65.21.57-.08,1.21-.65,1.42-1.16.43-2.4.63-3.7.63Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m341.93,792.39c-.07,0-.14,0-.22-.02-18.61-3.72-36.67-10.05-42.73-31.03-.17-.59.17-1.2.76-1.37.58-.18,1.2.17,1.37.76,5.73,19.84,23.11,25.89,41.04,29.47.6.12.99.7.87,1.3-.11.53-.57.89-1.08.89Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m358.42,960.67h-.01c-.61,0-1.1-.51-1.09-1.12l1.59-122.2c0-.61.56-1.09,1.12-1.09.61,0,1.1.51,1.09,1.12l-1.59,122.2c0,.61-.5,1.09-1.11,1.09Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m358.42,934.58c-.12,0-.25-.02-.38-.07-.35-.13-8.53-3.19-13.67-13.93-5.05-10.56-4.28-81.19-4.24-84.19,0-.61.49-1.07,1.12-1.09.61,0,1.1.51,1.09,1.12,0,.73-.8,73.1,4.03,83.21,4.72,9.88,12.35,12.78,12.43,12.81.57.21.87.84.66,1.42-.16.45-.59.73-1.04.73Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m401.04,333.16c-.44,0-.88-.18-1.21-.52-.45-.49-11.21-11.8-21.88-5.03-.77.49-1.79.26-2.28-.51-.49-.77-.26-1.79.51-2.28,13-8.25,25.94,5.43,26.06,5.57.62.67.58,1.71-.09,2.33-.32.3-.72.44-1.12.44Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m317.83,333.17c-.39,0-.78-.13-1.09-.41-.68-.6-.75-1.65-.15-2.33.11-.13,11.6-12.96,24.43-5.4.79.46,1.05,1.47.59,2.26-.46.79-1.47,1.05-2.26.59-10.5-6.18-20.18,4.63-20.28,4.74-.33.37-.78.56-1.24.56Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m358.85,379.95c-3.6,0-8.57-.87-10.78-4.17-1.42-2.11-.62-4.81.71-9.27.68-2.3,1.54-5.17,2.32-8.73,1.36-6.18.3-15.44.29-15.54-.07-.61.36-1.16.97-1.23.61-.08,1.16.36,1.23.97.05.39,1.12,9.73-.33,16.27-.8,3.63-1.67,6.54-2.36,8.88-1.14,3.84-1.83,6.16-.99,7.41,1.92,2.86,7.43,3.42,10.66,3.11.62-.05,1.15.39,1.21.99.06.61-.39,1.15-.99,1.21-.57.06-1.23.09-1.94.09Z"
            style={{ fill: '#e48779' }}
          />
          <path
            d="m372.94,390.67c-3.58-2.39-4.89-3.7-8.23-4.17-1.54-.22-3.3.53-4.55,1.9-.68.75-1.82.88-2.55.18-1.39-1.33-3.38-1.93-4.75-1.68-2.4.43-3.83,2.67-5.38,4.62v.69c.29,5.59,4.89,10.59,11.67,11.16,7.48.64,13.81-7.12,14.16-12.33l-.36-.36Z"
            style={{ fill: '#dc6d6e' }}
          />
          <path
            d="m358.25,395.06c-4.54,0-9.34-.94-13.74-3.67-.52-.32-.68-1-.36-1.52.32-.52,1-.68,1.52-.36,12.89,7.98,30.39-1.04,30.57-1.13.54-.28,1.21-.08,1.49.46.28.54.08,1.21-.47,1.49-.52.27-9.15,4.72-19.02,4.72Z"
            style={{ fill: '#bd5a5d' }}
          />
          <path
            d="m328.33,338.12h-.88c-1.36,1.3-2.18,3.79-1.97,6.61.31,3.99,2.55,7.07,5.01,6.88,2.46-.19,4.21-3.57,3.91-7.56-.03-.36-.07-.71-.13-1.06-.48-2.85-3.06-4.87-5.95-4.87Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m336.13,344.01c-.33,0-.66-.15-.88-.43-2.08-2.7-4.69-4.28-7.56-4.58-2.63-.29-4.97.57-6.04,1.48-.47.4-1.17.34-1.56-.13-.39-.47-.34-1.16.13-1.56,1.47-1.24,4.41-2.34,7.7-1.99,2.43.26,5.97,1.41,9.08,5.43.37.48.28,1.18-.2,1.55-.2.15-.44.23-.67.23Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m391.89,337.62h-3.07c-1.67.97-3.02,3.38-3.3,6.33-.39,3.98,1.29,7.4,3.75,7.64,2.46.24,4.77-2.79,5.16-6.78.32-3.28-.77-6.14-2.53-7.2Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m381.5,344.17c-.25,0-.5-.08-.71-.26-.47-.39-.53-1.09-.14-1.56,3.68-4.38,7.3-5.36,9.69-5.41,3.26-.12,5.87,1.47,7.21,2.94.41.45.38,1.15-.07,1.56s-1.15.38-1.56-.07c-.97-1.06-3.01-2.25-5.53-2.22-2.81.06-5.52,1.62-8.04,4.63-.22.26-.53.39-.85.39Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m304.8,315.74c-.12,0-.24-.02-.35-.06-.58-.19-.89-.82-.69-1.4,6.31-18.72,15.53-30.28,31.83-39.88,4.98-2.93,10.79-5.06,16.42-7.11,11.5-4.2,22.36-8.16,26.4-18.44.22-.57.87-.85,1.43-.62.57.22.85.87.63,1.44-4.4,11.2-16.24,15.52-27.7,19.71-5.53,2.02-11.25,4.11-16.05,6.94-15.79,9.31-24.73,20.51-30.86,38.68-.15.46-.59.75-1.05.75Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m302.05,315.99c-.52,0-.98-.37-1.08-.9-1.82-9.4.1-40.34,31.76-58.53,8.74-5.02,17.49-5.89,25.2-6.66,8-.8,14.9-1.49,20.45-6.26.46-.4,1.16-.35,1.56.12.4.46.35,1.16-.12,1.56-6.08,5.23-13.65,5.99-21.67,6.79-7.86.79-16,1.6-24.32,6.38-30.55,17.55-32.44,47.19-30.69,56.19.12.6-.28,1.18-.88,1.3-.07.01-.14.02-.21.02Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m431.3,342.01c-.58,0-1.07-.45-1.1-1.04-1.92-31.89-17.18-45.58-30.64-57.67-11.05-9.92-20.6-18.49-20.6-34.69,0-.61.5-1.11,1.11-1.11s1.11.5,1.11,1.11c0,15.21,9.2,23.48,19.86,33.04,13.78,12.37,29.4,26.39,31.37,59.18.04.61-.43,1.13-1.04,1.17-.02,0-.04,0-.07,0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m442.94,430.25c-.21,0-.42-.06-.61-.18-.51-.34-.65-1.02-.31-1.53,7.48-11.26,10.45-19.91,9.1-26.42-1.37-6.6-7.06-10.29-12.56-13.85-4.06-2.63-7.89-5.12-10.04-8.69-2.63-4.37-2.71-8.89-2.79-13.26-.06-3.49-.13-7.11-1.51-10.68-.22-.57.06-1.21.64-1.43.57-.22,1.21.06,1.43.63,1.51,3.94,1.58,7.75,1.65,11.43.08,4.27.15,8.3,2.48,12.16,1.88,3.13,5.51,5.48,9.35,7.97,5.61,3.64,11.97,7.76,13.53,15.26,1.48,7.13-1.6,16.32-9.42,28.1-.21.32-.56.49-.92.49Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m466.31,468.3c-.39,0-.76-.2-.96-.56-.3-.53-.11-1.21.42-1.51,3.46-1.96,5.94-5.47,6.61-9.39.68-3.92-.47-8.06-3.07-11.07-1.23-1.42-2.72-2.57-4.3-3.78-.93-.71-1.89-1.45-2.79-2.24-2.23-1.97-4.84-5.12-4.64-8.98.03-.61.55-1.04,1.16-1.05.61.03,1.08.55,1.05,1.16-.15,2.98,2.04,5.56,3.9,7.21.86.76,1.77,1.45,2.67,2.15,1.6,1.23,3.26,2.49,4.63,4.09,3.03,3.51,4.37,8.33,3.58,12.9-.79,4.57-3.67,8.66-7.7,10.94-.17.1-.36.14-.54.14Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m483.95,403.63c-.41,0-.81-.23-1-.63-.26-.55-.03-1.21.52-1.47,3.04-1.45,5.27-4.42,5.83-7.75.56-3.32-.59-6.86-2.99-9.23-1.97-1.95-4.58-3.04-7.35-4.2-.72-.3-1.44-.6-2.14-.92-4.79-2.15-7.6-4.8-8.58-8.11-.17-.59.16-1.2.75-1.38.59-.17,1.2.16,1.38.75.79,2.65,3.2,4.85,7.37,6.72.69.31,1.39.6,2.09.9,2.84,1.19,5.77,2.42,8.05,4.67,2.9,2.87,4.29,7.14,3.61,11.17-.68,4.02-3.38,7.61-7.06,9.38-.15.07-.32.11-.48.11Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m483.31,433.83c-.32,0-.64-.14-.86-.41-.39-.47-.31-1.17.16-1.56,2.39-1.94,3.36-5.66,2.37-9.04-.87-2.97-3.15-5.79-6.42-7.94-3.02-1.99-6.54-3.26-9.95-4.49-.57-.21-.87-.84-.66-1.42.21-.57.84-.87,1.42-.66,3.53,1.27,7.17,2.59,10.41,4.72,3.7,2.43,6.31,5.69,7.33,9.17,1.24,4.22-.03,8.89-3.09,11.38-.21.17-.45.25-.7.25Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m286.63,459.69c-.07,0-.14,0-.21-.02-3.71-.7-6.55-3.56-7.61-7.67-1.45-5.64.39-14.46,9.91-22.32.55-.45,1.23-.95,2-1.5,4.43-3.22,11.13-8.08,8.8-17.08-.15-.59.2-1.2.79-1.35.59-.15,1.19.2,1.35.79,2.71,10.47-5.02,16.07-9.64,19.43-.72.52-1.37.99-1.89,1.42-8.72,7.2-10.46,15.08-9.18,20.06.84,3.25,3.03,5.51,5.87,6.05.6.11,1,.69.88,1.29-.1.53-.56.9-1.09.9Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m279.14,406.01c-.38,0-.74-.19-.95-.54-4.96-8.3-3.3-19.96,3.78-26.54,1.19-1.11,2.51-2.08,3.78-3.01,1.68-1.23,3.26-2.39,4.57-3.85,2.13-2.37,3.37-6.08,1.6-8.59-.35-.5-.23-1.19.27-1.54.5-.35,1.19-.23,1.54.27,2.44,3.45.95,8.32-1.77,11.34-1.46,1.63-3.21,2.91-4.91,4.15-1.28.94-2.48,1.82-3.58,2.85-6.35,5.9-7.84,16.35-3.39,23.78.31.52.14,1.2-.38,1.52-.18.11-.37.16-.57.16Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m257.69,457.4c-.24,0-.48-.08-.69-.24-3.28-2.61-6.29-5.27-7.07-9.11-.71-3.5.73-7.48,3.85-10.65,2.1-2.12,4.67-3.71,7.16-5.24,3.66-2.25,7.11-4.38,8.98-8.01.28-.54.95-.76,1.49-.48.54.28.76.95.48,1.49-2.15,4.18-6.03,6.57-9.79,8.89-2.38,1.47-4.84,2.98-6.75,4.91-2.61,2.64-3.82,5.87-3.26,8.65.64,3.12,3.33,5.48,6.28,7.82.48.38.56,1.08.18,1.55-.22.28-.54.42-.87.42Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m511.02,746.18c-.27,0-.55-.1-.76-.3-.44-.42-.46-1.12-.04-1.56,8.68-9.16,23.09-12.72,35.04-8.65.58.2.89.83.69,1.4-.2.58-.82.88-1.4.69-11.16-3.8-24.62-.48-32.72,8.08-.22.23-.51.34-.8.34Z"
            style={{ fill: '#252a4f' }}
          />
          <path
            d="m515.95,735.81c-.21,0-.42-.06-.61-.18-.51-.34-.65-1.02-.31-1.53,4.2-6.35,10.83-11.14,18.19-13.12.59-.16,1.2.19,1.36.78.16.59-.19,1.2-.78,1.36-6.84,1.85-13.01,6.3-16.92,12.21-.21.32-.57.5-.92.5Z"
            style={{ fill: '#252a4f' }}
          />
          <path
            d="m488.56,887.4c-.07,0-.14,0-.21-.02-.6-.11-1-.69-.88-1.29.96-5.1,2.11-10.78,3.36-16.98,1.47-7.3,3.15-15.56,4.8-24.35.11-.6.68-.99,1.29-.88.6.11,1,.69.88,1.29-1.65,8.8-3.33,17.08-4.8,24.38-1.25,6.19-2.4,11.86-3.35,16.95-.1.53-.56.9-1.09.9Z"
            style={{ fill: '#252a4f' }}
          />
          <path
            d="m408.74,1592.13s-.07,0-.1,0c-.61-.06-1.06-.59-1-1.2.6-6.52,1.41-15.2,2.37-25.39,6.03-64.15,17.25-183.55,16.8-213.03-.48-31.93-.98-54.93-1.45-77.18-.47-22.21-.97-45.18-1.45-77.04-.25-16.53-.24-30.94-.24-43.64.02-34.38.03-61.54-4.89-105.42-.07-.61.37-1.15.98-1.22.62-.04,1.15.37,1.22.98,4.93,44,4.92,71.21,4.9,105.66,0,12.7-.01,27.1.24,43.61.48,31.86.97,54.82,1.45,77.03.48,22.25.97,45.26,1.45,77.2.45,29.59-10.78,149.08-16.81,213.26-.96,10.19-1.77,18.87-2.37,25.38-.05.57-.54,1-1.1,1Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m157.29,1579.33c-.11,0-.21-.02-.32-.05-.59-.18-.92-.79-.74-1.38,18.26-60.88,26.31-107.5,34.84-156.86,3.5-20.3,7.13-41.29,11.59-64.01,3.22-16.42,4.15-29.02,5.04-41.22.96-13.04,1.95-26.52,5.8-44.29,9.17-42.3,16.31-79.27,23.22-115.03,7.43-38.46,15.12-78.24,25.47-125.56.13-.6.72-.98,1.32-.84.6.13.97.72.84,1.32-10.35,47.3-18.03,87.06-25.46,125.51-6.91,35.77-14.06,72.75-23.23,115.08-3.82,17.62-4.76,30.43-5.76,43.99-.9,12.26-1.83,24.93-5.08,41.48-4.45,22.69-8.07,43.67-11.58,63.96-8.53,49.42-16.59,96.11-34.9,157.12-.14.48-.58.79-1.06.79Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m339.22,819.21c-.8,0-1.51-.59-1.63-1.4-.14-.9.48-1.74,1.38-1.88,3.15-.48,6.33-.48,9.47,0,.9.14,1.52.98,1.38,1.88-.14.9-.97,1.52-1.88,1.39-2.81-.42-5.66-.43-8.48,0-.08.01-.17.02-.25.02Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m364.15,1039.91c-.56,0-1.04-.42-1.1-.99l-6.37-60.46c-.06-.61.38-1.15.98-1.22.61-.05,1.15.38,1.22.98l6.37,60.46c.06.61-.38,1.15-.98,1.22-.04,0-.08,0-.12,0Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m511.81,962.54c-.4,0-.79-.22-.99-.61l-37.33-73.93c-.28-.55-.06-1.21.49-1.49.55-.28,1.21-.06,1.49.49l37.33,73.93c.28.55.06,1.21-.49,1.49-.16.08-.33.12-.5.12Z"
            style={{ fill: '#252a4f' }}
          />
        </g>
        <g style={{ clipPath: 'url(#uuid-992f1a2c-5b74-44e6-8337-4f68dc304aa8)', fill: 'none' }}>
          <path
            d="m410.88,870.59c-.61,0-1.16-.43-1.29-1.05-.15-.71.31-1.41,1.02-1.56,8.58-1.78,14.33-1.56,20.42-1.32,3.47.14,7.06.28,11.47.07.97-.04,1.69-.07,2.19-.35.04-.07.23-.67-.13-2.09-.67-2.61-2.52-5.44-3.51-5.85-5.46-2.23-11.61-.76-15.29.11-1.09.26-1.96.47-2.62.53-.71.06-1.37-.45-1.44-1.17-.07-.72.45-1.37,1.17-1.44.49-.05,1.32-.25,2.28-.48,3.98-.95,10.64-2.54,16.89.01,2.48,1.01,5.16,6.22,5.35,9.37.12,2.01-.71,2.91-1.43,3.3-1.04.58-2.15.62-3.14.67-4.71.22-8.36.07-11.9-.06-6.18-.24-11.52-.45-19.78,1.26-.09.02-.18.03-.27.03Z"
            style={{ fill: '#252a4f' }}
          />
          <path
            d="m566.85,967.89s-25.91,79.61-25.91,111.81c0,260.04,41.34,338.53,47.61,452.05,1.12,20.26-1.22,23.6,4.92,81.13,6.14,57.53,68.53,316.15,68.53,316.15l86.03,1.53s-9.85-281.96-11.65-375.98c-1.63-85.08-2.5-335.82-2.5-335.82,0,0,79.37,246.29,91.83,333.42,1.98,13.84,6.81,55.91,6.81,55.91,0,47.55,60.22,319.76,60.22,319.76l87.03-2.05s9.36-259.37-11.99-383.23c-3.44-19.95-2.57-108.52-4.36-128.76-22.34-253.21-84.31-442.51-84.31-442.51l-312.26-3.41Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m550.63,506.23s-39.22,16.7-60.81,57.92c-54.37,103.75-77.17,262.73-81.82,299.04-.55,4.3-2.77,8.17-6.12,10.93-2.25,1.85-4.56,4.4-5.46,7.54-3.97,13.93,5.14,18.51,5.14,18.51,0,0-7.82-1-8.73,10.57-1.23,15.64,2.21,41.37,10.11,46.89,7.91,5.52,80.03,3.07,80.03,3.07,0,0,16.48-.35,19.39-11.23,2.91-10.87,11.21-31.19,9.65-41.13-.58-3.72-4.76-9.78-4.76-9.78,0,0,7.85-2.06,8.77-12.63.92-10.57,55.81-296.23,55.81-296.23l-21.2-83.45Z"
            style={{ fill: '#fff' }}
          />
          <path
            d="m874.78,498.06s42.03,30.53,54.87,47.08c39.88,51.44,74.18,224.38,77.03,244.64,1.18,8.44,5.85,5.08,9.84,20.16,0,0-.39,18.43,2.45,25.25,2.84,6.82,8.25,14.31,10.48,26.82,2.23,12.52-.63,36.67-.63,36.67,0,0,6.29-.21,7.34,7.75,1.05,7.96-1.05,13.62-1.68,18.65-.63,5.03-4.18,19.88-8.2,24.87-9.33,11.6-77.47,11.22-84,10.34-6.54-.88-8.64-2.69-9.64-5.24-1.53-3.9-.91-7.05-5.85-15.71-4.94-8.67-11.94-19.92-.79-31.06,0-7.03-11.26-31.66-15.36-58.21-4.1-26.54-35.84-352.01-35.84-352.01h0Z"
            style={{ fill: '#fff' }}
          />
          <path
            d="m545.61,509.5c-49.85,81.92-15.48,351.18-8.09,387.7,7.39,36.52,28.89,71.35,28.89,71.35l314.26-.27s18.24-7.33,24.25-26.29c14.05-44.35,23.21-361.18-30.14-443.92-40.05-33.91-161.7-81.86-161.7-81.86,0,0-131.97,63.62-167.47,93.3h0Z"
            style={{ fill: '#fff' }}
          />
          <polygon
            points="558.26 996.73 887 997.62 881.54 967.89 566.06 967.89 558.26 996.73"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m645.59,370.44l15.24,72.72s31.56,24.92,55.37,22.22c30.23-3.43,50.94-24.18,50.94-24.18l9.15-79.95-130.7,9.2Z"
            style={{ fill: '#feb1a2' }}
          />
          <polygon
            points="714.83 464.3 691.65 492.5 708.24 508.73 688.93 570.36 707.2 910.43 734.06 941.05 755.19 905.4 746.71 567.3 726.61 507.96 741.2 488.99 714.83 464.3"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m657.6,427.4s-11.99,16.11-18.33,26.82c17.88,39.27,49.09,55.37,49.09,55.37,0,0,16.16-30.88,27.2-45.29-34.56-8.47-57.96-36.9-57.96-36.9h0Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m769.68,422.79s12.24,15.58,19.44,25.74c-14.59,40.6-42.73,57.27-42.73,57.27,0,0-19.15-28.06-31.34-41.51,33.75-11.28,54.64-41.51,54.64-41.51h0Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m640.82,357.63s-34.63-62.9-27.81-91.89c4.59-19.46,19.2-25.21,19.2-25.21,0,0-3.77-7.19,5.03-24.01,10.67-20.4,41.95-38.24,59.9-38.94,28.28-1.09,30.03,12.41,30.03,12.41,0,0,7.51-4.33,16.24-2.22,16.92,4.07,15.34,14.2,15.34,14.2,0,0,14.61,1.78,19.87,13.32,5.27,11.54.16,25.88.16,25.88,0,0,8.02,5.81,11.38,20.35,6.36,27.55-10,83.84-10,83.84l-139.33,12.27Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m633.73,337.47c6.34-15.18,5.46-24.31,3.53-38.94-1.34-10.13-5.01-19.94,5.35-38.52,9.04-16.21,31.27-6.93,62.28-9.05,12.91-.88,43.64-5.69,50.3-1.93,16.03,9.05,13.61,20.48,14.13,28.66,1.51,23.73,5.82,41.99,14.08,50.25,3.46,54.26-27.25,100.37-68.58,103-41.33,2.63-77.64-39.22-81.09-93.47h0Z"
            style={{ fill: '#feb1a2' }}
          />
          <path
            d="m636.52,347.39s-4.15-31.27-14.93-30.86c-22.91.86-15.7,50.91,20.69,54.75,0-7.91-5.76-23.89-5.76-23.89h0Z"
            style={{ fill: '#feb1a2' }}
          />
          <path
            d="m634.81,350.18c-.44,0-.86-.22-1.11-.61-4.16-6.57-10.03-11.87-16.98-15.33-.65-.32-.91-1.11-.59-1.76.32-.65,1.11-.92,1.76-.59,7.38,3.68,13.62,9.31,18.03,16.28.39.61.21,1.43-.41,1.81-.22.14-.46.2-.7.2Z"
            style={{ fill: '#bd6161' }}
          />
          <path
            d="m687.75,425.16c-.14,0-.27-.02-.41-.07-8.54-2.81-17.66-9.75-24.41-18.55-.44-.58-.33-1.4.24-1.84.58-.43,1.4-.33,1.84.24,7.57,9.89,16.92,15.6,23.14,17.65.69.23,1.07.97.84,1.66-.18.55-.7.9-1.25.9Z"
            style={{ fill: '#bd6161' }}
          />
          <path
            d="m779.48,336.05s4.15-31.27,14.93-30.86c22.91.86,18.27,50.91-18.12,54.75,0-7.91,3.19-23.89,3.19-23.89h0Z"
            style={{ fill: '#feb1a2' }}
          />
          <path
            d="m781.19,338.84c-.24,0-.48-.06-.7-.2-.61-.39-.8-1.2-.41-1.81,4.41-6.97,10.65-12.6,18.03-16.28.65-.33,1.44-.06,1.76.59.32.65.06,1.44-.59,1.76-6.95,3.46-12.83,8.76-16.98,15.33-.25.39-.68.61-1.11.61Z"
            style={{ fill: '#bd6161' }}
          />
          <path
            d="m661.95,324.18c-.84-5.92,1.45-11.14,5.11-11.66,3.66-.52,7.32,3.85,8.16,9.77.84,5.92-1.45,11.14-5.11,11.66-3.66.52-7.32-3.85-8.16-9.77h0Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m657.44,309.56c-.51,0-1.02-.14-1.49-.43-1.31-.82-1.71-2.56-.88-3.87,5-7.97,14.62-12.63,27.07-13.11,1.48-.11,2.85,1.15,2.91,2.69.06,1.55-1.14,2.85-2.69,2.91-10.55.41-18.55,4.14-22.54,10.49-.53.85-1.44,1.31-2.38,1.31Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m748.34,321.09c.84-5.92-1.45-11.14-5.11-11.66-3.66-.52-7.32,3.85-8.16,9.77-.84,5.92,1.45,11.14,5.11,11.66,3.66.52,7.31-3.85,8.16-9.77h0Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m752.86,306.46c-.99,0-1.95-.53-2.46-1.45-4.21-7.64-14.49-10.7-22.22-10.35-1.56.09-2.86-1.12-2.94-2.67-.07-1.55,1.12-2.86,2.67-2.93,9.72-.49,22.01,3.45,27.4,13.25.75,1.36.25,3.06-1.1,3.81-.43.24-.89.35-1.35.35Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m706.29,371.21c-6.32,0-12.58-1.8-17.94-5.23-.61-.39-.79-1.2-.4-1.81.39-.61,1.21-.79,1.81-.4,5.89,3.75,13.24,5.43,20.16,4.6,6.93-.83,13.67-4.19,18.51-9.23.5-.53,1.34-.54,1.86-.04.52.5.54,1.33.04,1.86-5.32,5.55-12.46,9.11-20.09,10.02-1.31.16-2.63.24-3.94.24Z"
            style={{ fill: '#bd6161' }}
          />
          <path
            d="m744.36,361.24c-6.5-11.17-26.93-11.37-26.93-11.37,0,0-5.5,6.11-19.3,1.46-8.63.78-19.69,6.82-22.73,13.52-4.57,10.05-2.63,32.56,3.09,43.95,5.72,11.39,18.52,28.47,38.95,26.19,18.86-2.11,29.22-21.71,30.51-30.17,1.78-11.63,3.58-31.24-3.59-43.57h0Zm-7.95,19.23c-.23,7.39-6.44,13.13-13.83,12.82-9.01-.37-16.77.73-22.23,1.91-6.84,1.48-13.73-2.75-15.41-9.54-1.09-4.38-1.59-10.2-.68-17.83.24-2.02,2.81-6.03,5.94-6.58,6.28-1.11,9.84,3.39,19.01,2.66,10.94-.87,11.27-5.01,16.82-5.72,5.55-.71,7.73-.02,8.74,4.39.59,2.57,1.87,10.35,1.63,17.9h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m706.3,355c-.68,0-1.36-.04-2.03-.12-4.35-.53-7.53-2.72-9.22-6.34-.31-.66-.02-1.44.64-1.75.65-.3,1.44-.02,1.75.64,1.3,2.8,3.71,4.43,7.15,4.84,4.73.57,9.52-1.44,10.55-2.73,2.08-2.6,2.28-6.79.52-11.23-.73-1.85-1.67-3.58-2.67-5.41-1.12-2.06-2.27-4.18-3.09-6.47-1.72-4.79-1.88-10.11-.44-15,.2-.69.93-1.09,1.63-.89.7.2,1.09.93.89,1.63-1.28,4.35-1.14,9.09.39,13.36.75,2.09,1.81,4.04,2.93,6.1.99,1.82,2.01,3.71,2.8,5.7,2.11,5.32,1.77,10.5-.91,13.85-1.59,1.98-6.18,3.81-10.88,3.81Z"
            style={{ fill: '#bd6161' }}
          />
          <path
            d="m680.26,235.61c-.25,0-.51-.07-.73-.22-.6-.41-.76-1.22-.36-1.82,9.9-14.69,27.39-33.9,46.83-43.78.64-.33,1.44-.07,1.77.58.33.65.07,1.44-.58,1.77-18.99,9.65-36.13,28.49-45.84,42.9-.25.38-.67.58-1.09.58Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m699.23,251c-.58,0-1.1-.38-1.27-.96-.19-.7.21-1.42.91-1.62,11.24-3.13,19.73-12.07,28.16-21.74,8.32-9.54,16.85-19.33,28.78-23.43.69-.24,1.44.13,1.67.82.24.68-.13,1.43-.82,1.67-11.26,3.87-19.56,13.38-27.58,22.59-8.77,10.06-17.57,19.3-29.51,22.63-.12.03-.24.05-.35.05Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m715.04,248.92c-.6,0-1.15-.42-1.28-1.03-.16-.71.29-1.41,1-1.57,10.69-2.36,17.86-7.29,25.46-12.51,6.81-4.68,13.84-9.51,23.84-12.81,3.44-1.14,11.55-1.02,15.03,1.27.61.4.78,1.21.38,1.82-.4.61-1.21.78-1.82.38-2.54-1.66-9.66-1.99-12.77-.97-9.65,3.19-16.52,7.91-23.18,12.48-7.81,5.37-15.19,10.44-26.38,12.91-.1.02-.19.03-.29.03Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m750.57,248.31c-.37,0-.74-.15-.99-.46-.47-.55-.41-1.38.14-1.85,8.21-7.09,19.15-6.82,28.76-6,.72.06,1.26.7,1.2,1.42s-.71,1.28-1.42,1.2c-9.07-.77-19.38-1.05-26.83,5.37-.25.21-.55.32-.86.32Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m837.58,699.73c-.08,0-.16,0-.24,0-10.41-.2-36.73-7.62-41.08-19.09-4.05-10.65-2.88-71.46-2.83-74.05.01-.7.57-1.27,1.27-1.29l76.92-2.35h.04c.68,0,1.25.52,1.31,1.2.56,6.57,5.4,64.43.86,71.6-5.76,9.12-24.99,23.98-36.25,23.98Zm-41.55-91.84c-.15,8.9-.87,62.44,2.68,71.81,3.62,9.52,27.9,17.19,38.68,17.4.07,0,.14,0,.21,0,10.15,0,28.55-14.12,34.01-22.76,3.02-4.78.99-43.24-1.16-68.73l-74.42,2.27Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m409.17,954.59l27.74,128.55s-11.82,62-11.82,72.18c0,27.27,19.28,46.53,19.28,46.53,0,0,8.64,18.03,22.67,21.77,8.55,6.28,20.58,11.34,20.58,11.34,0,0,4.85-6.48,1.79-12.53-3.06-6.05-9.84-15.51-9.84-15.51l-7.46-38.18,11.04-31.02s7.89,9.73,8.35,13.72c.68,5.92-4.62,13.98-1.66,23.77,2.95,9.78,16.88,11.73,16.88,11.73l-.89-34s3.02-26.83.3-35.2c-2.34-7.21-21.41-38.52-21.41-38.52,0,0,2.91-92.21,7.39-124.63-41.83-27.18-82.92,0-82.92,0h0Z"
            style={{ fill: '#feb1a2' }}
          />
          <path
            d="m944.3,956.21c5.38,37.61,19.79,117.18,19.79,117.18,0,0-17.51,38.82-18.35,47.18-.85,8.36,4.41,24.53,5.6,30.93,1.19,6.4,2,13.24,2,13.24,0,0-.82,28.09,5.49,28.09,4.72,0,10.29-5.49,11.72-14.34,1.79-11.09-2.71-21.6-2.71-21.6l2.8-17.12s7.24,29.19,6.76,37.01c-.47,7.82-7.71,25.78-7.71,25.78,0,0-12.34,13.22-10.66,19.19,1.68,5.97,26-11.1,26-11.1,0,0,18.37-12.02,20.81-17.47,4.34-3.23,9.76-15.38,13.31-26.05,3.55-10.66-.5-39.72-.5-39.72l-11.05-50.2s16.36-99.14,14.01-127.39c-20.35-20.35-70.18-10.81-77.32,6.37h0Z"
            style={{ fill: '#feb1a2' }}
          />
          <path
            d="m962.31,1074.72l-2.06-18.04s48.16-7.64,50.51,1.03c-.46,5.05-.56,12.11-2.06,18.04-4.02,4.43-46.38-1.03-46.38-1.03h0Z"
            style={{ fill: '#232b35' }}
          />
          <path
            d="m990.04,1065.24c0-7.54,5.19-13.66,11.6-13.66s11.6,6.11,11.6,13.66-5.19,13.66-11.6,13.66-11.6-6.11-11.6-13.66h0Z"
            style={{ fill: '#252a4f' }}
          />
          <path
            d="m994.79,1065.24c0-6.41,3.76-11.61,8.41-11.61,4.64,0,8.41,5.2,8.41,11.61s-3.76,11.61-8.41,11.61-8.41-5.2-8.41-11.61h0Z"
            style={{ fill: '#fefefe' }}
          />
          <path
            d="m644.51,967.89s-1.86,11.05.16,31.3c-3.76,1.84-10.27.93-10.27.93,0,0-2.78-13.18-.72-32.23h10.83Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m809.59,967.89s2.36,11.25-.16,30.96c3.76,1.84,11.19-.1,11.19-.1,0,0,1.85-11.81-.21-30.86h-10.83Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m724.48,1148.24c-.7,0-1.28-.55-1.31-1.26-.97-22.4-1.96-48.62-2.92-73.99-.96-25.36-1.95-51.59-2.92-73.98-.03-.73.53-1.34,1.26-1.37.73.03,1.34.53,1.37,1.26.97,22.4,1.96,48.63,2.92,74,.96,25.36,1.95,51.58,2.92,73.97.03.73-.53,1.34-1.26,1.37h-.06Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m725.33,1136.95c-.46,0-.9-.24-1.14-.66-.36-.63-.14-1.43.49-1.79,10.62-6.11,18.35-18.13,21.2-33,1.63-8.5.58-36.05-.34-60.37-.69-18.03-1.34-35.07-.91-43.56.04-.73.65-1.29,1.38-1.25.73.04,1.28.65,1.25,1.38-.42,8.37.23,25.35.91,43.33.98,25.65,1.99,52.17.3,60.96-3,15.62-11.19,28.3-22.47,34.79-.21.12-.43.18-.65.18Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m901.42,1058.06c-15.78,0-31.2-6.28-42.49-17.34-11.6-11.37-18.24-27.22-18.2-43.46,0-.73.59-1.31,1.31-1.31h0c.73,0,1.31.59,1.31,1.32-.03,15.32,6.48,30.86,17.42,41.58,10.94,10.72,26.69,16.89,41.92,16.58h.03c.71,0,1.3.57,1.31,1.28.02.73-.56,1.33-1.28,1.34-.44.01-.89.01-1.33.01Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m542.3,1076.14c-.59,0-1.13-.4-1.28-1-.17-.71.26-1.42.96-1.59,18.39-4.52,32.26-12.75,43.66-25.89,11.88-13.7,19.4-31.18,21.19-49.22.07-.72.74-1.24,1.44-1.18.72.07,1.25.71,1.18,1.44-1.84,18.58-9.59,36.58-21.82,50.68-11.77,13.58-26.07,22.07-45.01,26.72-.11.03-.21.04-.31.04Z"
            style={{ fill: PRIMARY_DARK }}
          />
          <path
            d="m745.41,997.59h-27.92c-5.55,0-10.07-4.52-10.07-10.07v-11.43c0-5.55,4.52-10.07,10.07-10.07h27.92c5.55,0,10.07,4.52,10.07,10.07v11.43c0,5.55-4.52,10.07-10.07,10.07Zm-27.92-27.83c-3.49,0-6.33,2.84-6.33,6.33v11.43c0,3.49,2.84,6.33,6.33,6.33h27.92c3.49,0,6.33-2.84,6.33-6.33v-11.43c0-3.49-2.84-6.33-6.33-6.33h-27.92Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m725.16,982.83h0l-13.76-.03c-1.03,0-1.87-.84-1.87-1.87,0-1.03.84-1.87,1.87-1.87h0l13.76.03c1.03,0,1.87.84,1.87,1.87,0,1.03-.84,1.87-1.87,1.87Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m528.8,820.96c-.67,0-1.25-.52-1.31-1.2-7.74-91.1-2.1-172.4,17.23-248.54.18-.7.89-1.13,1.6-.95.7.18,1.13.89.95,1.6-19.26,75.85-24.88,156.86-17.17,247.67.06.72-.47,1.36-1.2,1.42-.04,0-.07,0-.11,0Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m914.21,859.41c-.18,0-.36-.04-.53-.11-.67-.29-.97-1.07-.68-1.73,6.14-13.98.89-185.18-15.83-256.74-.17-.71.27-1.41.98-1.58.7-.16,1.41.27,1.58.98,17.05,72.93,22.32,243.27,15.68,258.4-.22.49-.7.79-1.2.79Z"
            style={{ fill: '#e6e6e6' }}
          />
          <path
            d="m734.18,789.71c6.18-.21,5.85-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m733.12,759.26c6.18-.21,5.86-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m730.99,698.36c6.18-.21,5.85-9.72-.33-9.51-6.18.21-5.86,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m732.05,728.81c6.18-.21,5.86-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m735.25,820.16c6.18-.21,5.86-9.72-.33-9.51-6.18.22-5.86,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m746.96,613.45c.32-.01.62-.06.9-.12l-.23-9.32c-.32-.05-.65-.07-1-.06-6.18.22-5.86,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m748.02,643.89c.22,0,.41-.05.61-.08l-.24-9.38c-.23-.02-.45-.05-.7-.04-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m729.93,667.91c6.18-.22,5.86-9.72-.33-9.51-6.18.21-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m745.89,583c.44-.01.83-.09,1.2-.19l-.23-9.19c-.4-.09-.83-.15-1.31-.13-6.18.22-5.86,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m736.31,850.61c6.18-.21,5.86-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m737.38,881.05c6.18-.22,5.85-9.72-.33-9.51-6.18.21-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m726.73,576.57c6.18-.22,5.86-9.72-.33-9.51-6.18.22-5.86,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m749.08,674.34c.11,0,.2-.03.31-.04l-.24-9.44c-.13,0-.26-.03-.4-.02-6.18.22-5.86,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m716.07,813.18c6.18-.22,5.85-9.72-.33-9.51-6.18.21-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m713.61,742.78c-6.18.22-5.85,9.72.33,9.51,6.18-.22,5.85-9.72-.33-9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m725.74,535.84c-6.46.23-6.12,10.17.35,9.94,6.46-.23,6.12-10.17-.35-9.94h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m715.01,782.73c6.18-.22,5.85-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m728.86,637.47c6.18-.22,5.86-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m719.26,904.53c6.18-.22,5.85-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m727.8,607.02c6.18-.22,5.85-9.72-.33-9.51-6.18.22-5.86,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m724.51,481.99c-6.19,0-6.18,9.51,0,9.51s6.18-9.51,0-9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m717.13,843.63c6.18-.22,5.86-9.72-.33-9.51-6.18.21-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m718.2,874.08c6.18-.22,5.85-9.72-.33-9.51-6.18.21-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m689.76,585.78l.5,9.37c5.2-.9,4.86-9-.5-9.37h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m707.56,569.59c6.18-.22,5.85-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m706.04,538.49c6.46-.23,6.12-10.17-.35-9.94-6.46.22-6.12,10.17.35,9.94h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m708.62,600.04c6.18-.22,5.85-9.72-.33-9.51-6.18.21-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m698.01,739.32l.35,6.43c1.39-1.86,1.25-4.75-.35-6.43h0Z"
            style={{ fill: '#8767b7' }}
          />
          <path
            d="m692.66,558.45l-1.75,5.59c1.82-1.2,2.39-3.6,1.75-5.59h0Z"
            style={{ fill: '#8767b7' }}
          />
          <path
            d="m691.4,616.27l.49,9.2c4.43-1.32,4.14-8.36-.49-9.2h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m738.44,911.5c6.18-.22,5.85-9.72-.33-9.51-6.18.21-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m712.88,721.84c6.18-.21,5.85-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m699.69,770.6l.25,4.68c.64-1.48.56-3.29-.25-4.68h0Z"
            style={{ fill: '#8767b7' }}
          />
          <path
            d="m711.81,691.39c6.18-.21,5.85-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m705.58,499.14c6.19,0,6.18-9.51,0-9.51s-6.18,9.51,0,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m752.45,796.08l-.23-9.34c-5.04.93-4.81,8.69.23,9.34h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m693.04,646.89l.47,8.83c3.66-1.64,3.4-7.58-.47-8.83h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m750.15,704.79h0l-.24-9.5s-.07,0-.1,0c-6.18.21-5.86,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m750.92,735.22l-.24-9.46c-5.86.42-5.59,9.36.24,9.46h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m751.68,765.65l-.24-9.4c-5.44.69-5.19,9.01.24,9.4h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m753.97,856.85l-.23-9.07c-4.22,1.35-4.02,7.94.23,9.07h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m753.21,826.49l-.23-9.26c-4.65,1.14-4.43,8.36.23,9.26h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m694.69,677.57l.44,8.29c2.89-1.86,2.67-6.75-.44-8.29h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m696.34,708.35l.41,7.54c2.13-1.95,1.96-5.85-.41-7.54h0Z"
            style={{ fill: '#8767b7' }}
          />
          <path
            d="m710.75,660.94c6.18-.21,5.85-9.72-.33-9.51-6.18.22-5.85,9.72.33,9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m709.35,620.98c-6.18.22-5.85,9.72.33,9.51,6.18-.22,5.86-9.72-.33-9.51h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m754.74,887.21l-.22-8.87c-3.81,1.52-3.64,7.55.22,8.87h0Z"
            style={{ fill: PRIMARY_MAIN }}
          />
        </g>
      </svg>
    </Box>
  );
}

export default memo(TotalAssociatedIllustration);

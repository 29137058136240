// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const IS_LOCALHOST =
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname.startsWith('192.168.');

export const IS_DEV = window.location.hostname.split('.')[0] === 'dev';

export const AUTH_PATH = IS_LOCALHOST
  ? 'http://localhost:8082'
  : IS_DEV
    ? 'https://dev.auth.wannapay.it'
    : 'https://auth.wannapay.it';

export const FRONTEND_PATH = IS_LOCALHOST
  ? 'http://localhost:8084'
  : IS_DEV
    ? 'https://dev.app.wannapay.it'
    : 'https://app.wannapay.it';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = '/dashboard'; // as '/dashboard'

export const STRIPE_PUBLISHABLE_KEY =
  IS_DEV || IS_LOCALHOST
    ? 'pk_test_51IPTVEHF220tyIJX2dmr6Gy291OUjG3yz7rU2sYLQDTkjyD8MggHyUHXrl6GWWhVBXWNpsbFXtFfxiADApcDiChz00W2NSrgdP'
    : 'pk_live_51IPTVEHF220tyIJXSs3HBYVzfTGWPtE1xCAVmlMOsSQ4yQVv3gh1gT0fa2CN5PMZf581dqKwd0aCTarFpjJATr4V00fbaQUWfK';

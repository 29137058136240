import * as api from 'src/backend/API';
import * as formatGraphql from './utils/format-graphql';

import apiMiddleware from './middleware';
import * as product from './api/product';
import * as category from './api/category';
import * as menu from './api/menu';
import * as location from './api/location';
import * as post from './api/post';
import * as corporate from './api/corporate';
import * as user from './api/user';
import * as order from './api/order';

const queriesList = {
  // product
  getProductsByCategoryApi: (categoryId: string) =>
    apiMiddleware(() => product.getProductsByCategoryApi(categoryId)),
  getProductApi: (productId: string) => apiMiddleware(() => product.getProductApi(productId)),
  getProductsByLocation: (locationId: string) =>
    apiMiddleware(() => product.getProductsByLocation(locationId)),
  getVariantsByLocation: (locationId: string) =>
    apiMiddleware(() => product.getVariantsByLocation(locationId)),
  getProductsAndVariantsByLocation: (locationId: string) =>
    apiMiddleware(() => product.getProductsAndVariantsByLocation(locationId)),
  getWinesByLocation: (locationId: string) =>
    apiMiddleware(() => product.getWinesByLocation(locationId)),
  getModifiersGroupByLocation: (locationId: string) =>
    apiMiddleware(() => product.getModifiersGroupByLocation(locationId)),
  // category
  getCategoriesByMenuApi: (menuId: string) =>
    apiMiddleware(() => category.getCategoriesByMenuApi(menuId)),
  getCategoryApi: (categoryId: string) => apiMiddleware(() => category.getCategoryApi(categoryId)),
  getCategoriesByLocation: (locationId: string) =>
    apiMiddleware(() => category.getCategoriesByLocation(locationId)),
  // menu
  getMenusMinimalNoFilterByLocationApi: (locationId: string) =>
    apiMiddleware(() => menu.getMenusMinimalNoFilterByLocationApi(locationId)),
  getMenusByLocationApi: (locationId: string) =>
    apiMiddleware(() => menu.getMenusByLocationApi(locationId)),
  getMenusWineByLocationApi: (locationId: string) =>
    apiMiddleware(() => menu.getMenusWineByLocationApi(locationId)),
  getMenuApi: (menuId: string) => apiMiddleware(() => menu.getMenuApi(menuId)),
  // location
  getLocationsApi: (locationIds: string[]) =>
    apiMiddleware(() => location.getLocationsApi(locationIds)),
  getLocationMinimalApi: (locationId: string) =>
    apiMiddleware(() => location.getLocationMinimalApi(locationId)),
  getLocationApi: (locationId: string) => apiMiddleware(() => location.getLocationApi(locationId)),
  getLocationStripePaymentMethodApi: (locationId: string) =>
    apiMiddleware(() => location.getLocationStripePaymentMethodApi(locationId)),
  getLocationSubscriptionApi: (locationId: string) =>
    apiMiddleware(() => location.getLocationSubscriptionApi(locationId)),
  getAssociatedPresenceByLocationlast2Months: (locationId: string) =>
    apiMiddleware(() => location.getAssociatedPresenceByLocationlast2Months(locationId)),
  // post
  getPostsByLocationApi: (locationId: string) =>
    apiMiddleware(() => post.getPostsByLocationApi(locationId)),
  // corporate
  getCorporatesApi: (corporateIds: string[]) =>
    apiMiddleware(() => corporate.getCorporatesApi(corporateIds)),
  getCorporateMinimalApi: (corporateId: string) =>
    apiMiddleware(() => corporate.getCorporateMinimalApi(corporateId)),
  getCorporateApi: (corporateId: string) =>
    apiMiddleware(() => corporate.getCorporateApi(corporateId)),
  // user
  getUserApi: (userId: string) => apiMiddleware(() => user.getUserApi(userId)),
  getUserPresenceKey: () => apiMiddleware(() => user.getUserPresenceKey()),
  getUserPaymentMethods: () => apiMiddleware(() => user.getUserPaymentMethods()),
  // order
  getOrdersByLocationApi: (locationId: string) =>
    apiMiddleware(() => order.getOrdersByLocationApi(locationId)),
  getOrderMinimalApi: (orderId: string) => apiMiddleware(() => order.getOrderMinimalApi(orderId)),
  getOrderApi: (orderId: string) => apiMiddleware(() => order.getOrderApi(orderId)),
};

const mutationsList = {
  // product
  addProductToCategoryApi: (categoryId: string, productId: string, products: api.Product[]) =>
    apiMiddleware(() => product.addProductToCategoryApi(categoryId, productId, products)),
  updateProductsApi: (products: api.Product[]) =>
    apiMiddleware(() => product.updateProductsApi(products)),
  removeProductFromCategoryApi: (categoryId: string, productId: string, products: api.Product[]) =>
    apiMiddleware(() => product.removeProductFromCategoryApi(categoryId, productId, products)),
  createProductApi: (productId: string, createData: any) =>
    apiMiddleware(() => product.createProductApi(productId, createData)),
  updateProductApi: (productId: string, updateData: any) =>
    apiMiddleware(() => product.updateProductApi(productId, updateData)),
  deleteProductApi: (productId: string) => apiMiddleware(() => product.deleteProductApi(productId)),
  handleProductVariantsApi: (
    productId: string,
    productName: string,
    businessId: string,
    locationId: string,
    oldProductVariants: any,
    newProductVariants: any
  ) =>
    apiMiddleware(() =>
      product.handleProductVariantsApi(
        productId,
        productName,
        businessId,
        locationId,
        oldProductVariants,
        newProductVariants
      )
    ),
  // category
  updateCategoriesApi: (categories: api.Category[]) =>
    apiMiddleware(() => category.updateCategoriesApi(categories)),
  createCategoryApi: (categoryId: string, createData: any) =>
    apiMiddleware(() => category.createCategoryApi(categoryId, createData)),
  updateCategoryApi: (categoryId: string, updateData: any) =>
    apiMiddleware(() => category.updateCategoryApi(categoryId, updateData)),
  deleteCategoryApi: (categoryId: string) =>
    apiMiddleware(() => category.deleteCategoryApi(categoryId)),
  // menu
  updateMenusApi: (menus: api.Menu[]) => apiMiddleware(() => menu.updateMenusApi(menus)),
  createMenuApi: (menuId: string, createData: any) =>
    apiMiddleware(() => menu.createMenuApi(menuId, createData)),
  updateMenuApi: (menuId: string, updateData: any) =>
    apiMiddleware(() => menu.updateMenuApi(menuId, updateData)),
  deleteMenuApi: (menuId: string) => apiMiddleware(() => menu.deleteMenuApi(menuId)),
  // location
  updateLocationApi: (locationId: string, updateData: any) =>
    apiMiddleware(() => location.updateLocationApi(locationId, updateData)),
  updateLocationLanguagesApi: (locationId: string, languages: string[]) =>
    apiMiddleware(() => location.updateLocationLanguagesApi(locationId, languages)),
  updateLocationStripeAccountApi: (locationId: string, updateData: any) =>
    apiMiddleware(() => location.updateLocationStripeAccountApi(locationId, updateData)),
  handleLocationStripeSubscriptionApi: (
    locationId: string,
    subscriptionType: api.StripeSubscriptionType
  ) =>
    apiMiddleware(() => location.handleLocationStripeSubscriptionApi(locationId, subscriptionType)),
  handleLocationStripePaymentMethodApi: (locationId: string, updateData: any) =>
    apiMiddleware(() => location.handleLocationStripePaymentMethodApi(locationId, updateData)),
  storePresenceKeyApi: (locationId: string, presenceKey: string) =>
    apiMiddleware(() => location.storePresenceKeyApi(locationId, presenceKey)),
  // post
  createPostApi: (postId: string, createData: any) =>
    apiMiddleware(() => post.createPostApi(postId, createData)),
  deletePostApi: (postId: string) => apiMiddleware(() => post.deletePostApi(postId)),
  // corporate
  updateCorporateApi: (corporateId: string, updateData: any) =>
    apiMiddleware(() => corporate.updateCorporateApi(corporateId, updateData)),
  corporateInviteAssociatedsApi: (corporateId: string, userEmails: string[]) =>
    apiMiddleware(() => corporate.corporateInviteAssociatedsApi(corporateId, userEmails)),
  // user
  updateUserAttributesApi: (userId: string, updateData: any) =>
    apiMiddleware(() => user.updateUserAttributesApi(userId, updateData)),
  // order
  payOrderApi: (orderId: string, orderData: any) =>
    apiMiddleware(() => order.payOrderApi(orderId, orderData)),
  updateOrderStatusApi: (orderId: string, newStatus: api.OrderStatus) =>
    apiMiddleware(() => order.updateOrderStatusApi(orderId, newStatus)),
};

export { api as ApiTypes };
export { formatGraphql as ApiUtils };
export { queriesList as ApiQueries };
export { mutationsList as ApiMutations };

// test 2

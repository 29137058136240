import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// import { useSettingsContext } from 'src/components/settings/context';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
    // const settings = useSettingsContext();

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    // const PRIMARY_MAIN = settings.themeMode === 'dark' ? '#ffffff' : '#000000';

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 50,
          height: 50,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          id="uuid-4e14a4a9-5c1a-45b6-9fc8-b3a7358fecaf"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1080 1080"
        >
          <path
            d="m259.4,72.4h561.1c103.3,0,187,83.7,187,187v561.1c0,103.3-83.7,187-187,187H259.4c-103.3,0-187-83.7-187-187V259.4c0-103.3,83.7-187,187-187"
            style={{ fill: PRIMARY_MAIN }}
          />
          <path
            d="m579.6,513.2c6.2,13.3,12.4,26.3,18.4,39.2,4.9,10.4,17.2,15.3,27.8,10.8,85.3-36.1,114.6-132.1,76.1-256.1,37.8,0,75-2.6,111.7.6,47,4.1,76.2,52.5,56.6,95.8-50.2,111-102.8,221-155.1,331-12,25.1-34.1,38.2-61.9,38.4-29.1.2-51.5-13.8-64-40-46-96.7-92.2-193.4-136.3-291-22.3-49.4-1.2-102.9,45.2-125.2,47.1-22.7,101.8-4.9,126.8,41.1,24.7,45.5,9.2,98.9-37.1,127.8-.1,0-.1.1-.2.1-9.1,5.9-12.6,17.6-8,27.5h0Z"
            style={{ fill: '#fff' }}
          />
          <path
            d="m382.5,306.8c-39.7,72.1-29.4,137.8,4.3,204.1,33.4,65.8,62.1,133.9,95.1,199.8,11.9,23.6,3.9,37-13.8,49.7-34,24.4-82.6,15.1-101.6-24-54.1-111.5-106.8-223.8-157.5-336.8-19.4-43.2,15.3-90.4,64.9-92.5,34.5-1.5,69.1-.4,108.6-.3h0Z"
            style={{ fill: '#fff' }}
          />
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;

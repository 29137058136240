/* eslint-disable react/style-prop-object */
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function TotalOrdersAssociatedsIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 1080 1080"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <svg
        id="uuid-652081d2-917f-44c4-b70b-ecd6eadb876e"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1080 1080"
      >
        <rect
          x="341.84"
          y="180.01"
          width="396.33"
          height="719.99"
          rx="55.14"
          ry="55.14"
          transform="translate(299.55 -184.83) rotate(26.65)"
          style={{ fill: '#232b35' }}
        />
        <rect
          x="374.7"
          y="209.09"
          width="349.3"
          height="624.55"
          rx="34.45"
          ry="34.45"
          transform="translate(292.19 -191.01) rotate(26.65)"
          style={{ fill: '#fff' }}
        />
        <rect
          x="355.49"
          y="811.71"
          width="76.45"
          height="39.63"
          rx="11.41"
          ry="11.41"
          transform="translate(414.76 -88.26) rotate(26.65)"
          style={{ fill: '#fff' }}
        />
        <circle cx="539.73" cy="540.54" r="103.72" style={{ fill: PRIMARY_MAIN }} />
        <path
          d="m590.97,602.74c-.92,1.83-2.83,2.72-4.27,2l-127.29-63.87c-1.45-.73-1.87-2.79-.95-4.62h0c.91-1.82,2.82-2.72,4.27-1.99l127.29,63.87c1.44.72,1.87,2.79.95,4.61h0Z"
          style={{ fill: '#fff' }}
        />
        <path
          d="m557.77,509.88c1.93-.13,3.74-1.25,4.68-3.11,1.4-2.79.27-6.2-2.52-7.6s-6.21-.27-7.61,2.52c-.94,1.86-.74,3.99.31,5.61-29.81-13.23-65.07-.83-79.86,28.64l109.91,55.15c14.81-29.52,3.63-65.26-24.91-81.21Z"
          style={{ fill: '#fff' }}
        />
        <path
          d="m458.44,495.07c-1.02-.51-2.36.12-3,1.4-.64,1.28-.35,2.74.67,3.25l29,14.55c1.32-1.1,2.86-2.29,4.62-3.5l-31.28-15.7Z"
          style={{ fill: '#e6e6e6' }}
        />
        <path
          d="m544.77,500.79c-3.44-9.41-10.14-17.65-19.48-22.87,1.36-.09,2.63-.88,3.29-2.19.99-1.96.19-4.36-1.77-5.35-1.97-.99-4.37-.19-5.35,1.77-.66,1.31-.52,2.81.22,3.95-20.97-9.31-45.77-.59-56.18,20.15l26.24,13.17c10.98-7.04,28.99-14.11,53.03-8.63Z"
          style={{ fill: '#e6e6e6' }}
        />
        <path
          d="m623.78,579.99c1.01.53,1.27,1.99.6,3.26-.67,1.27-2.03,1.87-3.03,1.34l-28.67-15.19c.13-1.71.21-3.65.18-5.79l30.92,16.38Z"
          style={{ fill: '#e6e6e6' }}
        />
        <path
          d="m569.07,512.97c9.66-2.65,20.25-1.97,29.92,2.61-.71-1.16-.81-2.66-.13-3.95,1.03-1.94,3.44-2.69,5.39-1.66,1.94,1.03,2.69,3.45,1.66,5.39-.69,1.3-1.98,2.05-3.34,2.11,19.74,11.69,27.01,36.95,16.15,57.45l-25.94-13.75c-.63-13.03-5.31-31.8-23.71-48.21Z"
          style={{ fill: '#e6e6e6' }}
        />
        <circle cx="518.65" cy="371.59" r="27.75" style={{ fill: PRIMARY_DARK }} />
        <path
          d="m538.81,373.6v-2.05c0-1.1-.9-2-2.01-2l-36.83.11c-1.1,0-2.01.91-2.01,2.01v2.16c-.07.08-.13.15-.2.22-1,1.14-1.95,2.22-1.49,3.11.29.57.97.81,1.69.88v.22c0,1.1.9,2,2.01,2l36.83-.11c1.1,0,2.01-.91,2.01-2.01v-1.63c.07.02.16.04.28.08.21.06.45.02.63-.11.18-.13.29-.34.29-.57,0-.12-.04-1.16-1.2-2.33Zm-39.98,1.39c.29-.33.5-.57.66-.79h32.43c3.12.1,5.28.22,5.86.38.2.2.35.39.46.56-.47.1-.76.42-1.04.78-.1.13-.21.27-.34.4-.69.67-1.71.6-2.25.5-.32-.06-.87-.47-1.36-.83-.73-.54-1.49-1.11-2.28-1.21-.79-.11-1.22.12-1.6.32-.13.07-.26.14-.43.21-.22.09-.45.2-.68.3-1.22.56-2.08.89-2.88.42-.3-.17-.58-.39-.85-.6-.91-.7-2.03-1.58-3.88-.37-.21.14-.41.27-.61.41-1.42.97-2.36,1.6-4.26.9-.5-.18-.88-.41-1.29-.65-.56-.33-1.14-.68-1.99-.89-1.26-.32-2.38.52-3.37,1.27-1.53,1.15-2.26,1.52-3.22.58-.15-.15-.28-.37-.42-.62-.31-.55-.79-1.41-1.9-1.41-.25,0-.54.05-.87.15-.7.22-1.24.6-1.76.96-.47.33-.92.64-1.43.79-.6.18-1.56.15-1.93,0,.19-.39.86-1.16,1.21-1.56Z"
          style={{ fill: '#fff' }}
        />
        <path
          d="m537.39,367.06l-6.03-6.02c-.78-.78-2.29-1.17-3.35-.88l-28.11,7.9c-1.06.3-1.03.54.07.54l36.83-.11c1.1,0,1.37-.64.59-1.43Zm-9.98-5.88c.56,0,.97.27.97.65s-.41.65-.97.65-.97-.28-.97-.65.41-.65.97-.65Zm-18.75,6.56c-.52,0-.89-.26-.89-.61s.38-.62.89-.62.89.26.89.62-.37.61-.89.61Zm6.25-.62c-.61,0-1.04-.29-1.04-.69s.44-.69,1.04-.69,1.05.29,1.05.69-.44.69-1.05.69Zm5.95-.61c-.71,0-1.22-.33-1.22-.77s.51-.78,1.22-.78,1.22.33,1.22.78-.51.77-1.22.77Zm6.54-.15c-.61,0-1.05-.29-1.05-.69s.44-.69,1.05-.69,1.05.29,1.05.69-.44.69-1.05.69Zm5.87.91c-.71,0-1.22-.33-1.22-.77s.51-.77,1.22-.77,1.22.33,1.22.77-.51.77-1.22.77Z"
          style={{ fill: '#fff' }}
        />
        <circle cx="485.67" cy="720.48" r="42.57" style={{ fill: PRIMARY_DARK }} />
        <path
          d="m515.82,701.47c-.45-.66-1.06-1.03-1.35-.83-.03.02-.05.05-.07.07v-.02s-20.24,13.93-20.24,13.93c0,0,0,.01.01.02-.56-1.61-1.39-3.05-2.47-4.17h0s9.3-20.39,9.3-20.39h-.02s0,0,0,0c.02-.02.04-.05.06-.08.15-.33-.32-.86-1.05-1.19-.73-.33-1.43-.34-1.58-.01-.01.03-.01.06-.02.09h-.02s-9.07,19.88-9.07,19.88c0,0,0,0,0,0-.94-.43-2-.67-3.16-.67-.02,0-.03,0-.04,0-1.57-1.75-3.84-2.85-6.37-2.85-2.75,0-5.19,1.3-6.77,3.32-.88-.31-1.82-.48-2.8-.48-4.74,0-8.57,3.84-8.57,8.58,0,0,.01.94-.17,2.27h33.4c.05-.42.05-.86.02-1.33,0,.01,0,.02,0,.03l21.18-14.58-.02-.02h0s.06-.02.09-.04c.29-.2.17-.9-.29-1.56Zm-17.17-12.04s.04.04.07.06c-.03-.02-.05-.04-.07-.06Zm1.73.8s.01,0,.02,0c0,0-.01,0-.02,0Zm.37-.03s0,0,0,0c0,0,0,0,0,0Zm-.17.03s0,0,.01,0c0,0,0,0-.01,0Zm-.39-.02s-.01,0-.02,0c0,0,.01,0,.02,0Zm-.23-.05s0,0,0,0c0,0,0,0,0,0Zm-.78-.33c-.07-.04-.13-.08-.19-.12.06.04.12.08.19.12Zm.29.15c-.09-.04-.17-.09-.25-.13.08.04.17.09.25.13Zm-.66-.42s.08.06.12.1c-.04-.03-.08-.06-.12-.1Zm-.24-.25s-.02-.02-.03-.04c0,.01.02.02.03.04Zm-.11-.16s0,0,0-.01c0,0,0,0,0,.01Zm-8.48,19.95s-.1-.05-.15-.08c.05.03.1.05.15.08Zm1.19.86s-.02-.02-.02-.02c0,0,.01.01.02.02Zm-.58-.46s-.05-.04-.08-.05c.02.02.05.03.08.05Zm4.13,7.16c.04.18.06.35.08.52-.02-.17-.05-.34-.08-.52Zm-.27-1.14s.03.09.04.14c-.01-.04-.02-.09-.04-.14Zm21.32-12.49s-.03,0-.04-.01c.01,0,.03,0,.04.01Zm-.16-.06s-.03-.02-.05-.03c.02,0,.04.02.05.03Zm-1.11-1.24s0,0,0,0c0,0,0,0,0,0Zm-.18-.79s0,0,0,.01c0,0,0,0,0-.01Zm.04.37s0-.01,0-.02c0,0,0,.01,0,.02Zm.64,1.15s.07.08.11.12c-.04-.04-.07-.08-.11-.12Zm-.24-.29c.06.08.11.15.17.22-.06-.07-.11-.14-.17-.22Zm.69.7s-.05-.03-.07-.05c.02.02.04.03.07.05Zm-.17-.13s-.06-.05-.08-.08c.03.02.06.05.08.08Zm.61.3s.02,0,.03,0c0,0-.02,0-.03,0Z"
          style={{ fill: '#fff' }}
        />
        <path
          d="m480.13,739.79c9.73-1.11,17.72-9.33,16.23-19.29h-36.93c-1.42,9.87,6.38,18.17,16.21,19.29h.96v9.04c-4.03.13-7.12.88-7.12,1.78,0,1,3.77,1.8,8.42,1.8,4.65,0,8.42-.81,8.42-1.8,0-.91-3.21-1.67-7.35-1.78v-9.03h1.16Z"
          style={{ fill: '#fff' }}
        />
        <circle cx="706.16" cy="386.58" r="50.24" style={{ fill: PRIMARY_DARK }} />
        <path
          d="m723.03,356.18h-.01c-.08-1.71-.58-2.57-2.46-2.57h-26.28c-1.88,0-2.9.87-3.08,2.57h-.04c0,.14,0,.28.01.42,0,.04-.01.07-.01.12v.44s.02,0,.02,0c.21,11.11.85,37.34,14.89,38.34v19.81c-5.89.14-10.51,1.51-10.51,3.18,0,1.76,5.16,3.2,11.53,3.2s11.53-1.43,11.53-3.2c0-1.67-4.61-3.04-10.5-3.18v-19.81c14.03-.99,14.67-27.23,14.89-38.34h0s.01-.44.01-.44c0-.11,0-.22,0-.33,0-.07,0-.15,0-.21Zm-6.93,26.79c-.18.62-.75,1.05-1.37,1.05-.14,0-.28-.02-.41-.06-.74-.23-1.16-1.04-.94-1.79,2.7-9.17,3.34-14.02,3.1-23.56-.02-.79.6-1.46,1.37-1.49h.06c.77,0,1.39.62,1.41,1.42.25,9.9-.41,14.93-3.22,24.44Z"
          style={{ fill: '#fff' }}
        />
        <line
          x1="507.31"
          y1="644.6"
          x2="506.92"
          y2="647.58"
          style={{
            fill: 'none',
            stroke: '#232b35',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
          }}
        />
        <line
          x1="506.2"
          y1="652.98"
          x2="503.7"
          y2="671.9"
          style={{
            fill: 'none',
            stroke: '#232b35',
            strokeDasharray: '0 0 5.45 5.45',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
          }}
        />
        <line
          x1="503.34"
          y1="674.6"
          x2="502.95"
          y2="677.58"
          style={{
            fill: 'none',
            stroke: '#232b35',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
          }}
        />
        <line
          x1="524.34"
          y1="401.58"
          x2="524.7"
          y2="404.55"
          style={{
            fill: 'none',
            stroke: '#232b35',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
          }}
        />
        <line
          x1="525.37"
          y1="409.96"
          x2="527.69"
          y2="428.91"
          style={{
            fill: 'none',
            stroke: '#232b35',
            strokeDasharray: '0 0 5.45 5.45',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
          }}
        />
        <line
          x1="528.02"
          y1="431.61"
          x2="528.39"
          y2="434.59"
          style={{
            fill: 'none',
            stroke: '#232b35',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
          }}
        />
        <line
          x1="665.22"
          y1="426.62"
          x2="662.79"
          y2="428.38"
          style={{
            fill: 'none',
            stroke: '#232b35',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
          }}
        />
        <line
          x1="657.8"
          y1="431.98"
          x2="620.39"
          y2="459"
          style={{
            fill: 'none',
            stroke: '#232b35',
            strokeDasharray: '0 0 6.15 6.15',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
          }}
        />
        <line
          x1="617.9"
          y1="460.8"
          x2="615.47"
          y2="462.56"
          style={{
            fill: 'none',
            stroke: '#232b35',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '2px',
          }}
        />
      </svg>
    </Box>
  );
}

export default memo(TotalOrdersAssociatedsIllustration);

import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/hooks';
import { useSettingsAuthContext } from 'src/auth/settings';
import { NavListProps } from 'src/components/nav-section';
import RoleTypes from 'src/auth/roles';

const overviewPath = (user: any, selectedLocation: any, t: any, onUpdate: any) => {
  const locPath: { subheader: string; items: NavListProps[] } = {
    subheader: t('overview'),
    items: [
      {
        title: 'analytics',
        icon: <Iconify icon="tabler:chart-histogram" />,
        path: paths.dashboard.analytics.corporate,
      },
      {
        title: 'ristoranti',
        icon: <Iconify icon="tabler:building-store" />,
        path: paths.dashboard.associated.locations.root,
      },
      {
        title: 'dipendenti',
        icon: <Iconify icon="tabler:users-group" />,
        path: paths.dashboard.associated.people.root,
      },
      user.roles &&
        user.roles.includes(RoleTypes.ROLE_0000) && {
          title: 'QR Code',
          icon: <Iconify icon="tabler:qrcode" />,
          path: paths.dashboard.qrcode.corporate,
        },
    ],
  };

  if (user && user.locations && selectedLocation && selectedLocation.locationId) {
    let select = user.locations.find((l: any) => l.locationId === selectedLocation.locationId);
    if (!select) {
      onUpdate('selectedLocation', {
        businessId: user.locations[0].businessId,
        locationId: user.locations[0].locationId,
      });

      select = user.locations[0];
    }
    let home: NavListProps;
    if (user.locations.length > 1) {
      const child = user.locations.map((l: any) => ({
        title: l.locationName,
        path:
          select.locationId === l.locationId
            ? paths.dashboard.root
            : paths.dashboard.location.select(l.businessId, l.locationId),
      }));
      home = {
        title: 'Home',
        path: paths.dashboard.location.select(select.businessId, select.locationId),
        icon: <Iconify icon="tabler:home" />,
        children: child,
      };
    } else {
      home = {
        title: 'Home',
        path: paths.dashboard.location.select(select.businessId, select.locationId),
        icon: <Iconify icon="tabler:home" />,
      };
    }

    locPath.items.unshift(home);
  }

  return locPath;
};

export function Role0200Navigation() {
  const { user } = useAuthContext();
  const authSettings = useSettingsAuthContext();
  const { t } = useLocales();

  const data: { subheader: string; items: NavListProps[] }[] = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------

      overviewPath(user, authSettings.selectedLocation, t, authSettings.onUpdate),
      {
        subheader: 'settings',
        items: [
          {
            title: 'Account',
            path: paths.dashboard.location.account.root,
            icon: <Iconify icon="tabler:user" />,
            children: [
              {
                title: 'billing',
                path: paths.dashboard.location.account.billing.root,
              },
            ],
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, authSettings.selectedLocation]
  );

  return data;
}

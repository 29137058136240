import { API, graphqlOperation } from 'aws-amplify';
import {
  getOrderMinimalQuery,
  getOrderQuery,
  orderByLocationQuery,
  updateOrderStatusQuery,
} from 'src/backend/queries';
import { payOrder as payOrderLambda } from 'src/backend/graphql/mutations';
import { callGraphQL } from 'src/utils/api';
import { GraphQLQuery } from '@aws-amplify/api';
import { OrderStatus } from '../API';

export async function getOrdersByLocationApi(locationId: string) {
  try {
    const listOrders = await callGraphQL(orderByLocationQuery, {
      locationId,
    });

    if (
      !listOrders ||
      !listOrders.data ||
      !listOrders.data.orderByLocation ||
      !listOrders.data.orderByLocation.items
    )
      throw new Error('No location found');

    return listOrders.data.orderByLocation.items;
  } catch (err) {
    return [];
  }
}

export async function getOrderMinimalApi(orderId: string) {
  try {
    const data = (await API.graphql(graphqlOperation(getOrderMinimalQuery, { id: orderId }))) as {
      data: any;
    };

    if (!data || !data.data || !data.data.getOrder) throw new Error('No order found');

    return data.data.getOrder;
  } catch (err) {
    return null;
  }
}

export async function getOrderApi(orderId: string) {
  try {
    const data = (await API.graphql(graphqlOperation(getOrderQuery, { id: orderId }))) as {
      data: any;
    };
    if (!data || !data.data || !data.data.getOrder) throw new Error('No order found');

    return data.data.getOrder;
  } catch (err) {
    return null;
  }
}

export async function payOrderApi(orderId: string, orderData: any) {
  try {
    const data = (await API.graphql({
      ...graphqlOperation(payOrderLambda, {
        orderID: orderId,
        ...orderData,
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as any;

    if (!data || !data.data || !data.data.payOrder) throw new Error('Error on pay order');

    return data.data.payOrder;
  } catch (err) {
    return null;
  }
}

export async function updateOrderStatusApi(orderId: string, newOrderStatus: OrderStatus) {
  try {
    const updateOrderQuery = await API.graphql<GraphQLQuery<any>>({
      query: updateOrderStatusQuery,
      variables: {
        id: orderId,
        status: newOrderStatus,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    console.log('data', updateOrderQuery);

    if (!updateOrderQuery || !updateOrderQuery.data || !updateOrderQuery.data.updateOrder)
      throw new Error('Error on update order');

    return updateOrderQuery.data.updateOrder;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

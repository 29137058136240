/* eslint-disable react/style-prop-object */
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function OrdersPreviousMonthIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 1080 1080"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="m787.54,254.48c-5.3-1.99-9.49,153.6-205.54,223.24,0,0,120.16,90.16,307.55,50.62,0,0,135.78-89.44,45.78-274.92,0,0-69.03,30.57-147.79,1.06"
        style={{ fill: '#f4f4f5' }}
      />
      <path
        d="m943.33,465.9s30-194-52-226c0,0,82.45-4.99,98,60,0,0,15.55,72-46,166"
        style={{ fill: '#e5e5e5' }}
      />
      <path
        d="m581.42,120.63s-42.73,73.41-143.01,44.13c0,0,52.72,93.22-114.55,106.63l207.08-21.72s109.33-41.42,50.49-129.04"
        style={{ fill: '#fff' }}
      />
      <path
        d="m349.48,252.6s270.14,46.85,227.48-184.46c0,0,141.7,187.39-83.7,216.67,0,0-169.77,3.25-199.05,83.61,0,0-21.21-71.89,55.27-115.81"
        style={{ fill: '#f4f4f5' }}
      />
      <path
        d="m650.16,147.14s13.46,95.65-29.24,109.31c0,0,80.8,13.66,125.43-9.76,0,0,23.16-74.17-10.02-134.68,0,0-39.61,42.94-86.17,35.14"
        style={{ fill: '#fff' }}
      />
      <path
        d="m296.7,550.07s-199.83-64.22-211.58-270.14c0,0,84.5-158.9,269.99-211.8,0,0-13.58,143.06,70.83,194.51,0,0-146.88,111.11-129.25,287.42"
        style={{ fill: '#fff' }}
      />
      <path
        d="m196.88,267.37c-3.34,7.51-2.42,15.36,2.8,23.48,3.25,4.96,7.48,8,12.62,9.09,5.1,1.06,10.1-.84,15.01-5.67,6.44-6.36,8.63-13.82,6.7-22.38-1.05-4.84-3.98-11.23-8.75-19.19l14.37-12.64c4.49,7.5,8.21,12.2,11.1,14.12,4.93,3.32,10.15,2.22,15.65-3.24,3.54-3.52,5.66-7.64,6.41-12.33.78-4.66-.09-9.25-2.61-13.79-2.86-5.22-6.37-7.8-10.58-7.74-4.25.04-8.82,2.22-13.71,6.59l-11.43-21.68c5.35-3.87,10.77-6.74,16.25-8.64,4.91-1.35,9.98-1.43,15.2-.27,3.74.94,7.19,2.84,10.37,5.71,3.17,2.84,6.22,7.06,9.18,12.67,5.61,10.36,8.08,20.91,7.46,31.81-.44,11.03-4.13,20.59-11.29,28.62-5.12,5.72-10.79,8.56-17.02,8.46-3.96-.12-7.29-1.16-9.95-3.09,1.36,2.12,1.9,6.65,1.68,13.63-.22,10.55-3.89,19.92-11.2,28.07-7.77,8.63-17.37,12.58-28.91,11.67-11.78-1.07-23.11-9.2-33.54-24.37-12.36-18.84-15.3-36.23-9.04-51.82,3.46-8.13,9.66-15.82,18.48-23.07l13.99,24.53c-4.31,3.61-7.4,7.43-9.25,11.46"
        style={{ fill: '#4d4d4d' }}
      />
      <path
        d="m329.09,285.63l-78.93,111.2-18.6-22.65,60.4-74.83-8.71-12.99,12.24-14.44c.73.83,1.39,1.52,1.95,2.04,3.17,2.9,6.6,4.18,10.26,3.84,2.48-.25,5.17-1.51,8.06-3.78,1.71-1.38,2.9-2.53,3.58-3.43l9.75,15.03h0Z"
        style={{ fill: '#4d4d4d' }}
      />
      <path
        d="m461.81,882.83l305.49,38.06c47.81,5.96,91.4-27.97,97.36-75.79l44.63-358.31c6.22-49.96-29.23-95.51-79.2-101.74l-312.5-38.93c-40.97-5.1-78.33,23.97-83.43,64.95l-46.85,376.07c-5.85,46.99,27.5,89.84,74.49,95.69"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m430.77,878.96l305.49,38.06c47.81,5.96,91.4-27.97,97.36-75.79l44.63-358.31c6.22-49.96-29.23-95.51-79.2-101.74l-312.5-38.93c-40.97-5.1-78.33,23.97-83.43,64.95l-46.85,376.07c-5.85,47,27.5,89.84,74.49,95.69"
        style={{ fill: PRIMARY_MAIN }}
      />
      <path
        d="m875.91,501.74s-52.7,370.54-112.81,404.26c0,0-316.65-64.5-425.14-68.9l61.13-397.52,476.82,62.16Z"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m399.1,439.57l476.82,62.16s-15.33,313.21-176.51,389.53c-7.08,3.35-15.13,3.97-22.69,1.92-56.11-15.19-309.69-82.73-428.01-95.38-6.61-.71-9.39-8.85-4.6-13.45,31.55-30.25,109.39-124.04,155-344.79"
        style={{ fill: '#f4f4f5' }}
      />
      <path
        d="m740.12,428.81c-2.06,10.11-9.36,17.17-16.3,15.76s-10.9-10.76-8.84-20.88c2.06-10.11,9.36-17.17,16.3-15.76s10.9,10.76,8.84,20.88"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m761.35,285.12c-36.91,0-66.94,37.63-66.94,83.88,0,28.86,11.72,54.3,29.48,69.39,2.71,2.3,6.64,2.54,9.48.4,0,0,5.44-3.38,6.44-11.51.67-5.46-1.2-10.95-4.67-15.23-8.57-10.59-14.1-26.09-14.1-43.05,0-31.03,18.46-57.25,40.31-57.25,11.47,0,21.97,7.26,29.39,18.61l18.38-20.01c-12.16-15.55-29.07-25.23-47.77-25.23"
        style={{ fill: '#fff' }}
      />
      <path
        d="m800.61,381.51l26.44,3.35c.79-5.14,1.24-10.44,1.24-15.86,0-22.82-7.33-43.52-19.17-58.65l-18.38,20.01c6.72,10.28,10.92,23.89,10.92,38.64,0,4.28-.38,8.46-1.05,12.51"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m486.68,397.07c-2.06,10.11-9.36,17.17-16.3,15.76s-10.9-10.76-8.84-20.88c2.06-10.11,9.36-17.17,16.3-15.76s10.9,10.76,8.84,20.88"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m507.9,253.38c-36.91,0-66.94,37.63-66.94,83.88,0,28.86,11.72,54.3,29.48,69.39,2.71,2.3,6.64,2.54,9.48.4,0,0,5.44-3.38,6.44-11.51.67-5.46-1.21-10.95-4.67-15.23-8.57-10.58-14.1-26.09-14.1-43.05,0-31.03,18.46-57.25,40.31-57.25,11.47,0,21.97,7.26,29.39,18.61l18.38-20.01c-12.16-15.55-29.07-25.23-47.77-25.23"
        style={{ fill: '#fff' }}
      />
      <path
        d="m547.16,349.77l26.44,3.35c.79-5.14,1.24-10.44,1.24-15.86,0-22.82-7.33-43.52-19.17-58.65l-18.38,20.01c6.72,10.28,10.92,23.89,10.92,38.64,0,4.29-.38,8.46-1.05,12.51"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m559.98,409.76c-2.06,10.11-9.36,17.17-16.3,15.76s-10.9-10.76-8.84-20.88c2.06-10.11,9.36-17.17,16.3-15.76s10.9,10.76,8.84,20.88"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m581.2,266.07c-36.91,0-66.94,37.63-66.94,83.89,0,28.86,11.72,54.3,29.48,69.39,2.71,2.3,6.64,2.54,9.48.4,0,0,5.44-3.38,6.44-11.51.67-5.46-1.2-10.95-4.67-15.23-8.57-10.58-14.1-26.09-14.1-43.05,0-31.03,18.46-57.25,40.31-57.25,11.47,0,21.97,7.26,29.39,18.61l18.38-20.01c-12.16-15.55-29.07-25.23-47.77-25.23"
        style={{ fill: '#fff' }}
      />
      <path
        d="m620.28,358.92l27.18,3.39c.68-5.59.69-10.1.69-12.34,0-22.82-7.33-43.52-19.17-58.65l-18.38,20.01c6.72,10.28,10.92,23.89,10.92,38.64,0,2.06-.47,5.49-1.23,8.96"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m875.91,501.74s-27.24,308.99-327.96,318.02c-6.66.2-13.28-.8-19.53-3.13-50.79-18.88-308.02-112.76-466.75-138.26-5.88-.94-5.76-9.4.13-10.21,93.42-12.82,298.7-60,337.29-228.59"
        style={{ fill: '#fff' }}
      />
      <path
        d="m813.42,441.45c-2.06,10.11-9.36,17.17-16.3,15.76s-10.9-10.76-8.84-20.88c2.06-10.11,9.36-17.17,16.3-15.76s10.9,10.76,8.84,20.88"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m834.65,297.76c-36.91,0-66.94,37.63-66.94,83.89,0,28.86,11.72,54.3,29.48,69.39,2.71,2.3,6.64,2.54,9.48.4,0,0,5.44-3.38,6.44-11.51.67-5.46-1.2-10.95-4.67-15.23-8.57-10.58-14.1-26.09-14.1-43.05,0-31.04,18.46-57.25,40.31-57.25,11.47,0,21.97,7.26,29.39,18.61l18.38-20.01c-12.16-15.55-29.07-25.23-47.77-25.23"
        style={{ fill: '#fff' }}
      />
      <path
        d="m870.36,400.24s4.27,2.53,11.24,9.11,10.38,11.72,10.38,11.72c9.6-15.4,9.6-34,9.6-39.43,0-22.82-7.33-43.52-19.17-58.65l-18.38,20.01c6.72,10.28,10.92,23.9,10.92,38.64,0,4.29-1.99,14.53-4.59,18.6"
        style={{ fill: '#bdc0c2' }}
      />
      <path d="m603.37,570.07l-102.93,141.87-44.23-17.03,75.24-94.96-26.2-9.34,15.68-19.39c2.11.5,3.97.9,5.52,1.17,8.68,1.5,16.86,1.28,24.51-.68,5.22-1.35,10.25-3.83,15.09-7.43,2.86-2.18,4.75-3.89,5.66-5.11l31.66,10.9h0Z" />
    </Box>
  );
}

export default memo(OrdersPreviousMonthIllustration);

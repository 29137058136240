import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as api from 'src/backend/API';
import { listCategoriesProducts } from 'src/backend/graphql/queries';
import {
  getCategoryLocationQuery,
  getCategoryQuery,
  getProductWithCategoriesQuery,
  deleteProduct,
  deleteCategoriesProductsQuery,
  listProducts,
  createCategoriesProducts,
  deleteCategoriesProducts,
} from 'src/backend/queries';
import * as mutations from 'src/backend/graphql/mutations';
import { callGraphQL } from 'src/utils/api';
import { generatePLUFromName } from '../utils/format-graphql';
import { updateCategoryApi } from './category';

// ----------------------------------------------------------------------

export async function getProductsByCategoryApi(categoryId: string) {
  try {
    const categoryQuery = (await API.graphql(
      graphqlOperation(getCategoryQuery, { id: categoryId })
    )) as {
      data: any;
    };

    if (!categoryQuery || !categoryQuery.data || !categoryQuery.data.getCategory)
      throw new Error('No category found');

    const sortProducts = categoryQuery.data.getCategory.products.items
      .sort((a: any, b: any) => {
        const indexA = categoryQuery.data.getCategory.subProductSortOrder
          ? categoryQuery.data.getCategory.subProductSortOrder.indexOf(a.product.plu)
          : -1;
        const indexB = categoryQuery.data.getCategory.subProductSortOrder
          ? categoryQuery.data.getCategory.subProductSortOrder.indexOf(b.product.plu)
          : -1;
        return indexA - indexB;
      })
      .map((a: any) => a.product);

    return sortProducts as api.Product[];
  } catch (err) {
    console.log('err', err);

    return null;
  }
}

export async function addProductToCategoryApi(
  categoryId: string,
  productId: string,
  products: api.Product[]
) {
  try {
    const category = await API.graphql<GraphQLQuery<any>>({
      query: getCategoryLocationQuery,
      variables: { id: categoryId },
    });

    if (!category.data) throw new Error('Error get category');

    const ccp = await API.graphql<GraphQLQuery<any>>({
      query: createCategoriesProducts,
      variables: {
        categoryID: categoryId,
        productID: productId,
        locationId: category.data.getCategory.locationId,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    console.log('ccp', ccp);

    if (!ccp.data) throw new Error('Error create CategoriesProducts');

    const order: string[] = ccp.data.createCategoriesProducts.category.subProductSortOrder;
    const productPLU: string = ccp.data.createCategoriesProducts.product.plu;

    order.push(productPLU);

    const updateSortOrder = await updateCategoryApi(ccp.data.createCategoriesProducts.category.id, {
      subProductSortOrder: order,
    });

    if (!updateSortOrder) {
      throw new Error('Error on update products sort order');
    }

    const newProducts = [...products];
    newProducts.push(ccp.data.createCategoriesProducts.product);
    return newProducts;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}
export async function updateProductsApi(newProductsData: api.Product[]) {
  try {
    if (newProductsData.length > 0) {
      // eslint-disable-next-line guard-for-in
      for (const newProductData in newProductsData) {
        // eslint-disable-next-line no-await-in-loop
        const updateProduct = await API.graphql<GraphQLQuery<any>>({
          query: mutations.updateProduct,
          variables: {
            input: {
              ...newProductsData[newProductData],
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        if (!updateProduct || !updateProduct.data) throw new Error('Update error');
      }
    }
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function removeProductFromCategoryApi(
  categoryId: string,
  productId: string,
  products: api.Product[]
) {
  try {
    const listCP = await callGraphQL(listCategoriesProducts, {
      filter: {
        categoryID: { eq: categoryId },
        productID: { eq: productId },
      },
    });

    if (!listCP.data || listCP.data.listCategoriesProducts.items.length < 1)
      throw new Error('Error find CategoriesProducts');

    const cpId = listCP.data.listCategoriesProducts.items[0].id;

    const dcp = await API.graphql<GraphQLQuery<any>>({
      query: deleteCategoriesProducts,
      variables: {
        id: cpId,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (
      !dcp ||
      !dcp.data.deleteCategoriesProducts.category.id ||
      !dcp.data.deleteCategoriesProducts.category.subProductSortOrder ||
      !dcp.data.deleteCategoriesProducts.product.plu
    )
      throw new Error('deleteCategoriesProducts data not valid');

    const newIndex = dcp.data.deleteCategoriesProducts.category.subProductSortOrder.findIndex(
      (item: string) => item === dcp.data.deleteCategoriesProducts.product.plu
    );
    const newOrder: any = dcp.data.deleteCategoriesProducts.category.subProductSortOrder;
    if (newIndex !== -1) {
      newOrder.splice(newIndex, 1);
    }

    // eslint-disable-next-line no-await-in-loop
    const updateSortOrder = await updateCategoryApi(dcp.data.deleteCategoriesProducts.category.id, {
      subProductSortOrder: newOrder,
    });

    if (!updateSortOrder) throw new Error('Error update products sort order');

    const newProducts = products.filter((row) => row.id !== productId);
    return newProducts;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

export async function getProductApi(productId: string) {
  try {
    const data = (await API.graphql(
      graphqlOperation(getProductWithCategoriesQuery, { id: productId })
    )) as {
      data: any;
    };
    if (!data || !data.data || !data.data.getProduct) throw new Error('No product found');

    return data.data.getProduct;
  } catch (err) {
    return null;
  }
}

function getProductCategoriesId(product: api.Product | undefined): string[] {
  if (product && product.categories && product.categories.items) {
    const categoryIDs = product.categories.items.map((cp: api.CategoriesProducts | null) => {
      if (cp && typeof cp.categoryID === 'string') {
        return cp.categoryID;
      }
      return null;
    });

    return categoryIDs.filter((id: string | null): id is string => id !== null) as string[];
  }
  return [];
}

function compareProductCategories(currentProduct: api.Product, newCategories: string[]) {
  const matchingIds: api.UpdateCategoriesProductsInput[] = [];
  const addedIds: api.CreateCategoriesProductsInput[] = [];
  const removedIds: api.DeleteCategoriesProductsInput[] = [];

  const oldCategories = getProductCategoriesId(currentProduct);

  newCategories.forEach((category) => {
    if (oldCategories.includes(category) && currentProduct.categories) {
      const c = currentProduct.categories.items.find((item: any) => item.categoryID === category);
      if (c) matchingIds.push(c);
    } else {
      addedIds.push({
        productID: currentProduct.id,
        categoryID: category,
        locationId: currentProduct.locationId,
      });
    }
  });

  oldCategories.forEach((category) => {
    if (!newCategories.includes(category) && currentProduct.categories) {
      const c = currentProduct.categories.items.find((item: any) => item.categoryID === category);
      if (c) removedIds.push({ id: c.id });
    }
  });

  return { matchingIds, addedIds, removedIds };
}

async function updateProductCategoriesApi(
  addCategories: api.CreateCategoriesProductsInput[],
  removeCategories: api.DeleteCategoriesProductsInput[]
) {
  try {
    // eslint-disable-next-line guard-for-in
    for (const addCategory in addCategories) {
      // eslint-disable-next-line no-await-in-loop
      const ccp = await API.graphql<GraphQLQuery<any>>({
        query: createCategoriesProducts,
        variables: {
          locationId: addCategories[addCategory].locationId,
          categoryID: addCategories[addCategory].categoryID,
          productID: addCategories[addCategory].productID,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      console.log('ccp', ccp);
      if (
        !ccp ||
        !ccp.data.createCategoriesProducts.category.id ||
        !ccp.data.createCategoriesProducts.category.subProductSortOrder ||
        !ccp.data.createCategoriesProducts.product.plu
      )
        throw new Error('Dati non validi');
      const cppSort = ccp.data.createCategoriesProducts.category.subProductSortOrder;
      cppSort.push(ccp.data.createCategoriesProducts.product.plu);

      // eslint-disable-next-line no-await-in-loop
      await updateCategoryApi(ccp.data.createCategoriesProducts.category.id, {
        subProductSortOrder: cppSort,
      });
    }

    // eslint-disable-next-line guard-for-in
    for (const removeCategory in removeCategories) {
      // eslint-disable-next-line no-await-in-loop
      const dcp = await API.graphql<GraphQLQuery<any>>({
        query: deleteCategoriesProducts,
        variables: {
          id: removeCategories[removeCategory].id,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      console.log('dcp', dcp);

      if (
        !dcp ||
        !dcp.data.deleteCategoriesProducts.category.id ||
        !dcp.data.deleteCategoriesProducts.category.subProductSortOrder ||
        !dcp.data.deleteCategoriesProducts.product.plu
      )
        throw new Error('Dati non validi');

      const newIndex = dcp.data.deleteCategoriesProducts.category.subProductSortOrder.findIndex(
        (item: string) => item === dcp.data.deleteCategoriesProducts.product.plu
      );
      const newOrder: any = dcp.data.deleteCategoriesProducts.category.subProductSortOrder;
      if (newIndex !== -1) {
        newOrder.splice(newIndex, 1);
      }

      // eslint-disable-next-line no-await-in-loop
      await updateCategoryApi(dcp.data.deleteCategoriesProducts.category.id, {
        subProductSortOrder: newOrder,
      });
    }

    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function createProductApi(productId: string, createData: any) {
  try {
    const { categories, ...upData } = createData;

    const createProduct = await API.graphql<GraphQLQuery<any>>({
      query: mutations.createProduct,
      variables: {
        input: {
          id: productId,
          productType: 1,
          plu: generatePLUFromName(upData.name),
          capacityUsages: [],
          deliveryTax: 0,
          eatInTax: 0,
          image: '',
          max: 0,
          min: 0,
          multiply: 1,
          posCategoryIds: [],
          posProductCategoryId: '',
          posProductId: '',
          productTags: [],
          subProductSortOrder: [],
          takeawayTax: 0,
          ...upData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    console.log('createProduct', createProduct);
    if (!createProduct.data) throw new Error('Error create product');

    if (categories) {
      const c = compareProductCategories(createProduct.data.createProduct, categories);

      const updateCategoriesQuery = await updateProductCategoriesApi(c.addedIds, c.removedIds);

      console.log('c', c);
      console.log('updateCategoriesQuery', updateCategoriesQuery);
      if (!updateCategoriesQuery) throw new Error('Update categories error');
    }

    return createProduct.data.createProduct;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

export async function updateProductApi(productId: string, updateData: any) {
  try {
    const data = await getProductApi(productId);
    console.log('data', data);
    if (!data) throw new Error('Dati non validi');

    const { categories, ...upData } = updateData;

    if (categories) {
      const c = compareProductCategories(data, categories);
      const updateCategoriesQuery = await updateProductCategoriesApi(c.addedIds, c.removedIds);
      console.log('updateCategoriesQuery', updateCategoriesQuery);
      if (!updateCategoriesQuery) throw new Error('Update categories error');
    }

    const updateProduct = await API.graphql<GraphQLQuery<any>>({
      query: mutations.updateProduct,
      variables: {
        input: {
          id: productId,
          ...upData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (updateProduct && updateProduct.data) {
      return updateProduct.data.updateProduct;
    }
    throw new Error('Update error');
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

export async function deleteProductApi(productId: string) {
  try {
    const data = await getProductApi(productId);
    if (!data) throw new Error('No product');

    /* await saveModifierGroupsDiff(
      {
        id: data.id,
        modifierGroups: data.modifierGroups ? data.modifierGroups.items : [],
      },
      {
        locationId: data.locationId,
        businessId: data.businessId,
      },
      formatModifierGroups(data.modifierGroups && data.modifierGroups ? data.modifierGroups.items : []),
      []
    ); */

    if (data.categories && data.categories.items && data.categories.items.length > 0) {
      // eslint-disable-next-line guard-for-in
      for (const categories in data.categories.items) {
        const cpId = data.categories.items[categories]?.id;

        if (cpId) {
          const input: api.DeleteCategoriesProductsInput = {
            id: cpId,
          };

          // eslint-disable-next-line no-await-in-loop
          await API.graphql<GraphQLQuery<any>>({
            query: deleteCategoriesProductsQuery,
            variables: {
              input,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
        }
      }
    }

    const dp = await API.graphql<GraphQLQuery<any>>({
      query: deleteProduct,
      variables: {
        input: {
          id: data.id,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (!dp.data || !dp.data.deleteProduct) throw new Error('Error create product');

    return true;
    // throw new Error('Update error');
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function getProductsByLocation(locationId: string) {
  try {
    const listAllProduct = await callGraphQL(listProducts, {
      filter: {
        and: {
          locationId: { eq: locationId },
          productType: { eq: 1 },
          isVariant: { ne: true },
        },
      },
    });
    if (!listAllProduct || !listAllProduct.data || !listAllProduct.data.listProducts)
      throw new Error('No product found');

    return listAllProduct.data.listProducts.items;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

export async function getVariantsByLocation(locationId: string) {
  try {
    const listAllProduct = await callGraphQL(listProducts, {
      filter: {
        and: {
          locationId: { eq: locationId },
          productType: { eq: 1 },
          isVariant: { eq: true },
        },
      },
    });
    if (!listAllProduct || !listAllProduct.data || !listAllProduct.data.listProducts)
      throw new Error('No product found');

    return listAllProduct.data.listProducts.items;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

export async function getProductsAndVariantsByLocation(locationId: string) {
  try {
    const listAllProduct = await callGraphQL(listProducts, {
      filter: {
        and: {
          locationId: { eq: locationId },
          productType: { eq: 1 },
        },
      },
    });
    if (!listAllProduct || !listAllProduct.data || !listAllProduct.data.listProducts)
      throw new Error('No product found');

    return listAllProduct.data.listProducts.items;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

export async function getWinesByLocation(locationId: string) {
  try {
    const listAllProduct = await callGraphQL(listProducts, {
      filter: {
        and: {
          locationId: { eq: locationId },
          productType: { eq: 1 },
          isWine: { eq: true },
        },
      },
    });
    if (!listAllProduct || !listAllProduct.data || !listAllProduct.data.listProducts)
      throw new Error('No product found');

    return listAllProduct.data.listProducts.items;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

export async function getModifiersGroupByLocation(locationId: string) {
  try {
    const listAllProduct = await callGraphQL(listProducts, {
      filter: {
        and: {
          locationId: { eq: locationId },
          productType: { eq: 3 },
        },
      },
    });
    if (!listAllProduct || !listAllProduct.data || !listAllProduct.data.listProducts)
      throw new Error('No product found');

    return listAllProduct.data.listProducts.items;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

export async function handleProductVariantsApi(
  productId: string,
  productName: string,
  businessId: string,
  locationId: string,
  oldProductVariants: any,
  newProductVariants: any
) {
  if (oldProductVariants) {
    const oldItems = oldProductVariants.subProducts.product.subProducts.items;
    const newItems = newProductVariants.subProducts.product.subProducts.items;

    const removedItems = oldItems.filter(
      (oldItem: any) => !newItems.some((newItem: any) => oldItem.id === newItem.id)
    );

    const addedItems = newItems.filter(
      (newItem: any) => !oldItems.some((oldItem: any) => newItem.id === oldItem.id)
    );

    // eslint-disable-next-line guard-for-in
    for (const removedItem in removedItems) {
      console.log('removedItem', removedItems[removedItem]);

      try {
        // eslint-disable-next-line no-await-in-loop
        await API.graphql<GraphQLQuery<any>>({
          query: mutations.deleteProductsSubProducts,
          variables: {
            input: {
              id: removedItems[removedItem].id,
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
      } catch (error) {
        console.log('error', error);
      }

      try {
        // eslint-disable-next-line no-await-in-loop
        await API.graphql<GraphQLQuery<any>>({
          query: mutations.deleteSubProducts,
          variables: {
            input: {
              id: removedItems[removedItem].subProducts.id,
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
      } catch (error) {
        console.log('error', error);
      }
    }

    // eslint-disable-next-line guard-for-in
    for (const addedItem in addedItems) {
      console.log('addedItem', addedItems[addedItem]);

      try {
        console.log('locationId1', locationId);
        // eslint-disable-next-line no-await-in-loop
        const sp = await API.graphql<GraphQLQuery<any>>({
          query: mutations.createSubProducts,
          variables: {
            input: {
              businessId,
              locationId,
              productId: addedItems[addedItem].subProducts.product.id,
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        if (!sp.data) throw new Error('Error createSubProducts');

        console.log('locationId1', locationId);
        // eslint-disable-next-line no-await-in-loop
        await API.graphql<GraphQLQuery<any>>({
          query: mutations.createProductsSubProducts,
          variables: {
            input: {
              productID: oldProductVariants.subProducts.product.id,
              subProductsID: sp.data.createSubProducts.id,
              locationId,
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
      } catch (error) {
        console.log('error', error);
      }
    }
  } else {
    try {
      const p = await API.graphql<GraphQLQuery<any>>({
        query: mutations.createProduct,
        variables: {
          input: {
            businessId,
            locationId,
            name: `Choose your ${productName}`,
            plu: generatePLUFromName(`Choose your ${productName}`),
            price: 0,
            priceType: api.PriceType.addition,
            productType: 3,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });

      if (!p.data) throw new Error('Error createProduct');

      const sp = await API.graphql<GraphQLQuery<any>>({
        query: mutations.createSubProducts,
        variables: {
          input: {
            businessId,
            locationId,
            productId: p.data.createProduct.id,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });

      if (!sp.data) throw new Error('Error createSubProducts');

      const t = await API.graphql<GraphQLQuery<any>>({
        query: mutations.createProductsSubProducts,
        variables: {
          input: {
            productID: productId,
            subProductsID: sp.data.createSubProducts.id,
            locationId,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });

      console.log('t', t);

      const newItems = newProductVariants.subProducts.product.subProducts.items;

      console.log('newItems', newItems);

      // eslint-disable-next-line guard-for-in
      for (const newItem in newItems) {
        console.log('addedItem', newItems[newItem]);

        try {
          // eslint-disable-next-line no-await-in-loop
          const ssp = await API.graphql<GraphQLQuery<any>>({
            query: mutations.createSubProducts,
            variables: {
              input: {
                businessId,
                locationId,
                productId: newItems[newItem].subProducts.product.id,
              },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });

          if (!ssp.data) throw new Error('Error createSubProducts');

          // eslint-disable-next-line no-await-in-loop
          await API.graphql<GraphQLQuery<any>>({
            query: mutations.createProductsSubProducts,
            variables: {
              input: {
                productID: p.data.createProduct.id,
                subProductsID: ssp.data.createSubProducts.id,
                locationId,
              },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
        } catch (error) {
          console.log('error', error);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  }
  console.log('oldProductVariants', oldProductVariants);
  console.log('newProductVariants', newProductVariants);
  return true;
}

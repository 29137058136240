/* eslint-disable react/style-prop-object */
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function TotalLocationsAssociatedIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_LIGHTER = theme.palette.primary.lighter;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 1080 1080"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <svg
        id="uuid-592de5bf-3165-4108-836c-75e30a8c183c"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1080 1080"
      >
        <rect x="220.26" y="407.8" width="20" height="415.72" style={{ fill: '#232b35' }} />
        <rect x="841.51" y="407.8" width="20" height="415.72" style={{ fill: '#232b35' }} />
        <path
          d="m361.85,527.66c0-14.37-11.65-26.02-26.03-26.02s-26.03,11.65-26.03,26.02c0,1.14.1,2.25.24,3.36h51.57c.14-1.11.24-2.22.24-3.36Z"
          style={{ fill: '#6d6c6b' }}
        />
        <path
          d="m339.19,502.92c0,1.29-1.51,2.32-3.36,2.32h0c-1.85,0-3.36-1.04-3.36-2.32v-27.37c0-1.29,1.51-2.32,3.36-2.32h0c1.85,0,3.36,1.04,3.36,2.32v27.37Z"
          style={{ fill: '#7a7977' }}
        />
        <path
          d="m366.06,533.12c0,2.75-1.89,4.98-4.2,4.98h-52.06c-2.32,0-4.2-2.24-4.2-4.98h0c0-2.75,1.88-4.98,4.2-4.98h52.06c2.31,0,4.2,2.24,4.2,4.98h0Z"
          style={{ fill: '#7f7e7d' }}
        />
        <path
          d="m767.98,527.66c0-14.37-11.64-26.02-26.02-26.02s-26.04,11.65-26.04,26.02c0,1.14.11,2.25.25,3.36h51.57c.14-1.11.24-2.22.24-3.36Z"
          style={{ fill: '#6d6c6b' }}
        />
        <path
          d="m745.31,502.92c0,1.29-1.5,2.32-3.35,2.32h0c-1.85,0-3.36-1.04-3.36-2.32v-27.37c0-1.29,1.51-2.32,3.36-2.32h0c1.85,0,3.35,1.04,3.35,2.32v27.37Z"
          style={{ fill: '#7a7977' }}
        />
        <path
          d="m772.19,533.12c0,2.75-1.89,4.98-4.2,4.98h-52.07c-2.31,0-4.19-2.24-4.19-4.98h0c0-2.75,1.88-4.98,4.19-4.98h52.07c2.31,0,4.2,2.24,4.2,4.98h0Z"
          style={{ fill: '#7f7e7d' }}
        />
        <rect x="207.7" y="643.19" width="665.84" height="180.34" style={{ fill: '#dadada' }} />
        <polygon
          points="873.54 643.38 207.7 643.38 220.05 637.54 861.8 637.54 873.54 643.38"
          style={{ fill: '#fff' }}
        />
        <polygon
          points="303.4 214.76 185.09 433.04 894.91 433.04 776.6 214.76 303.4 214.76"
          style={{ fill: PRIMARY_MAIN }}
        />
        <polygon
          points="287.14 432.7 388.28 432.7 433.58 213.86 368.03 213.86 287.14 432.7"
          style={{ fill: '#fff' }}
        />
        <polygon
          points="489.42 432.7 590.56 432.7 576.85 214.39 504.42 214.39 489.42 432.7"
          style={{ fill: '#fff' }}
        />
        <polygon
          points="691.69 432.7 792.84 432.7 709.02 214.39 647.69 214.39 691.69 432.7"
          style={{ fill: '#fff' }}
        />
        <path
          d="m388.74,442.49c-.36-2.5-.52-4.6-.51-6.79l.05-3h-99.14l-2.32.35-.2,2.84c0,28.31,23.04,51.35,51.35,51.35,25.36,0,47.19-18.93,50.77-44.05l.05-.35-.05-.35Z"
          style={{ fill: '#bcbcbc' }}
        />
        <path
          d="m591.02,442.49c-.36-2.5-.52-4.6-.51-6.79l.05-3h-99.14l-2.32.35-.2,2.84c0,28.31,23.04,51.35,51.34,51.35,25.36,0,47.19-18.93,50.77-44.05l.05-.35-.05-.35Z"
          style={{ fill: '#bcbcbc' }}
        />
        <path
          d="m793.3,442.49c-.36-2.5-.52-4.6-.51-6.79l.05-3h-99.14l-2.31.35-.2,2.84c0,28.31,23.03,51.35,51.34,51.35,25.36,0,47.19-18.93,50.77-44.05l.05-.35-.05-.35Z"
          style={{ fill: '#bcbcbc' }}
        />
        <path
          d="m287.61,442.49c-.36-2.5-.53-4.6-.52-6.79l.05-3h-99.13l-2.32.35-.19,2.84c0,28.31,23.03,51.35,51.34,51.35,25.36,0,47.19-18.93,50.77-44.05l.05-.35-.05-.35Z"
          style={{ fill: PRIMARY_DARK }}
        />
        <path
          d="m489.89,442.49c-.35-2.5-.53-4.6-.52-6.79l.05-3h-99.14l-2.32.35-.19,2.84c0,28.31,23.03,51.35,51.34,51.35,25.36,0,47.19-18.93,50.77-44.05l.05-.35-.05-.35Z"
          style={{ fill: PRIMARY_DARK }}
        />
        <path
          d="m692.16,442.49c-.35-2.5-.53-4.6-.52-6.79l.05-3h-99.13l-2.32.35-.19,2.84c0,28.31,23.03,51.35,51.34,51.35,25.36,0,47.19-18.93,50.77-44.05l.05-.35-.05-.35Z"
          style={{ fill: PRIMARY_DARK }}
        />
        <path
          d="m894.42,442.04c-.36-2.5-.53-4.6-.51-6.79l.05-3.01h-99.13l-2.32.35-.2,2.85c0,28.31,23.03,51.34,51.34,51.34,25.36,0,47.19-18.93,50.77-44.05l.05-.34-.05-.35Z"
          style={{ fill: PRIMARY_DARK }}
        />
        <rect x="417.79" y="504.69" width="244.43" height="319.32" style={{ fill: '#d3d2d1' }} />
        <rect x="439.07" y="526.21" width="201.86" height="297.8" style={{ fill: '#232b35' }} />
        <polygon
          points="417.79 504.69 439.07 526.21 640.93 526.21 662.21 504.69 417.79 504.69"
          style={{ fill: '#fff' }}
        />
        <rect x="216.44" y="656.32" width="31.18" height="16.43" style={{ fill: '#fff' }} />
        <rect x="249.43" y="684.74" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="270.82" y="657.16" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="215.1" y="715.14" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="274.76" y="735.4" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="367.08" y="777.62" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="669.93" y="651.52" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="689.68" y="712.13" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="833.62" y="679.92" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="668.54" y="748.37" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="324.86" y="705" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <rect x="236.48" y="763.54" width="31.17" height="16.43" style={{ fill: '#fff' }} />
        <path
          d="m628.7,692.16c0,5.61-4.55,10.17-10.17,10.17s-10.17-4.55-10.17-10.17,4.55-10.17,10.17-10.17,10.17,4.55,10.17,10.17Z"
          style={{ fill: '#605b58' }}
        />
        <path
          d="m622.76,692.16c0,2.34-1.89,4.22-4.23,4.22s-4.23-1.89-4.23-4.22,1.89-4.23,4.23-4.23,4.23,1.89,4.23,4.23Z"
          style={{ fill: '#494541' }}
        />
        <rect
          x="462.04"
          y="549.53"
          width="154.14"
          height="116.61"
          style={{ fill: PRIMARY_LIGHTER }}
        />
        <path
          d="m604.1,561.61v92.45h-129.98v-92.45h129.98Zm12.08-12.08h-154.14v116.61h154.14v-116.61h0Z"
          style={{ fill: '#fff' }}
        />
        <polygon
          points="833.56 706.15 844.8 795.49 814.14 795.49 825.59 701.04 832.53 701.04 833.56 706.15"
          style={{ fill: '#44403c' }}
        />
        <polygon
          points="814.14 795.49 844.8 795.49 843.78 786.3 815.16 786.3 814.14 795.49"
          style={{ fill: '#936241' }}
        />
        <polygon
          points="710.95 657.7 733.11 835.11 744.45 835.11 721.51 657.99 710.95 657.7"
          style={{ fill: '#cc9671' }}
        />
        <polygon
          points="726.76 657.7 721.51 657.99 744.45 835.11 749.7 832.62 726.76 657.7"
          style={{ fill: '#936241' }}
        />
        <polygon
          points="822.16 663.84 844.33 835.11 855.67 835.11 832.73 664.11 822.16 663.84"
          style={{ fill: '#cc9671' }}
        />
        <polygon
          points="837.98 663.84 832.73 664.11 855.67 835.11 860.92 832.71 837.98 663.84"
          style={{ fill: '#936241' }}
        />
        <polygon
          points="719.49 665.43 823.53 665.43 813.15 800.74 705.92 799.99 719.49 665.43"
          style={{ fill: '#1d1d1b' }}
        />
        <polygon
          points="710.95 655.82 688.4 858.52 698.57 858.52 721.75 656.1 710.95 655.82"
          style={{ fill: '#cc9671' }}
        />
        <polygon
          points="698.57 858.52 704.79 856.54 727.12 655.82 721.75 656.1 698.57 858.52"
          style={{ fill: '#936241' }}
        />
        <polygon
          points="824.2 655.78 834.64 655.82 819.56 866.14 807.69 866.14 824.2 655.78"
          style={{ fill: '#cc9671' }}
        />
        <polygon
          points="839.64 662.32 834.64 655.82 819.56 866.14 825.22 863.89 839.64 662.32"
          style={{ fill: '#936241' }}
        />
        <polygon
          points="692.45 799.65 824.31 800.63 823.3 813.28 691.36 813.18 692.45 799.65"
          style={{ fill: '#f4c6a6' }}
        />
        <polygon
          points="692.45 799.65 695.17 798.78 825.73 799.87 824.31 800.63 692.45 799.65"
          style={{ fill: '#936241' }}
        />
        <polygon
          points="823.3 813.28 824.86 812.31 825.73 799.87 824.31 800.63 823.3 813.28"
          style={{ fill: '#cc9671' }}
        />
        <polygon
          points="709.13 655.17 835.46 656.16 834.49 668.8 708.09 668.71 709.13 655.17"
          style={{ fill: '#f4c6a6' }}
        />
        <polygon
          points="709.13 655.17 711.75 654.3 836.82 655.39 835.46 656.16 709.13 655.17"
          style={{ fill: '#936241' }}
        />
        <polygon
          points="834.49 668.8 835.98 667.84 836.82 655.39 835.46 656.16 834.49 668.8"
          style={{ fill: '#cc9671' }}
        />
      </svg>
    </Box>
  );
}

export default memo(TotalLocationsAssociatedIllustration);

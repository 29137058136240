import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { ColorSchemaValues, ColorSchema } from 'src/theme/palette';
//
import { IconifyProps } from './types';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: IconifyProps;
}

const Iconify = forwardRef<SVGElement, Props>(({ icon, width = 20, sx, color, ...other }, ref) => {
  const theme = useTheme();
  return (
    <Box
      ref={ref}
      component={Icon}
      className="component-iconify"
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
      color={
        color && typeof color === 'string' && ColorSchemaValues.includes(color as ColorSchema)
          ? (theme.palette as any)[color as string]
            ? (theme.palette as any)[color as string].main
            : color
          : color
      }
    />
  );
});

export default Iconify;

/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type LambdaCartType = {
  menuId: string,
  businessId: string,
  locationId: string,
  tableId?: string | null,
  items: Array< LambdaCartItemsType >,
};

export type LambdaCartItemsType = {
  id: string,
  type: number,
  quantity: number,
  subItems?: Array< LambdaCartItemsType > | null,
};

export type NewOrderResponse = NewOrderResponseSuccess | ErrorResponse


export type NewOrderResponseSuccess = {
  __typename: "NewOrderResponseSuccess",
  orderId?: string | null,
  code?: number | null,
};

export type ErrorResponse = {
  __typename: "ErrorResponse",
  error?: string | null,
  code?: number | null,
};

export type LambdaRegisterLocationBody = {
  channelLinkId: string,
  channelLinkName: string,
  channelLocationId: string,
  locationId: string,
  status: LocationStatus,
};

export enum LocationStatus {
  register = "register",
  active = "active",
  inactive = "inactive",
  paused = "paused",
}


export type RegisterLocationResponse = RegisterLocationResponseSuccess | ErrorResponse


export type RegisterLocationResponseSuccess = {
  __typename: "RegisterLocationResponseSuccess",
  locationId?: string | null,
  code?: number | null,
};

export type StripeAccountSessionResponse = StripeAccountSessionResponseSuccess | ErrorResponse


export type StripeAccountSessionResponseSuccess = {
  __typename: "StripeAccountSessionResponseSuccess",
  clientSecret?: string | null,
};

export type InvoiceUserAttributes = {
  fiscalCode: string,
  firstName: string,
  lastName: string,
  email: string,
  address: AddressInput,
};

export type AddressInput = {
  street: string,
  houseNumber: string,
  postalCode: string,
  city: string,
  province: string,
  country: string,
};

export type CreateInvoiceResponse = CreateInvoiceResponseSuccess | ErrorResponse


export type CreateInvoiceResponseSuccess = {
  __typename: "CreateInvoiceResponseSuccess",
  invoiceId?: string | null,
  code?: number | null,
};

export type SendInvoiceResponse = SendInvoiceResponseSuccess | ErrorResponse


export type SendInvoiceResponseSuccess = {
  __typename: "SendInvoiceResponseSuccess",
  send: boolean,
};

export type SendReceiptResponse = SendReceiptResponseSuccess | ErrorResponse


export type SendReceiptResponseSuccess = {
  __typename: "SendReceiptResponseSuccess",
  send: boolean,
};

export type RemoveCardResponse = RemoveCardSuccess | ErrorResponse


export type RemoveCardSuccess = {
  __typename: "RemoveCardSuccess",
  success: boolean,
};

export type CreateBusinessInput = {
  id?: string | null,
  name: string,
};

export type ModelBusinessConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelBusinessConditionInput | null > | null,
  or?: Array< ModelBusinessConditionInput | null > | null,
  not?: ModelBusinessConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Business = {
  __typename: "Business",
  id: string,
  name: string,
  locations?: ModelLocationConnection | null,
  corporate?: ModelCorporateConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location | null >,
  nextToken?: string | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  name?: string | null,
  images?: Array< string | null > | null,
  logo?: string | null,
  bio?: string | null,
  bioTranslations?:  Array<Translation | null > | null,
  description?: string | null,
  descriptionTranslations?:  Array<Translation | null > | null,
  gallery?:  Array<ImageGallery | null > | null,
  posts?: ModelPostConnection | null,
  stripeInfo?: StripeInfo | null,
  invoiceInfo?: InvoiceInfo | null,
  openingDay?: OpeningDay | null,
  deliverectId: string,
  status: LocationStatus,
  timeZone: string,
  menu?: ModelMenuConnection | null,
  menuSortOrder?: Array< string | null > | null,
  floors?: ModelFloorConnection | null,
  credit: number,
  selfOrdering: boolean,
  externalLinks?:  Array<ExternaLink > | null,
  template: Template,
  color: string,
  showCurrency: boolean,
  showWineList?: boolean | null,
  address?: Address | null,
  phoneNumber?: string | null,
  social?:  Array<SocialLink | null > | null,
  email?: string | null,
  languageDefault: string,
  languagesEnabled?: Array< string | null > | null,
  translationsInProgress?: boolean | null,
  coverPrice?: number | null,
  deliveryCost?: number | null,
  generalVatRate?: number | null,
  businessLocationsId: string,
  business?: Business | null,
  associatedCorporate?: ModelCorporateLocationsConnection | null,
  presences?: ModelAssociatedPresenceConnection | null,
  bankDetails?: BankDetails | null,
  createdAt: string,
  updatedAt: string,
};

export type Translation = {
  __typename: "Translation",
  language: string,
  body: string,
};

export type ImageGallery = {
  __typename: "ImageGallery",
  image: string,
  name: string,
  nameTranslations?:  Array<Translation | null > | null,
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection",
  items:  Array<Post | null >,
  nextToken?: string | null,
};

export type Post = {
  __typename: "Post",
  id: string,
  image?: string | null,
  name: string,
  nameTranslations?:  Array<Translation | null > | null,
  userId: string,
  userName: string,
  postLocationId: string,
  createdAt: string,
  updatedAt: string,
};

export type StripeInfo = {
  __typename: "StripeInfo",
  stripeCostumer?: string | null,
  stripeAccount?: string | null,
  stripeSubscriptionType?: StripeSubscriptionType | null,
  stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
  stripeDefaultPaymentMethod?: boolean | null,
};

export enum StripeSubscriptionType {
  free = "free",
  premium = "premium",
}


export enum StripeSubscriptionStatus {
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  trialing = "trialing",
  active = "active",
  paused = "paused",
  past_due = "past_due",
  canceled = "canceled",
  unpaid = "unpaid",
}


export type InvoiceInfo = {
  __typename: "InvoiceInfo",
  taxCode: string,
  broadcastFormat: string,
  businessName: string,
  taxRegime: string,
  registeredOffice: Address,
};

export type Address = {
  __typename: "Address",
  street: string,
  houseNumber: string,
  postalCode: string,
  city: string,
  province: string,
  country: string,
};

export type OpeningDay = {
  __typename: "OpeningDay",
  monday?: OpeningHours | null,
  tuesday?: OpeningHours | null,
  wednesday?: OpeningHours | null,
  thursday?: OpeningHours | null,
  friday?: OpeningHours | null,
  saturday?: OpeningHours | null,
  sunday?: OpeningHours | null,
};

export type OpeningHours = {
  __typename: "OpeningHours",
  slots:  Array<Slot >,
};

export type Slot = {
  __typename: "Slot",
  start: string,
  end: string,
};

export type ModelMenuConnection = {
  __typename: "ModelMenuConnection",
  items:  Array<Menu | null >,
  nextToken?: string | null,
};

export type Menu = {
  __typename: "Menu",
  id: string,
  businessId: string,
  menuLocationId: string,
  location: Location,
  name: string,
  nameTranslations?:  Array<Translation | null > | null,
  description?: string | null,
  descriptionTranslations?:  Array<Translation | null > | null,
  dense?: boolean | null,
  type?: number | null,
  nestedModifiers: boolean,
  image?: Array< string | null > | null,
  currency?: number | null,
  availabilities?:  Array<Availability > | null,
  disabled?: boolean | null,
  wineList?: boolean | null,
  onlyAssociated?: boolean | null,
  categorySortOrder?: Array< string | null > | null,
  categories?: ModelCategoryConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Availability = {
  __typename: "Availability",
  dayOfWeek?: number | null,
  startTime?: string | null,
  endTime?: string | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  businessId: string,
  menuCategoriesId: string,
  locationId: string,
  menu: Menu,
  name: string,
  nameTranslations?:  Array<Translation | null > | null,
  description?: string | null,
  descriptionTranslations?:  Array<Translation | null > | null,
  dense?: boolean | null,
  image?: Array< string | null > | null,
  posCategoryType?: Array< string | null > | null,
  posCategoryId?: string | null,
  posLocationId?: string | null,
  availabilities?:  Array<Availability > | null,
  level?: number | null,
  sortedChannelProductIds?: Array< string | null > | null,
  subProductSortOrder?: Array< string | null > | null,
  disabled?: boolean | null,
  wineList?: boolean | null,
  subCategories?: ModelCategoryConnection | null,
  products?: ModelCategoriesProductsConnection | null,
  createdAt: string,
  updatedAt: string,
  categorySubCategoriesId?: string | null,
};

export type ModelCategoriesProductsConnection = {
  __typename: "ModelCategoriesProductsConnection",
  items:  Array<CategoriesProducts | null >,
  nextToken?: string | null,
};

export type CategoriesProducts = {
  __typename: "CategoriesProducts",
  id: string,
  locationId: string,
  productID: string,
  categoryID: string,
  product?: Product | null,
  category?: Category | null,
  createdAt: string,
  updatedAt: string,
};

export type Product = {
  __typename: "Product",
  id: string,
  businessId: string,
  categories?: ModelCategoriesProductsConnection | null,
  locationId: string,
  name: string,
  nameTranslations?:  Array<Translation | null > | null,
  description?: string | null,
  descriptionTranslations?:  Array<Translation | null > | null,
  dense?: boolean | null,
  image?: Array< string | null > | null,
  capacityUsages?: Array< string | null > | null,
  deliveryTax?: number | null,
  eatInTax?: number | null,
  takeawayTax?: number | null,
  max?: number | null,
  min?: number | null,
  isVariant?: boolean | null,
  multiMax?: number | null,
  multiply?: number | null,
  plu: string,
  disabled?: boolean | null,
  snoozeStart?: string | null,
  snoozeEnd?: string | null,
  price: number,
  posCategoryIds?: Array< string | null > | null,
  posProductCategoryId?: string | null,
  posProductId?: string | null,
  productTags?: Array< string | null > | null,
  productType: number,
  isWine?: boolean | null,
  wineInfo?: WineInfo | null,
  priceType?: PriceType | null,
  subProductSortOrder?: Array< string | null > | null,
  subProducts?: ModelProductsSubProductsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type WineInfo = {
  __typename: "WineInfo",
  caption?: string | null,
  year?: number | null,
  perfectFor?: Array< number | null > | null,
  typology?: number | null,
  country?: number | null,
  region?: number | null,
  grape?:  Array<Grape | null > | null,
  alcoholPercentage?: number | null,
  servingTemperature?: number | null,
  color?: string | null,
  perfume?: string | null,
  taste?: string | null,
  producer?: string | null,
  productionMethod?: number | null,
};

export type Grape = {
  __typename: "Grape",
  grapePercentage?: number | null,
  grapeName?: number | null,
};

export enum PriceType {
  addition = "addition",
  subtraction = "subtraction",
  fixed = "fixed",
}


export type ModelProductsSubProductsConnection = {
  __typename: "ModelProductsSubProductsConnection",
  items:  Array<ProductsSubProducts | null >,
  nextToken?: string | null,
};

export type ProductsSubProducts = {
  __typename: "ProductsSubProducts",
  id: string,
  locationId: string,
  productID: string,
  subProductsID: string,
  product?: Product | null,
  subProducts?: SubProducts | null,
  createdAt: string,
  updatedAt: string,
};

export type SubProducts = {
  __typename: "SubProducts",
  id: string,
  businessId: string,
  locationId: string,
  productId: string,
  product: Product,
  products?: ModelProductsSubProductsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelFloorConnection = {
  __typename: "ModelFloorConnection",
  items:  Array<Floor | null >,
  nextToken?: string | null,
};

export type Floor = {
  __typename: "Floor",
  id: string,
  businessId: string,
  locationFloorsId: string,
  name: string,
  tables?: ModelTableConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelTableConnection = {
  __typename: "ModelTableConnection",
  items:  Array<Table | null >,
  nextToken?: string | null,
};

export type Table = {
  __typename: "Table",
  id: string,
  businessId: string,
  locationId: string,
  name: string,
  seats: number,
  activeOrder?: Order | null,
  createdAt: string,
  updatedAt: string,
  floorTablesId?: string | null,
  tableActiveOrderId?: string | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  businessId: string,
  locationId: string,
  table?: Table | null,
  destination?: Address | null,
  note?: string | null,
  billingInfo?: BillingInfo | null,
  type?: OrderType | null,
  orderItems?: ModelOrderItemConnection | null,
  orderItemsSortOrder?: Array< string | null > | null,
  subTotal: number,
  total: number,
  paid: number,
  deliverectParams?: OrderDeliverectParams | null,
  ristoQuickStatus?: RistoQuickStatus | null,
  status: OrderStatus,
  lastPaymentsRistoQuick?: ModelPaymentConnection | null,
  payments?: ModelPaymentConnection | null,
  location?: Location | null,
  business?: Business | null,
  userId?: string | null,
  user?: User | null,
  generalVatRate?: number | null,
  deliveryCost?: number | null,
  phoneNumber?: string | null,
  createdAt: string,
  updatedAt: string,
  orderTableId?: string | null,
};

export type BillingInfo = {
  __typename: "BillingInfo",
  email?: string | null,
  phoneNumber?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  vatNumber?: string | null,
  sdi?: string | null,
  address?: Address | null,
};

export enum OrderType {
  selfOrdering = "selfOrdering",
  takeaway = "takeaway",
  delivery = "delivery",
}


export type ModelOrderItemConnection = {
  __typename: "ModelOrderItemConnection",
  items:  Array<OrderItem | null >,
  nextToken?: string | null,
};

export type OrderItem = {
  __typename: "OrderItem",
  id: string,
  plu: string,
  name: string,
  quantity: number,
  price: number,
  total: number,
  type: number,
  payment?: boolean | null,
  product?: Product | null,
  subItems?: ModelOrderItemConnection | null,
  createdAt: string,
  updatedAt: string,
  orderItemSubItemsId?: string | null,
  orderOrderItemsId?: string | null,
  orderItemProductId?: string | null,
};

export type OrderDeliverectParams = {
  __typename: "OrderDeliverectParams",
  id?: string | null,
  type: number,
  isAlreadyPaid: boolean,
  paymentType: number,
  paymentAmount: number,
  decimalDigits: number,
  status: number,
};

export enum RistoQuickStatus {
  active = "active",
  toSend = "toSend",
  sent = "sent",
  complete = "complete",
}


export enum OrderStatus {
  pending = "pending",
  payment_initiated = "payment_initiated",
  payment_completed = "payment_completed",
  fulfilled = "fulfilled",
}


export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection",
  items:  Array<Payment | null >,
  nextToken?: string | null,
};

export type Payment = {
  __typename: "Payment",
  id: string,
  orderID: string,
  orderIDRistoQuick?: string | null,
  status: PaymentStatus,
  userID?: string | null,
  useCoin: boolean,
  userEmail: string,
  customerName?: string | null,
  paymentID: string,
  paymentAmount: number,
  invoiceID?: string | null,
  order?: Order | null,
  createdAt: string,
  updatedAt: string,
};

export enum PaymentStatus {
  initiated = "initiated",
  completed = "completed",
  error = "error",
}


export type User = {
  __typename: "User",
  id: string,
  email: string,
  type: UserType,
  coin?: number | null,
  totalCoin?: number | null,
  stripeCustomerId?: string | null,
  association?: ModelAssociatedConnection | null,
  orders?: ModelOrderConnection | null,
  firstName?: string | null,
  businessName?: string | null,
  taxCode?: string | null,
  sdi?: string | null,
  lastName?: string | null,
  fiscalCode?: string | null,
  address?: Address | null,
  gender?: GenderType | null,
  dob?: DoB | null,
  phoneNumber?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum UserType {
  business = "business",
  simple = "simple",
}


export type ModelAssociatedConnection = {
  __typename: "ModelAssociatedConnection",
  items:  Array<Associated | null >,
  nextToken?: string | null,
};

export type Associated = {
  __typename: "Associated",
  id: string,
  userId?: string | null,
  corporateID: string,
  userEmail: string,
  user?: User | null,
  corporate?: Corporate | null,
  presences?: ModelAssociatedPresenceConnection | null,
  status: AssociatedStatus,
  createdAt: string,
  updatedAt: string,
};

export type Corporate = {
  __typename: "Corporate",
  id: string,
  businessCorporateId: string,
  name?: string | null,
  associates?: ModelAssociatedConnection | null,
  associatedLocations?: ModelCorporateLocationsConnection | null,
  business?: Business | null,
  presences?: ModelAssociatedPresenceConnection | null,
  stripeInfo?: StripeInfo | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelCorporateLocationsConnection = {
  __typename: "ModelCorporateLocationsConnection",
  items:  Array<CorporateLocations | null >,
  nextToken?: string | null,
};

export type CorporateLocations = {
  __typename: "CorporateLocations",
  id: string,
  locationID: string,
  corporateID: string,
  location?: Location | null,
  corporate?: Corporate | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelAssociatedPresenceConnection = {
  __typename: "ModelAssociatedPresenceConnection",
  items:  Array<AssociatedPresence | null >,
  nextToken?: string | null,
};

export type AssociatedPresence = {
  __typename: "AssociatedPresence",
  id: string,
  associatedID: string,
  locationID: string,
  corporateID: string,
  location?: Location | null,
  corporate?: Corporate | null,
  associated?: Associated | null,
  createdAt: string,
  updatedAt: string,
};

export enum AssociatedStatus {
  invited = "invited",
  active = "active",
  inactive = "inactive",
  deleted = "deleted",
}


export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export enum GenderType {
  male = "male",
  female = "female",
  notSpecified = "notSpecified",
}


export type DoB = {
  __typename: "DoB",
  day?: number | null,
  month?: number | null,
  year?: number | null,
};

export type ExternaLink = {
  __typename: "ExternaLink",
  link: string,
  label: string,
  icon: string,
};

export enum Template {
  dark = "dark",
  light = "light",
}


export type SocialLink = {
  __typename: "SocialLink",
  link: string,
  label: string,
  icon: string,
};

export type BankDetails = {
  __typename: "BankDetails",
  accountHolder: string,
  iban: string,
  bic: string,
};

export type ModelCorporateConnection = {
  __typename: "ModelCorporateConnection",
  items:  Array<Corporate | null >,
  nextToken?: string | null,
};

export type UpdateBusinessInput = {
  id: string,
  name?: string | null,
};

export type DeleteBusinessInput = {
  id: string,
};

export type DeleteUserInput = {
  id: string,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  type?: ModelUserTypeInput | null,
  coin?: ModelIntInput | null,
  totalCoin?: ModelIntInput | null,
  stripeCustomerId?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  taxCode?: ModelStringInput | null,
  sdi?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  fiscalCode?: ModelStringInput | null,
  gender?: ModelGenderTypeInput | null,
  phoneNumber?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelStringInput | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelGenderTypeInput = {
  eq?: GenderType | null,
  ne?: GenderType | null,
};

export type DeleteAssociatedInput = {
  id: string,
};

export type ModelAssociatedConditionInput = {
  userId?: ModelIDInput | null,
  corporateID?: ModelIDInput | null,
  userEmail?: ModelStringInput | null,
  status?: ModelAssociatedStatusInput | null,
  and?: Array< ModelAssociatedConditionInput | null > | null,
  or?: Array< ModelAssociatedConditionInput | null > | null,
  not?: ModelAssociatedConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelAssociatedStatusInput = {
  eq?: AssociatedStatus | null,
  ne?: AssociatedStatus | null,
};

export type CreateCorporateLocationsInput = {
  id?: string | null,
  locationID: string,
  corporateID: string,
};

export type ModelCorporateLocationsConditionInput = {
  locationID?: ModelIDInput | null,
  corporateID?: ModelIDInput | null,
  and?: Array< ModelCorporateLocationsConditionInput | null > | null,
  or?: Array< ModelCorporateLocationsConditionInput | null > | null,
  not?: ModelCorporateLocationsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateCorporateLocationsInput = {
  id: string,
  locationID?: string | null,
  corporateID?: string | null,
};

export type DeleteCorporateLocationsInput = {
  id: string,
};

export type CreateAssociatedPresenceInput = {
  id?: string | null,
  associatedID: string,
  locationID: string,
  corporateID: string,
  createdAt?: string | null,
};

export type ModelAssociatedPresenceConditionInput = {
  associatedID?: ModelIDInput | null,
  locationID?: ModelIDInput | null,
  corporateID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAssociatedPresenceConditionInput | null > | null,
  or?: Array< ModelAssociatedPresenceConditionInput | null > | null,
  not?: ModelAssociatedPresenceConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateAssociatedPresenceInput = {
  id: string,
  associatedID?: string | null,
  locationID?: string | null,
  corporateID?: string | null,
  createdAt?: string | null,
};

export type DeleteAssociatedPresenceInput = {
  id: string,
};

export type CreateOrderItemInput = {
  id?: string | null,
  plu: string,
  name: string,
  quantity: number,
  price: number,
  total: number,
  type: number,
  payment?: boolean | null,
  orderItemSubItemsId?: string | null,
  orderOrderItemsId?: string | null,
  orderItemProductId?: string | null,
};

export type ModelOrderItemConditionInput = {
  plu?: ModelStringInput | null,
  name?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  price?: ModelIntInput | null,
  total?: ModelIntInput | null,
  type?: ModelIntInput | null,
  payment?: ModelBooleanInput | null,
  and?: Array< ModelOrderItemConditionInput | null > | null,
  or?: Array< ModelOrderItemConditionInput | null > | null,
  not?: ModelOrderItemConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  orderItemSubItemsId?: ModelIDInput | null,
  orderOrderItemsId?: ModelIDInput | null,
  orderItemProductId?: ModelIDInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateOrderItemInput = {
  id: string,
  plu?: string | null,
  name?: string | null,
  quantity?: number | null,
  price?: number | null,
  total?: number | null,
  type?: number | null,
  payment?: boolean | null,
  orderItemSubItemsId?: string | null,
  orderOrderItemsId?: string | null,
  orderItemProductId?: string | null,
};

export type DeleteOrderItemInput = {
  id: string,
};

export type CreatePaymentInput = {
  id?: string | null,
  orderID: string,
  orderIDRistoQuick?: string | null,
  status: PaymentStatus,
  userID?: string | null,
  useCoin: boolean,
  userEmail: string,
  customerName?: string | null,
  paymentID: string,
  paymentAmount: number,
  invoiceID?: string | null,
};

export type ModelPaymentConditionInput = {
  orderID?: ModelIDInput | null,
  orderIDRistoQuick?: ModelIDInput | null,
  status?: ModelPaymentStatusInput | null,
  userID?: ModelStringInput | null,
  useCoin?: ModelBooleanInput | null,
  userEmail?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  paymentID?: ModelStringInput | null,
  paymentAmount?: ModelIntInput | null,
  invoiceID?: ModelStringInput | null,
  and?: Array< ModelPaymentConditionInput | null > | null,
  or?: Array< ModelPaymentConditionInput | null > | null,
  not?: ModelPaymentConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null,
  ne?: PaymentStatus | null,
};

export type UpdatePaymentInput = {
  id: string,
  orderID?: string | null,
  orderIDRistoQuick?: string | null,
  status?: PaymentStatus | null,
  userID?: string | null,
  useCoin?: boolean | null,
  userEmail?: string | null,
  customerName?: string | null,
  paymentID?: string | null,
  paymentAmount?: number | null,
  invoiceID?: string | null,
};

export type DeletePaymentInput = {
  id: string,
};

export type CreateIntegrationGeneralInput = {
  id?: string | null,
  latestVersion: string,
  latestLink: string,
};

export type ModelIntegrationGeneralConditionInput = {
  latestVersion?: ModelStringInput | null,
  latestLink?: ModelStringInput | null,
  and?: Array< ModelIntegrationGeneralConditionInput | null > | null,
  or?: Array< ModelIntegrationGeneralConditionInput | null > | null,
  not?: ModelIntegrationGeneralConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type IntegrationGeneral = {
  __typename: "IntegrationGeneral",
  id: string,
  latestVersion: string,
  latestLink: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateIntegrationGeneralInput = {
  id: string,
  latestVersion?: string | null,
  latestLink?: string | null,
};

export type DeleteIntegrationGeneralInput = {
  id: string,
};

export type CreateIntegrationInput = {
  id?: string | null,
  run: boolean,
  integrationGeneralId: string,
};

export type ModelIntegrationConditionInput = {
  run?: ModelBooleanInput | null,
  integrationGeneralId?: ModelIDInput | null,
  and?: Array< ModelIntegrationConditionInput | null > | null,
  or?: Array< ModelIntegrationConditionInput | null > | null,
  not?: ModelIntegrationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Integration = {
  __typename: "Integration",
  id: string,
  run: boolean,
  integrationGeneralId: string,
  integrationGeneral?: IntegrationGeneral | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateIntegrationInput = {
  id: string,
  run?: boolean | null,
  integrationGeneralId?: string | null,
};

export type DeleteIntegrationInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  email: string,
  type: UserType,
  coin?: number | null,
  totalCoin?: number | null,
  stripeCustomerId?: string | null,
  firstName?: string | null,
  businessName?: string | null,
  taxCode?: string | null,
  sdi?: string | null,
  lastName?: string | null,
  fiscalCode?: string | null,
  address?: AddressInput | null,
  gender?: GenderType | null,
  dob?: DoBInput | null,
  phoneNumber?: string | null,
};

export type DoBInput = {
  day?: number | null,
  month?: number | null,
  year?: number | null,
};

export type UpdateUserInput = {
  id: string,
  email?: string | null,
  type?: UserType | null,
  coin?: number | null,
  totalCoin?: number | null,
  stripeCustomerId?: string | null,
  firstName?: string | null,
  businessName?: string | null,
  taxCode?: string | null,
  sdi?: string | null,
  lastName?: string | null,
  fiscalCode?: string | null,
  address?: AddressInput | null,
  gender?: GenderType | null,
  dob?: DoBInput | null,
  phoneNumber?: string | null,
};

export type CreateAssociatedInput = {
  id?: string | null,
  userId?: string | null,
  corporateID: string,
  userEmail: string,
  status: AssociatedStatus,
};

export type UpdateAssociatedInput = {
  id: string,
  userId?: string | null,
  corporateID?: string | null,
  userEmail?: string | null,
  status?: AssociatedStatus | null,
};

export type CreateCorporateInput = {
  id?: string | null,
  businessCorporateId: string,
  name?: string | null,
  stripeInfo?: StripeInfoInput | null,
};

export type StripeInfoInput = {
  stripeCostumer?: string | null,
  stripeAccount?: string | null,
  stripeSubscriptionType?: StripeSubscriptionType | null,
  stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
  stripeDefaultPaymentMethod?: boolean | null,
};

export type ModelCorporateConditionInput = {
  businessCorporateId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelCorporateConditionInput | null > | null,
  or?: Array< ModelCorporateConditionInput | null > | null,
  not?: ModelCorporateConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateCorporateInput = {
  id: string,
  businessCorporateId?: string | null,
  name?: string | null,
  stripeInfo?: StripeInfoInput | null,
};

export type DeleteCorporateInput = {
  id: string,
};

export type CreateLocationInput = {
  id?: string | null,
  name?: string | null,
  images?: Array< string | null > | null,
  logo?: string | null,
  bio?: string | null,
  bioTranslations?: Array< TranslationInput | null > | null,
  description?: string | null,
  descriptionTranslations?: Array< TranslationInput | null > | null,
  gallery?: Array< ImageGalleryInput | null > | null,
  stripeInfo?: StripeInfoInput | null,
  invoiceInfo?: InvoiceInfoInput | null,
  openingDay?: OpeningDayInput | null,
  deliverectId: string,
  status: LocationStatus,
  timeZone: string,
  menuSortOrder?: Array< string | null > | null,
  credit: number,
  selfOrdering: boolean,
  externalLinks?: Array< ExternaLinkInput > | null,
  template: Template,
  color: string,
  showCurrency: boolean,
  showWineList?: boolean | null,
  address?: AddressInput | null,
  phoneNumber?: string | null,
  social?: Array< SocialLinkInput | null > | null,
  email?: string | null,
  languageDefault: string,
  languagesEnabled?: Array< string | null > | null,
  translationsInProgress?: boolean | null,
  coverPrice?: number | null,
  deliveryCost?: number | null,
  generalVatRate?: number | null,
  businessLocationsId: string,
  bankDetails?: BankDetailsInput | null,
};

export type TranslationInput = {
  language: string,
  body: string,
};

export type ImageGalleryInput = {
  image: string,
  name: string,
  nameTranslations?: Array< TranslationInput | null > | null,
};

export type InvoiceInfoInput = {
  taxCode: string,
  broadcastFormat: string,
  businessName: string,
  taxRegime: string,
  registeredOffice: AddressInput,
};

export type OpeningDayInput = {
  monday?: OpeningHoursInput | null,
  tuesday?: OpeningHoursInput | null,
  wednesday?: OpeningHoursInput | null,
  thursday?: OpeningHoursInput | null,
  friday?: OpeningHoursInput | null,
  saturday?: OpeningHoursInput | null,
  sunday?: OpeningHoursInput | null,
};

export type OpeningHoursInput = {
  slots: Array< SlotInput >,
};

export type SlotInput = {
  start: string,
  end: string,
};

export type ExternaLinkInput = {
  link: string,
  label: string,
  icon: string,
};

export type SocialLinkInput = {
  link: string,
  label: string,
  icon: string,
};

export type BankDetailsInput = {
  accountHolder: string,
  iban: string,
  bic: string,
};

export type ModelLocationConditionInput = {
  name?: ModelStringInput | null,
  images?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deliverectId?: ModelStringInput | null,
  status?: ModelLocationStatusInput | null,
  timeZone?: ModelStringInput | null,
  menuSortOrder?: ModelStringInput | null,
  credit?: ModelIntInput | null,
  selfOrdering?: ModelBooleanInput | null,
  template?: ModelTemplateInput | null,
  color?: ModelStringInput | null,
  showCurrency?: ModelBooleanInput | null,
  showWineList?: ModelBooleanInput | null,
  phoneNumber?: ModelStringInput | null,
  email?: ModelStringInput | null,
  languageDefault?: ModelStringInput | null,
  languagesEnabled?: ModelStringInput | null,
  translationsInProgress?: ModelBooleanInput | null,
  coverPrice?: ModelIntInput | null,
  deliveryCost?: ModelIntInput | null,
  generalVatRate?: ModelIntInput | null,
  businessLocationsId?: ModelIDInput | null,
  and?: Array< ModelLocationConditionInput | null > | null,
  or?: Array< ModelLocationConditionInput | null > | null,
  not?: ModelLocationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelLocationStatusInput = {
  eq?: LocationStatus | null,
  ne?: LocationStatus | null,
};

export type ModelTemplateInput = {
  eq?: Template | null,
  ne?: Template | null,
};

export type UpdateLocationInput = {
  id: string,
  name?: string | null,
  images?: Array< string | null > | null,
  logo?: string | null,
  bio?: string | null,
  bioTranslations?: Array< TranslationInput | null > | null,
  description?: string | null,
  descriptionTranslations?: Array< TranslationInput | null > | null,
  gallery?: Array< ImageGalleryInput | null > | null,
  stripeInfo?: StripeInfoInput | null,
  invoiceInfo?: InvoiceInfoInput | null,
  openingDay?: OpeningDayInput | null,
  deliverectId?: string | null,
  status?: LocationStatus | null,
  timeZone?: string | null,
  menuSortOrder?: Array< string | null > | null,
  credit?: number | null,
  selfOrdering?: boolean | null,
  externalLinks?: Array< ExternaLinkInput > | null,
  template?: Template | null,
  color?: string | null,
  showCurrency?: boolean | null,
  showWineList?: boolean | null,
  address?: AddressInput | null,
  phoneNumber?: string | null,
  social?: Array< SocialLinkInput | null > | null,
  email?: string | null,
  languageDefault?: string | null,
  languagesEnabled?: Array< string | null > | null,
  translationsInProgress?: boolean | null,
  coverPrice?: number | null,
  deliveryCost?: number | null,
  generalVatRate?: number | null,
  businessLocationsId?: string | null,
  bankDetails?: BankDetailsInput | null,
};

export type DeleteLocationInput = {
  id: string,
};

export type CreatePostInput = {
  id?: string | null,
  image?: string | null,
  name: string,
  nameTranslations?: Array< TranslationInput | null > | null,
  userId: string,
  userName: string,
  postLocationId: string,
};

export type ModelPostConditionInput = {
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  postLocationId?: ModelIDInput | null,
  and?: Array< ModelPostConditionInput | null > | null,
  or?: Array< ModelPostConditionInput | null > | null,
  not?: ModelPostConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdatePostInput = {
  id: string,
  image?: string | null,
  name?: string | null,
  nameTranslations?: Array< TranslationInput | null > | null,
  userId?: string | null,
  userName?: string | null,
  postLocationId?: string | null,
};

export type DeletePostInput = {
  id: string,
};

export type CreateMenuInput = {
  id?: string | null,
  businessId: string,
  menuLocationId: string,
  name: string,
  nameTranslations?: Array< TranslationInput | null > | null,
  description?: string | null,
  descriptionTranslations?: Array< TranslationInput | null > | null,
  dense?: boolean | null,
  type?: number | null,
  nestedModifiers: boolean,
  image?: Array< string | null > | null,
  currency?: number | null,
  availabilities?: Array< AvailabilityInput > | null,
  disabled?: boolean | null,
  wineList?: boolean | null,
  onlyAssociated?: boolean | null,
  categorySortOrder?: Array< string | null > | null,
};

export type AvailabilityInput = {
  dayOfWeek?: number | null,
  startTime?: string | null,
  endTime?: string | null,
};

export type ModelMenuConditionInput = {
  businessId?: ModelIDInput | null,
  menuLocationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  dense?: ModelBooleanInput | null,
  type?: ModelIntInput | null,
  nestedModifiers?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  currency?: ModelIntInput | null,
  disabled?: ModelBooleanInput | null,
  wineList?: ModelBooleanInput | null,
  onlyAssociated?: ModelBooleanInput | null,
  categorySortOrder?: ModelStringInput | null,
  and?: Array< ModelMenuConditionInput | null > | null,
  or?: Array< ModelMenuConditionInput | null > | null,
  not?: ModelMenuConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateMenuInput = {
  id: string,
  businessId?: string | null,
  menuLocationId?: string | null,
  name?: string | null,
  nameTranslations?: Array< TranslationInput | null > | null,
  description?: string | null,
  descriptionTranslations?: Array< TranslationInput | null > | null,
  dense?: boolean | null,
  type?: number | null,
  nestedModifiers?: boolean | null,
  image?: Array< string | null > | null,
  currency?: number | null,
  availabilities?: Array< AvailabilityInput > | null,
  disabled?: boolean | null,
  wineList?: boolean | null,
  onlyAssociated?: boolean | null,
  categorySortOrder?: Array< string | null > | null,
};

export type DeleteMenuInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  businessId: string,
  menuCategoriesId: string,
  locationId: string,
  name: string,
  nameTranslations?: Array< TranslationInput | null > | null,
  description?: string | null,
  descriptionTranslations?: Array< TranslationInput | null > | null,
  dense?: boolean | null,
  image?: Array< string | null > | null,
  posCategoryType?: Array< string | null > | null,
  posCategoryId?: string | null,
  posLocationId?: string | null,
  availabilities?: Array< AvailabilityInput > | null,
  level?: number | null,
  sortedChannelProductIds?: Array< string | null > | null,
  subProductSortOrder?: Array< string | null > | null,
  disabled?: boolean | null,
  wineList?: boolean | null,
  categorySubCategoriesId?: string | null,
};

export type ModelCategoryConditionInput = {
  businessId?: ModelIDInput | null,
  menuCategoriesId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  dense?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  posCategoryType?: ModelStringInput | null,
  posCategoryId?: ModelStringInput | null,
  posLocationId?: ModelStringInput | null,
  level?: ModelIntInput | null,
  sortedChannelProductIds?: ModelStringInput | null,
  subProductSortOrder?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  wineList?: ModelBooleanInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  categorySubCategoriesId?: ModelIDInput | null,
};

export type UpdateCategoryInput = {
  id: string,
  businessId?: string | null,
  menuCategoriesId?: string | null,
  locationId?: string | null,
  name?: string | null,
  nameTranslations?: Array< TranslationInput | null > | null,
  description?: string | null,
  descriptionTranslations?: Array< TranslationInput | null > | null,
  dense?: boolean | null,
  image?: Array< string | null > | null,
  posCategoryType?: Array< string | null > | null,
  posCategoryId?: string | null,
  posLocationId?: string | null,
  availabilities?: Array< AvailabilityInput > | null,
  level?: number | null,
  sortedChannelProductIds?: Array< string | null > | null,
  subProductSortOrder?: Array< string | null > | null,
  disabled?: boolean | null,
  wineList?: boolean | null,
  categorySubCategoriesId?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreateProductInput = {
  id?: string | null,
  businessId: string,
  locationId: string,
  name: string,
  nameTranslations?: Array< TranslationInput | null > | null,
  description?: string | null,
  descriptionTranslations?: Array< TranslationInput | null > | null,
  dense?: boolean | null,
  image?: Array< string | null > | null,
  capacityUsages?: Array< string | null > | null,
  deliveryTax?: number | null,
  eatInTax?: number | null,
  takeawayTax?: number | null,
  max?: number | null,
  min?: number | null,
  isVariant?: boolean | null,
  multiMax?: number | null,
  multiply?: number | null,
  plu: string,
  disabled?: boolean | null,
  snoozeStart?: string | null,
  snoozeEnd?: string | null,
  price: number,
  posCategoryIds?: Array< string | null > | null,
  posProductCategoryId?: string | null,
  posProductId?: string | null,
  productTags?: Array< string | null > | null,
  productType: number,
  isWine?: boolean | null,
  wineInfo?: WineInfoInput | null,
  priceType?: PriceType | null,
  subProductSortOrder?: Array< string | null > | null,
};

export type WineInfoInput = {
  caption?: string | null,
  year?: number | null,
  perfectFor?: Array< number | null > | null,
  typology?: number | null,
  country?: number | null,
  region?: number | null,
  grape?: Array< GrapeInput | null > | null,
  alcoholPercentage?: number | null,
  servingTemperature?: number | null,
  color?: string | null,
  perfume?: string | null,
  taste?: string | null,
  producer?: string | null,
  productionMethod?: number | null,
};

export type GrapeInput = {
  grapePercentage?: number | null,
  grapeName?: number | null,
};

export type ModelProductConditionInput = {
  businessId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  dense?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  capacityUsages?: ModelStringInput | null,
  deliveryTax?: ModelIntInput | null,
  eatInTax?: ModelIntInput | null,
  takeawayTax?: ModelIntInput | null,
  max?: ModelIntInput | null,
  min?: ModelIntInput | null,
  isVariant?: ModelBooleanInput | null,
  multiMax?: ModelIntInput | null,
  multiply?: ModelIntInput | null,
  plu?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  snoozeStart?: ModelStringInput | null,
  snoozeEnd?: ModelStringInput | null,
  price?: ModelIntInput | null,
  posCategoryIds?: ModelStringInput | null,
  posProductCategoryId?: ModelStringInput | null,
  posProductId?: ModelStringInput | null,
  productTags?: ModelStringInput | null,
  productType?: ModelIntInput | null,
  isWine?: ModelBooleanInput | null,
  priceType?: ModelPriceTypeInput | null,
  subProductSortOrder?: ModelStringInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelPriceTypeInput = {
  eq?: PriceType | null,
  ne?: PriceType | null,
};

export type UpdateProductInput = {
  id: string,
  businessId?: string | null,
  locationId?: string | null,
  name?: string | null,
  nameTranslations?: Array< TranslationInput | null > | null,
  description?: string | null,
  descriptionTranslations?: Array< TranslationInput | null > | null,
  dense?: boolean | null,
  image?: Array< string | null > | null,
  capacityUsages?: Array< string | null > | null,
  deliveryTax?: number | null,
  eatInTax?: number | null,
  takeawayTax?: number | null,
  max?: number | null,
  min?: number | null,
  isVariant?: boolean | null,
  multiMax?: number | null,
  multiply?: number | null,
  plu?: string | null,
  disabled?: boolean | null,
  snoozeStart?: string | null,
  snoozeEnd?: string | null,
  price?: number | null,
  posCategoryIds?: Array< string | null > | null,
  posProductCategoryId?: string | null,
  posProductId?: string | null,
  productTags?: Array< string | null > | null,
  productType?: number | null,
  isWine?: boolean | null,
  wineInfo?: WineInfoInput | null,
  priceType?: PriceType | null,
  subProductSortOrder?: Array< string | null > | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreateCategoriesProductsInput = {
  id?: string | null,
  locationId: string,
  productID: string,
  categoryID: string,
};

export type ModelCategoriesProductsConditionInput = {
  locationId?: ModelIDInput | null,
  productID?: ModelIDInput | null,
  categoryID?: ModelIDInput | null,
  and?: Array< ModelCategoriesProductsConditionInput | null > | null,
  or?: Array< ModelCategoriesProductsConditionInput | null > | null,
  not?: ModelCategoriesProductsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateCategoriesProductsInput = {
  id: string,
  locationId?: string | null,
  productID?: string | null,
  categoryID?: string | null,
};

export type DeleteCategoriesProductsInput = {
  id: string,
};

export type CreateSubProductsInput = {
  id?: string | null,
  businessId: string,
  locationId: string,
  productId: string,
};

export type ModelSubProductsConditionInput = {
  businessId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  and?: Array< ModelSubProductsConditionInput | null > | null,
  or?: Array< ModelSubProductsConditionInput | null > | null,
  not?: ModelSubProductsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateSubProductsInput = {
  id: string,
  businessId?: string | null,
  locationId?: string | null,
  productId?: string | null,
};

export type DeleteSubProductsInput = {
  id: string,
};

export type CreateProductsSubProductsInput = {
  id?: string | null,
  locationId: string,
  productID: string,
  subProductsID: string,
};

export type ModelProductsSubProductsConditionInput = {
  locationId?: ModelIDInput | null,
  productID?: ModelIDInput | null,
  subProductsID?: ModelIDInput | null,
  and?: Array< ModelProductsSubProductsConditionInput | null > | null,
  or?: Array< ModelProductsSubProductsConditionInput | null > | null,
  not?: ModelProductsSubProductsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateProductsSubProductsInput = {
  id: string,
  locationId?: string | null,
  productID?: string | null,
  subProductsID?: string | null,
};

export type DeleteProductsSubProductsInput = {
  id: string,
};

export type CreateFloorInput = {
  id?: string | null,
  businessId: string,
  locationFloorsId: string,
  name: string,
};

export type ModelFloorConditionInput = {
  businessId?: ModelIDInput | null,
  locationFloorsId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelFloorConditionInput | null > | null,
  or?: Array< ModelFloorConditionInput | null > | null,
  not?: ModelFloorConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateFloorInput = {
  id: string,
  businessId?: string | null,
  locationFloorsId?: string | null,
  name?: string | null,
};

export type DeleteFloorInput = {
  id: string,
};

export type CreateTableInput = {
  id?: string | null,
  businessId: string,
  locationId: string,
  name: string,
  seats: number,
  floorTablesId?: string | null,
  tableActiveOrderId?: string | null,
};

export type ModelTableConditionInput = {
  businessId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  seats?: ModelIntInput | null,
  and?: Array< ModelTableConditionInput | null > | null,
  or?: Array< ModelTableConditionInput | null > | null,
  not?: ModelTableConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  floorTablesId?: ModelIDInput | null,
  tableActiveOrderId?: ModelIDInput | null,
};

export type UpdateTableInput = {
  id: string,
  businessId?: string | null,
  locationId?: string | null,
  name?: string | null,
  seats?: number | null,
  floorTablesId?: string | null,
  tableActiveOrderId?: string | null,
};

export type DeleteTableInput = {
  id: string,
};

export type CreateOrderInput = {
  id?: string | null,
  businessId: string,
  locationId: string,
  destination?: AddressInput | null,
  note?: string | null,
  billingInfo?: BillingInfoInput | null,
  type?: OrderType | null,
  orderItemsSortOrder?: Array< string | null > | null,
  subTotal: number,
  total: number,
  paid: number,
  deliverectParams?: OrderDeliverectParamsInput | null,
  ristoQuickStatus?: RistoQuickStatus | null,
  status: OrderStatus,
  userId?: string | null,
  generalVatRate?: number | null,
  deliveryCost?: number | null,
  phoneNumber?: string | null,
  orderTableId?: string | null,
};

export type BillingInfoInput = {
  email: string,
  phoneNumber?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  vatNumber?: string | null,
  sdi?: string | null,
  address?: AddressInput | null,
};

export type OrderDeliverectParamsInput = {
  id?: string | null,
  type: number,
  isAlreadyPaid: boolean,
  paymentType: number,
  paymentAmount: number,
  decimalDigits: number,
  status: number,
};

export type ModelOrderConditionInput = {
  businessId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  note?: ModelStringInput | null,
  type?: ModelOrderTypeInput | null,
  orderItemsSortOrder?: ModelStringInput | null,
  subTotal?: ModelIntInput | null,
  total?: ModelIntInput | null,
  paid?: ModelIntInput | null,
  ristoQuickStatus?: ModelRistoQuickStatusInput | null,
  status?: ModelOrderStatusInput | null,
  userId?: ModelIDInput | null,
  generalVatRate?: ModelIntInput | null,
  deliveryCost?: ModelIntInput | null,
  phoneNumber?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  orderTableId?: ModelIDInput | null,
};

export type ModelOrderTypeInput = {
  eq?: OrderType | null,
  ne?: OrderType | null,
};

export type ModelRistoQuickStatusInput = {
  eq?: RistoQuickStatus | null,
  ne?: RistoQuickStatus | null,
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null,
  ne?: OrderStatus | null,
};

export type UpdateOrderInput = {
  id: string,
  businessId?: string | null,
  locationId?: string | null,
  destination?: AddressInput | null,
  note?: string | null,
  billingInfo?: BillingInfoInput | null,
  type?: OrderType | null,
  orderItemsSortOrder?: Array< string | null > | null,
  subTotal?: number | null,
  total?: number | null,
  paid?: number | null,
  deliverectParams?: OrderDeliverectParamsInput | null,
  ristoQuickStatus?: RistoQuickStatus | null,
  status?: OrderStatus | null,
  userId?: string | null,
  generalVatRate?: number | null,
  deliveryCost?: number | null,
  phoneNumber?: string | null,
  orderTableId?: string | null,
};

export type DeleteOrderInput = {
  id: string,
};

export type PayOrderResponse = PayOrderResponseSuccess | ErrorResponse


export type PayOrderResponseSuccess = {
  __typename: "PayOrderResponseSuccess",
  orderID?: string | null,
  clientSecret?: string | null,
};

export type StripeAccountData = {
  locationId: string,
  account_token: string,
  business_profile: StripeAccountBusinessProfile,
  bank_account_token: string,
  person_token: string,
};

export type StripeAccountBusinessProfile = {
  mcc: string,
  url?: string | null,
  product_description?: string | null,
};

export type StripeUpdateAccountResponse = StripeUpdateAccountResponseSuccess | ErrorResponse


export type StripeUpdateAccountResponseSuccess = {
  __typename: "StripeUpdateAccountResponseSuccess",
  success?: boolean | null,
};

export enum Crud {
  create = "create",
  read = "read",
  update = "update",
  delete = "delete",
}


export type StripeHandlePaymentMethodResponse = StripeHandlePaymentMethodResponseSuccess | ErrorResponse


export type StripeHandlePaymentMethodResponseSuccess = {
  __typename: "StripeHandlePaymentMethodResponseSuccess",
  success?: boolean | null,
};

export type StripeHandleCancelSubscriptionResponse = StripeHandleCancelSubscriptionResponseSuccess | ErrorResponse


export type StripeHandleCancelSubscriptionResponseSuccess = {
  __typename: "StripeHandleCancelSubscriptionResponseSuccess",
  success?: boolean | null,
};

export type StripeChangePlanSubscriptionResponse = StripeChangePlanSubscriptionResponseSuccess | ErrorResponse


export type StripeChangePlanSubscriptionResponseSuccess = {
  __typename: "StripeChangePlanSubscriptionResponseSuccess",
  success?: boolean | null,
};

export type TranslateLocationResponse = TranslateLocationResponseSuccess | ErrorResponse


export type TranslateLocationResponseSuccess = {
  __typename: "TranslateLocationResponseSuccess",
  success: boolean,
};

export type StorePresenceKeyResponse = StorePresenceKeyResponseSuccess | ErrorResponse


export type StorePresenceKeyResponseSuccess = {
  __typename: "StorePresenceKeyResponseSuccess",
  userId: string,
  status: string,
};

export type CorporateInviteAssociatedsResponse = CorporateInviteAssociatedsResponseSuccess | ErrorResponse


export type CorporateInviteAssociatedsResponseSuccess = {
  __typename: "CorporateInviteAssociatedsResponseSuccess",
  success: boolean,
};

export type OrderItemInput = {
  id: string,
  quantity: number,
};

export type CreateOrderNotPaidResponse = CreateOrderNotPaidResponseSuccess | ErrorResponse


export type CreateOrderNotPaidResponseSuccess = {
  __typename: "CreateOrderNotPaidResponseSuccess",
  orderId: string,
  success: boolean,
};

export type GetCardsResponse = GetCardsResponseSuccess | ErrorResponse


export type GetCardsResponseSuccess = {
  __typename: "GetCardsResponseSuccess",
  userCards?:  Array<userCards | null > | null,
};

export type userCards = {
  __typename: "userCards",
  id: string,
  brand: string,
  last4: string,
  expMonth: string,
  expYear: string,
};

export type GetJWTResponse = GetJWTResponseSuccess | ErrorResponse


export type GetJWTResponseSuccess = {
  __typename: "GetJWTResponseSuccess",
  token?: string | null,
  code?: number | null,
};

export type ModelBusinessFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBusinessFilterInput | null > | null,
  or?: Array< ModelBusinessFilterInput | null > | null,
  not?: ModelBusinessFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelBusinessConnection = {
  __typename: "ModelBusinessConnection",
  items:  Array<Business | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  type?: ModelUserTypeInput | null,
  coin?: ModelIntInput | null,
  totalCoin?: ModelIntInput | null,
  stripeCustomerId?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  taxCode?: ModelStringInput | null,
  sdi?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  fiscalCode?: ModelStringInput | null,
  gender?: ModelGenderTypeInput | null,
  phoneNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelAssociatedFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  corporateID?: ModelIDInput | null,
  userEmail?: ModelStringInput | null,
  status?: ModelAssociatedStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAssociatedFilterInput | null > | null,
  or?: Array< ModelAssociatedFilterInput | null > | null,
  not?: ModelAssociatedFilterInput | null,
};

export type ModelCorporateFilterInput = {
  id?: ModelIDInput | null,
  businessCorporateId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCorporateFilterInput | null > | null,
  or?: Array< ModelCorporateFilterInput | null > | null,
  not?: ModelCorporateFilterInput | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  images?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deliverectId?: ModelStringInput | null,
  status?: ModelLocationStatusInput | null,
  timeZone?: ModelStringInput | null,
  menuSortOrder?: ModelStringInput | null,
  credit?: ModelIntInput | null,
  selfOrdering?: ModelBooleanInput | null,
  template?: ModelTemplateInput | null,
  color?: ModelStringInput | null,
  showCurrency?: ModelBooleanInput | null,
  showWineList?: ModelBooleanInput | null,
  phoneNumber?: ModelStringInput | null,
  email?: ModelStringInput | null,
  languageDefault?: ModelStringInput | null,
  languagesEnabled?: ModelStringInput | null,
  translationsInProgress?: ModelBooleanInput | null,
  coverPrice?: ModelIntInput | null,
  deliveryCost?: ModelIntInput | null,
  generalVatRate?: ModelIntInput | null,
  businessLocationsId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
};

export type ModelPostFilterInput = {
  id?: ModelIDInput | null,
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  postLocationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPostFilterInput | null > | null,
  or?: Array< ModelPostFilterInput | null > | null,
  not?: ModelPostFilterInput | null,
};

export type ModelCorporateLocationsFilterInput = {
  id?: ModelIDInput | null,
  locationID?: ModelIDInput | null,
  corporateID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCorporateLocationsFilterInput | null > | null,
  or?: Array< ModelCorporateLocationsFilterInput | null > | null,
  not?: ModelCorporateLocationsFilterInput | null,
};

export type ModelAssociatedPresenceFilterInput = {
  id?: ModelIDInput | null,
  associatedID?: ModelIDInput | null,
  locationID?: ModelIDInput | null,
  corporateID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAssociatedPresenceFilterInput | null > | null,
  or?: Array< ModelAssociatedPresenceFilterInput | null > | null,
  not?: ModelAssociatedPresenceFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelMenuFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  menuLocationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  dense?: ModelBooleanInput | null,
  type?: ModelIntInput | null,
  nestedModifiers?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  currency?: ModelIntInput | null,
  disabled?: ModelBooleanInput | null,
  wineList?: ModelBooleanInput | null,
  onlyAssociated?: ModelBooleanInput | null,
  categorySortOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMenuFilterInput | null > | null,
  or?: Array< ModelMenuFilterInput | null > | null,
  not?: ModelMenuFilterInput | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  menuCategoriesId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  dense?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  posCategoryType?: ModelStringInput | null,
  posCategoryId?: ModelStringInput | null,
  posLocationId?: ModelStringInput | null,
  level?: ModelIntInput | null,
  sortedChannelProductIds?: ModelStringInput | null,
  subProductSortOrder?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  wineList?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  categorySubCategoriesId?: ModelIDInput | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  dense?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  capacityUsages?: ModelStringInput | null,
  deliveryTax?: ModelIntInput | null,
  eatInTax?: ModelIntInput | null,
  takeawayTax?: ModelIntInput | null,
  max?: ModelIntInput | null,
  min?: ModelIntInput | null,
  isVariant?: ModelBooleanInput | null,
  multiMax?: ModelIntInput | null,
  multiply?: ModelIntInput | null,
  plu?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  snoozeStart?: ModelStringInput | null,
  snoozeEnd?: ModelStringInput | null,
  price?: ModelIntInput | null,
  posCategoryIds?: ModelStringInput | null,
  posProductCategoryId?: ModelStringInput | null,
  posProductId?: ModelStringInput | null,
  productTags?: ModelStringInput | null,
  productType?: ModelIntInput | null,
  isWine?: ModelBooleanInput | null,
  priceType?: ModelPriceTypeInput | null,
  subProductSortOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type ModelCategoriesProductsFilterInput = {
  id?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  productID?: ModelIDInput | null,
  categoryID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCategoriesProductsFilterInput | null > | null,
  or?: Array< ModelCategoriesProductsFilterInput | null > | null,
  not?: ModelCategoriesProductsFilterInput | null,
};

export type ModelSubProductsFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  productId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSubProductsFilterInput | null > | null,
  or?: Array< ModelSubProductsFilterInput | null > | null,
  not?: ModelSubProductsFilterInput | null,
};

export type ModelSubProductsConnection = {
  __typename: "ModelSubProductsConnection",
  items:  Array<SubProducts | null >,
  nextToken?: string | null,
};

export type ModelProductsSubProductsFilterInput = {
  id?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  productID?: ModelIDInput | null,
  subProductsID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProductsSubProductsFilterInput | null > | null,
  or?: Array< ModelProductsSubProductsFilterInput | null > | null,
  not?: ModelProductsSubProductsFilterInput | null,
};

export type ModelFloorFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  locationFloorsId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFloorFilterInput | null > | null,
  or?: Array< ModelFloorFilterInput | null > | null,
  not?: ModelFloorFilterInput | null,
};

export type ModelTableFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  seats?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTableFilterInput | null > | null,
  or?: Array< ModelTableFilterInput | null > | null,
  not?: ModelTableFilterInput | null,
  floorTablesId?: ModelIDInput | null,
  tableActiveOrderId?: ModelIDInput | null,
};

export type ModelOrderItemFilterInput = {
  id?: ModelIDInput | null,
  plu?: ModelStringInput | null,
  name?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  price?: ModelIntInput | null,
  total?: ModelIntInput | null,
  type?: ModelIntInput | null,
  payment?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderItemFilterInput | null > | null,
  or?: Array< ModelOrderItemFilterInput | null > | null,
  not?: ModelOrderItemFilterInput | null,
  orderItemSubItemsId?: ModelIDInput | null,
  orderOrderItemsId?: ModelIDInput | null,
  orderItemProductId?: ModelIDInput | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  businessId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  note?: ModelStringInput | null,
  type?: ModelOrderTypeInput | null,
  orderItemsSortOrder?: ModelStringInput | null,
  subTotal?: ModelIntInput | null,
  total?: ModelIntInput | null,
  paid?: ModelIntInput | null,
  ristoQuickStatus?: ModelRistoQuickStatusInput | null,
  status?: ModelOrderStatusInput | null,
  userId?: ModelIDInput | null,
  generalVatRate?: ModelIntInput | null,
  deliveryCost?: ModelIntInput | null,
  phoneNumber?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
  orderTableId?: ModelIDInput | null,
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null,
  orderID?: ModelIDInput | null,
  orderIDRistoQuick?: ModelIDInput | null,
  status?: ModelPaymentStatusInput | null,
  userID?: ModelStringInput | null,
  useCoin?: ModelBooleanInput | null,
  userEmail?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  paymentID?: ModelStringInput | null,
  paymentAmount?: ModelIntInput | null,
  invoiceID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPaymentFilterInput | null > | null,
  or?: Array< ModelPaymentFilterInput | null > | null,
  not?: ModelPaymentFilterInput | null,
};

export type ModelIntegrationGeneralFilterInput = {
  id?: ModelIDInput | null,
  latestVersion?: ModelStringInput | null,
  latestLink?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIntegrationGeneralFilterInput | null > | null,
  or?: Array< ModelIntegrationGeneralFilterInput | null > | null,
  not?: ModelIntegrationGeneralFilterInput | null,
};

export type ModelIntegrationGeneralConnection = {
  __typename: "ModelIntegrationGeneralConnection",
  items:  Array<IntegrationGeneral | null >,
  nextToken?: string | null,
};

export type ModelIntegrationFilterInput = {
  id?: ModelIDInput | null,
  run?: ModelBooleanInput | null,
  integrationGeneralId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIntegrationFilterInput | null > | null,
  or?: Array< ModelIntegrationFilterInput | null > | null,
  not?: ModelIntegrationFilterInput | null,
};

export type ModelIntegrationConnection = {
  __typename: "ModelIntegrationConnection",
  items:  Array<Integration | null >,
  nextToken?: string | null,
};

export type GetUserPaymentMethodsResponse = GetUserPaymentMethodsResponseSuccess | ErrorResponse


export type GetUserPaymentMethodsResponseSuccess = {
  __typename: "GetUserPaymentMethodsResponseSuccess",
  userPaymentMethods?:  Array<userPaymentMethod | null > | null,
};

export type userPaymentMethod = {
  __typename: "userPaymentMethod",
  id: string,
  brand: string,
  last4: string,
  default_source: boolean,
};

export type GetLocationSubscriptionResponse = GetLocationSubscriptionResponseSuccess | ErrorResponse


export type GetLocationSubscriptionResponseSuccess = {
  __typename: "GetLocationSubscriptionResponseSuccess",
  paymentMethods?:  Array<userPaymentMethod | null > | null,
  subscription: LocationSubscriptionResponse,
};

export type LocationSubscriptionResponse = {
  __typename: "LocationSubscriptionResponse",
  id: string,
  current_period_end: number,
  schedule: boolean,
};

export type GetPresenceKeyResponse = GetPresenceKeyResponseSuccess | ErrorResponse


export type GetPresenceKeyResponseSuccess = {
  __typename: "GetPresenceKeyResponseSuccess",
  presenceKey: string,
  valid: boolean,
};

export type ModelSubscriptionBusinessFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBusinessFilterInput | null > | null,
  or?: Array< ModelSubscriptionBusinessFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  email?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  coin?: ModelSubscriptionIntInput | null,
  totalCoin?: ModelSubscriptionIntInput | null,
  stripeCustomerId?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  businessName?: ModelSubscriptionStringInput | null,
  taxCode?: ModelSubscriptionStringInput | null,
  sdi?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  fiscalCode?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  id?: ModelStringInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAssociatedFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  corporateID?: ModelSubscriptionIDInput | null,
  userEmail?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAssociatedFilterInput | null > | null,
  or?: Array< ModelSubscriptionAssociatedFilterInput | null > | null,
  userId?: ModelStringInput | null,
};

export type ModelSubscriptionCorporateFilterInput = {
  businessCorporateId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCorporateFilterInput | null > | null,
  or?: Array< ModelSubscriptionCorporateFilterInput | null > | null,
};

export type ModelSubscriptionLocationFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  images?: ModelSubscriptionStringInput | null,
  logo?: ModelSubscriptionStringInput | null,
  bio?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  deliverectId?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  timeZone?: ModelSubscriptionStringInput | null,
  menuSortOrder?: ModelSubscriptionStringInput | null,
  credit?: ModelSubscriptionIntInput | null,
  selfOrdering?: ModelSubscriptionBooleanInput | null,
  template?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  showCurrency?: ModelSubscriptionBooleanInput | null,
  showWineList?: ModelSubscriptionBooleanInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  languageDefault?: ModelSubscriptionStringInput | null,
  languagesEnabled?: ModelSubscriptionStringInput | null,
  translationsInProgress?: ModelSubscriptionBooleanInput | null,
  coverPrice?: ModelSubscriptionIntInput | null,
  deliveryCost?: ModelSubscriptionIntInput | null,
  generalVatRate?: ModelSubscriptionIntInput | null,
  businessLocationsId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLocationFilterInput | null > | null,
  or?: Array< ModelSubscriptionLocationFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionPostFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  image?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  userName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPostFilterInput | null > | null,
  or?: Array< ModelSubscriptionPostFilterInput | null > | null,
};

export type ModelSubscriptionCorporateLocationsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  locationID?: ModelSubscriptionIDInput | null,
  corporateID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCorporateLocationsFilterInput | null > | null,
  or?: Array< ModelSubscriptionCorporateLocationsFilterInput | null > | null,
};

export type ModelSubscriptionAssociatedPresenceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  associatedID?: ModelSubscriptionIDInput | null,
  locationID?: ModelSubscriptionIDInput | null,
  corporateID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAssociatedPresenceFilterInput | null > | null,
  or?: Array< ModelSubscriptionAssociatedPresenceFilterInput | null > | null,
};

export type ModelSubscriptionMenuFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  businessId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  dense?: ModelSubscriptionBooleanInput | null,
  type?: ModelSubscriptionIntInput | null,
  nestedModifiers?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionIntInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  wineList?: ModelSubscriptionBooleanInput | null,
  onlyAssociated?: ModelSubscriptionBooleanInput | null,
  categorySortOrder?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMenuFilterInput | null > | null,
  or?: Array< ModelSubscriptionMenuFilterInput | null > | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  businessId?: ModelSubscriptionIDInput | null,
  menuCategoriesId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  dense?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionStringInput | null,
  posCategoryType?: ModelSubscriptionStringInput | null,
  posCategoryId?: ModelSubscriptionStringInput | null,
  posLocationId?: ModelSubscriptionStringInput | null,
  level?: ModelSubscriptionIntInput | null,
  sortedChannelProductIds?: ModelSubscriptionStringInput | null,
  subProductSortOrder?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  wineList?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  categorySubCategoriesId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  businessId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  dense?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionStringInput | null,
  capacityUsages?: ModelSubscriptionStringInput | null,
  deliveryTax?: ModelSubscriptionIntInput | null,
  eatInTax?: ModelSubscriptionIntInput | null,
  takeawayTax?: ModelSubscriptionIntInput | null,
  max?: ModelSubscriptionIntInput | null,
  min?: ModelSubscriptionIntInput | null,
  isVariant?: ModelSubscriptionBooleanInput | null,
  multiMax?: ModelSubscriptionIntInput | null,
  multiply?: ModelSubscriptionIntInput | null,
  plu?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  snoozeStart?: ModelSubscriptionStringInput | null,
  snoozeEnd?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionIntInput | null,
  posCategoryIds?: ModelSubscriptionStringInput | null,
  posProductCategoryId?: ModelSubscriptionStringInput | null,
  posProductId?: ModelSubscriptionStringInput | null,
  productTags?: ModelSubscriptionStringInput | null,
  productType?: ModelSubscriptionIntInput | null,
  isWine?: ModelSubscriptionBooleanInput | null,
  priceType?: ModelSubscriptionStringInput | null,
  subProductSortOrder?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
};

export type ModelSubscriptionCategoriesProductsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  productID?: ModelSubscriptionIDInput | null,
  categoryID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoriesProductsFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoriesProductsFilterInput | null > | null,
};

export type ModelSubscriptionSubProductsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  businessId?: ModelSubscriptionIDInput | null,
  productId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSubProductsFilterInput | null > | null,
  or?: Array< ModelSubscriptionSubProductsFilterInput | null > | null,
};

export type ModelSubscriptionProductsSubProductsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  productID?: ModelSubscriptionIDInput | null,
  subProductsID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductsSubProductsFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductsSubProductsFilterInput | null > | null,
};

export type ModelSubscriptionFloorFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  businessId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFloorFilterInput | null > | null,
  or?: Array< ModelSubscriptionFloorFilterInput | null > | null,
  floorTablesId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionTableFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  businessId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  seats?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTableFilterInput | null > | null,
  or?: Array< ModelSubscriptionTableFilterInput | null > | null,
  tableActiveOrderId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionOrderItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  plu?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  price?: ModelSubscriptionIntInput | null,
  total?: ModelSubscriptionIntInput | null,
  type?: ModelSubscriptionIntInput | null,
  payment?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderItemFilterInput | null > | null,
  orderItemSubItemsId?: ModelSubscriptionIDInput | null,
  orderItemProductId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  businessId?: ModelSubscriptionIDInput | null,
  note?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  orderItemsSortOrder?: ModelSubscriptionStringInput | null,
  subTotal?: ModelSubscriptionIntInput | null,
  total?: ModelSubscriptionIntInput | null,
  paid?: ModelSubscriptionIntInput | null,
  ristoQuickStatus?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  generalVatRate?: ModelSubscriptionIntInput | null,
  deliveryCost?: ModelSubscriptionIntInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  orderOrderItemsId?: ModelSubscriptionIDInput | null,
  orderTableId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionPaymentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  orderID?: ModelSubscriptionIDInput | null,
  orderIDRistoQuick?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionStringInput | null,
  useCoin?: ModelSubscriptionBooleanInput | null,
  userEmail?: ModelSubscriptionStringInput | null,
  customerName?: ModelSubscriptionStringInput | null,
  paymentID?: ModelSubscriptionStringInput | null,
  paymentAmount?: ModelSubscriptionIntInput | null,
  invoiceID?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaymentFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentFilterInput | null > | null,
};

export type ModelSubscriptionIntegrationGeneralFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  latestVersion?: ModelSubscriptionStringInput | null,
  latestLink?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIntegrationGeneralFilterInput | null > | null,
  or?: Array< ModelSubscriptionIntegrationGeneralFilterInput | null > | null,
};

export type ModelSubscriptionIntegrationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  run?: ModelSubscriptionBooleanInput | null,
  integrationGeneralId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionIntegrationFilterInput | null > | null,
  or?: Array< ModelSubscriptionIntegrationFilterInput | null > | null,
};

export type NewOrderMutationVariables = {
  cart: LambdaCartType,
};

export type NewOrderMutation = {
  newOrder: ( {
      __typename: "NewOrderResponseSuccess",
      orderId?: string | null,
      code?: number | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type RegisterLocationMutationVariables = {
  registerLocationBody: LambdaRegisterLocationBody,
};

export type RegisterLocationMutation = {
  registerLocation: ( {
      __typename: "RegisterLocationResponseSuccess",
      locationId?: string | null,
      code?: number | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type StripeAccountSessionMutationVariables = {
  locationID: string,
};

export type StripeAccountSessionMutation = {
  stripeAccountSession: ( {
      __typename: "StripeAccountSessionResponseSuccess",
      clientSecret?: string | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type CreateInvoiceMutationVariables = {
  locationId: string,
  amount: number,
  paymentId: string,
  username?: string | null,
  invoiceUserAttribues?: InvoiceUserAttributes | null,
};

export type CreateInvoiceMutation = {
  createInvoice: ( {
      __typename: "CreateInvoiceResponseSuccess",
      invoiceId?: string | null,
      code?: number | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type SendInvoiceMutationVariables = {
  paymentID: string,
};

export type SendInvoiceMutation = {
  sendInvoice: ( {
      __typename: "SendInvoiceResponseSuccess",
      send: boolean,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type SendReceiptMutationVariables = {
  paymentID: string,
};

export type SendReceiptMutation = {
  sendReceipt: ( {
      __typename: "SendReceiptResponseSuccess",
      send: boolean,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type RemoveCardMutationVariables = {
  paymentMethodId: string,
};

export type RemoveCardMutation = {
  removeCard: ( {
      __typename: "RemoveCardSuccess",
      success: boolean,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type CreateBusinessMutationVariables = {
  input: CreateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type CreateBusinessMutation = {
  createBusiness?:  {
    __typename: "Business",
    id: string,
    name: string,
    locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    corporate?:  {
      __typename: "ModelCorporateConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessMutationVariables = {
  input: UpdateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type UpdateBusinessMutation = {
  updateBusiness?:  {
    __typename: "Business",
    id: string,
    name: string,
    locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    corporate?:  {
      __typename: "ModelCorporateConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessMutationVariables = {
  input: DeleteBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type DeleteBusinessMutation = {
  deleteBusiness?:  {
    __typename: "Business",
    id: string,
    name: string,
    locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    corporate?:  {
      __typename: "ModelCorporateConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    type: UserType,
    coin?: number | null,
    totalCoin?: number | null,
    stripeCustomerId?: string | null,
    association?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      nextToken?: string | null,
    } | null,
    firstName?: string | null,
    businessName?: string | null,
    taxCode?: string | null,
    sdi?: string | null,
    lastName?: string | null,
    fiscalCode?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    gender?: GenderType | null,
    dob?:  {
      __typename: "DoB",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAssociatedMutationVariables = {
  input: DeleteAssociatedInput,
  condition?: ModelAssociatedConditionInput | null,
};

export type DeleteAssociatedMutation = {
  deleteAssociated?:  {
    __typename: "Associated",
    id: string,
    userId?: string | null,
    corporateID: string,
    userEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    status: AssociatedStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCorporateLocationsMutationVariables = {
  input: CreateCorporateLocationsInput,
  condition?: ModelCorporateLocationsConditionInput | null,
};

export type CreateCorporateLocationsMutation = {
  createCorporateLocations?:  {
    __typename: "CorporateLocations",
    id: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCorporateLocationsMutationVariables = {
  input: UpdateCorporateLocationsInput,
  condition?: ModelCorporateLocationsConditionInput | null,
};

export type UpdateCorporateLocationsMutation = {
  updateCorporateLocations?:  {
    __typename: "CorporateLocations",
    id: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCorporateLocationsMutationVariables = {
  input: DeleteCorporateLocationsInput,
  condition?: ModelCorporateLocationsConditionInput | null,
};

export type DeleteCorporateLocationsMutation = {
  deleteCorporateLocations?:  {
    __typename: "CorporateLocations",
    id: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAssociatedPresenceMutationVariables = {
  input: CreateAssociatedPresenceInput,
  condition?: ModelAssociatedPresenceConditionInput | null,
};

export type CreateAssociatedPresenceMutation = {
  createAssociatedPresence?:  {
    __typename: "AssociatedPresence",
    id: string,
    associatedID: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    associated?:  {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAssociatedPresenceMutationVariables = {
  input: UpdateAssociatedPresenceInput,
  condition?: ModelAssociatedPresenceConditionInput | null,
};

export type UpdateAssociatedPresenceMutation = {
  updateAssociatedPresence?:  {
    __typename: "AssociatedPresence",
    id: string,
    associatedID: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    associated?:  {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAssociatedPresenceMutationVariables = {
  input: DeleteAssociatedPresenceInput,
  condition?: ModelAssociatedPresenceConditionInput | null,
};

export type DeleteAssociatedPresenceMutation = {
  deleteAssociatedPresence?:  {
    __typename: "AssociatedPresence",
    id: string,
    associatedID: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    associated?:  {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrderItemMutationVariables = {
  input: CreateOrderItemInput,
  condition?: ModelOrderItemConditionInput | null,
};

export type CreateOrderItemMutation = {
  createOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    plu: string,
    name: string,
    quantity: number,
    price: number,
    total: number,
    type: number,
    payment?: boolean | null,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    orderItemSubItemsId?: string | null,
    orderOrderItemsId?: string | null,
    orderItemProductId?: string | null,
  } | null,
};

export type UpdateOrderItemMutationVariables = {
  input: UpdateOrderItemInput,
  condition?: ModelOrderItemConditionInput | null,
};

export type UpdateOrderItemMutation = {
  updateOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    plu: string,
    name: string,
    quantity: number,
    price: number,
    total: number,
    type: number,
    payment?: boolean | null,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    orderItemSubItemsId?: string | null,
    orderOrderItemsId?: string | null,
    orderItemProductId?: string | null,
  } | null,
};

export type DeleteOrderItemMutationVariables = {
  input: DeleteOrderItemInput,
  condition?: ModelOrderItemConditionInput | null,
};

export type DeleteOrderItemMutation = {
  deleteOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    plu: string,
    name: string,
    quantity: number,
    price: number,
    total: number,
    type: number,
    payment?: boolean | null,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    orderItemSubItemsId?: string | null,
    orderOrderItemsId?: string | null,
    orderItemProductId?: string | null,
  } | null,
};

export type CreatePaymentMutationVariables = {
  input: CreatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type CreatePaymentMutation = {
  createPayment?:  {
    __typename: "Payment",
    id: string,
    orderID: string,
    orderIDRistoQuick?: string | null,
    status: PaymentStatus,
    userID?: string | null,
    useCoin: boolean,
    userEmail: string,
    customerName?: string | null,
    paymentID: string,
    paymentAmount: number,
    invoiceID?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePaymentMutationVariables = {
  input: UpdatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type UpdatePaymentMutation = {
  updatePayment?:  {
    __typename: "Payment",
    id: string,
    orderID: string,
    orderIDRistoQuick?: string | null,
    status: PaymentStatus,
    userID?: string | null,
    useCoin: boolean,
    userEmail: string,
    customerName?: string | null,
    paymentID: string,
    paymentAmount: number,
    invoiceID?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePaymentMutationVariables = {
  input: DeletePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type DeletePaymentMutation = {
  deletePayment?:  {
    __typename: "Payment",
    id: string,
    orderID: string,
    orderIDRistoQuick?: string | null,
    status: PaymentStatus,
    userID?: string | null,
    useCoin: boolean,
    userEmail: string,
    customerName?: string | null,
    paymentID: string,
    paymentAmount: number,
    invoiceID?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIntegrationGeneralMutationVariables = {
  input: CreateIntegrationGeneralInput,
  condition?: ModelIntegrationGeneralConditionInput | null,
};

export type CreateIntegrationGeneralMutation = {
  createIntegrationGeneral?:  {
    __typename: "IntegrationGeneral",
    id: string,
    latestVersion: string,
    latestLink: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIntegrationGeneralMutationVariables = {
  input: UpdateIntegrationGeneralInput,
  condition?: ModelIntegrationGeneralConditionInput | null,
};

export type UpdateIntegrationGeneralMutation = {
  updateIntegrationGeneral?:  {
    __typename: "IntegrationGeneral",
    id: string,
    latestVersion: string,
    latestLink: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIntegrationGeneralMutationVariables = {
  input: DeleteIntegrationGeneralInput,
  condition?: ModelIntegrationGeneralConditionInput | null,
};

export type DeleteIntegrationGeneralMutation = {
  deleteIntegrationGeneral?:  {
    __typename: "IntegrationGeneral",
    id: string,
    latestVersion: string,
    latestLink: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIntegrationMutationVariables = {
  input: CreateIntegrationInput,
  condition?: ModelIntegrationConditionInput | null,
};

export type CreateIntegrationMutation = {
  createIntegration?:  {
    __typename: "Integration",
    id: string,
    run: boolean,
    integrationGeneralId: string,
    integrationGeneral?:  {
      __typename: "IntegrationGeneral",
      id: string,
      latestVersion: string,
      latestLink: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIntegrationMutationVariables = {
  input: UpdateIntegrationInput,
  condition?: ModelIntegrationConditionInput | null,
};

export type UpdateIntegrationMutation = {
  updateIntegration?:  {
    __typename: "Integration",
    id: string,
    run: boolean,
    integrationGeneralId: string,
    integrationGeneral?:  {
      __typename: "IntegrationGeneral",
      id: string,
      latestVersion: string,
      latestLink: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIntegrationMutationVariables = {
  input: DeleteIntegrationInput,
  condition?: ModelIntegrationConditionInput | null,
};

export type DeleteIntegrationMutation = {
  deleteIntegration?:  {
    __typename: "Integration",
    id: string,
    run: boolean,
    integrationGeneralId: string,
    integrationGeneral?:  {
      __typename: "IntegrationGeneral",
      id: string,
      latestVersion: string,
      latestLink: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    email: string,
    type: UserType,
    coin?: number | null,
    totalCoin?: number | null,
    stripeCustomerId?: string | null,
    association?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      nextToken?: string | null,
    } | null,
    firstName?: string | null,
    businessName?: string | null,
    taxCode?: string | null,
    sdi?: string | null,
    lastName?: string | null,
    fiscalCode?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    gender?: GenderType | null,
    dob?:  {
      __typename: "DoB",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    type: UserType,
    coin?: number | null,
    totalCoin?: number | null,
    stripeCustomerId?: string | null,
    association?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      nextToken?: string | null,
    } | null,
    firstName?: string | null,
    businessName?: string | null,
    taxCode?: string | null,
    sdi?: string | null,
    lastName?: string | null,
    fiscalCode?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    gender?: GenderType | null,
    dob?:  {
      __typename: "DoB",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAssociatedMutationVariables = {
  input: CreateAssociatedInput,
  condition?: ModelAssociatedConditionInput | null,
};

export type CreateAssociatedMutation = {
  createAssociated?:  {
    __typename: "Associated",
    id: string,
    userId?: string | null,
    corporateID: string,
    userEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    status: AssociatedStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAssociatedMutationVariables = {
  input: UpdateAssociatedInput,
  condition?: ModelAssociatedConditionInput | null,
};

export type UpdateAssociatedMutation = {
  updateAssociated?:  {
    __typename: "Associated",
    id: string,
    userId?: string | null,
    corporateID: string,
    userEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    status: AssociatedStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCorporateMutationVariables = {
  input: CreateCorporateInput,
  condition?: ModelCorporateConditionInput | null,
};

export type CreateCorporateMutation = {
  createCorporate?:  {
    __typename: "Corporate",
    id: string,
    businessCorporateId: string,
    name?: string | null,
    associates?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    associatedLocations?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCorporateMutationVariables = {
  input: UpdateCorporateInput,
  condition?: ModelCorporateConditionInput | null,
};

export type UpdateCorporateMutation = {
  updateCorporate?:  {
    __typename: "Corporate",
    id: string,
    businessCorporateId: string,
    name?: string | null,
    associates?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    associatedLocations?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCorporateMutationVariables = {
  input: DeleteCorporateInput,
  condition?: ModelCorporateConditionInput | null,
};

export type DeleteCorporateMutation = {
  deleteCorporate?:  {
    __typename: "Corporate",
    id: string,
    businessCorporateId: string,
    name?: string | null,
    associates?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    associatedLocations?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    images?: Array< string | null > | null,
    logo?: string | null,
    bio?: string | null,
    bioTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    gallery?:  Array< {
      __typename: "ImageGallery",
      image: string,
      name: string,
    } | null > | null,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    invoiceInfo?:  {
      __typename: "InvoiceInfo",
      taxCode: string,
      broadcastFormat: string,
      businessName: string,
      taxRegime: string,
    } | null,
    openingDay?:  {
      __typename: "OpeningDay",
    } | null,
    deliverectId: string,
    status: LocationStatus,
    timeZone: string,
    menu?:  {
      __typename: "ModelMenuConnection",
      nextToken?: string | null,
    } | null,
    menuSortOrder?: Array< string | null > | null,
    floors?:  {
      __typename: "ModelFloorConnection",
      nextToken?: string | null,
    } | null,
    credit: number,
    selfOrdering: boolean,
    externalLinks?:  Array< {
      __typename: "ExternaLink",
      link: string,
      label: string,
      icon: string,
    } > | null,
    template: Template,
    color: string,
    showCurrency: boolean,
    showWineList?: boolean | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    phoneNumber?: string | null,
    social?:  Array< {
      __typename: "SocialLink",
      link: string,
      label: string,
      icon: string,
    } | null > | null,
    email?: string | null,
    languageDefault: string,
    languagesEnabled?: Array< string | null > | null,
    translationsInProgress?: boolean | null,
    coverPrice?: number | null,
    deliveryCost?: number | null,
    generalVatRate?: number | null,
    businessLocationsId: string,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    associatedCorporate?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    bankDetails?:  {
      __typename: "BankDetails",
      accountHolder: string,
      iban: string,
      bic: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    images?: Array< string | null > | null,
    logo?: string | null,
    bio?: string | null,
    bioTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    gallery?:  Array< {
      __typename: "ImageGallery",
      image: string,
      name: string,
    } | null > | null,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    invoiceInfo?:  {
      __typename: "InvoiceInfo",
      taxCode: string,
      broadcastFormat: string,
      businessName: string,
      taxRegime: string,
    } | null,
    openingDay?:  {
      __typename: "OpeningDay",
    } | null,
    deliverectId: string,
    status: LocationStatus,
    timeZone: string,
    menu?:  {
      __typename: "ModelMenuConnection",
      nextToken?: string | null,
    } | null,
    menuSortOrder?: Array< string | null > | null,
    floors?:  {
      __typename: "ModelFloorConnection",
      nextToken?: string | null,
    } | null,
    credit: number,
    selfOrdering: boolean,
    externalLinks?:  Array< {
      __typename: "ExternaLink",
      link: string,
      label: string,
      icon: string,
    } > | null,
    template: Template,
    color: string,
    showCurrency: boolean,
    showWineList?: boolean | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    phoneNumber?: string | null,
    social?:  Array< {
      __typename: "SocialLink",
      link: string,
      label: string,
      icon: string,
    } | null > | null,
    email?: string | null,
    languageDefault: string,
    languagesEnabled?: Array< string | null > | null,
    translationsInProgress?: boolean | null,
    coverPrice?: number | null,
    deliveryCost?: number | null,
    generalVatRate?: number | null,
    businessLocationsId: string,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    associatedCorporate?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    bankDetails?:  {
      __typename: "BankDetails",
      accountHolder: string,
      iban: string,
      bic: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    images?: Array< string | null > | null,
    logo?: string | null,
    bio?: string | null,
    bioTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    gallery?:  Array< {
      __typename: "ImageGallery",
      image: string,
      name: string,
    } | null > | null,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    invoiceInfo?:  {
      __typename: "InvoiceInfo",
      taxCode: string,
      broadcastFormat: string,
      businessName: string,
      taxRegime: string,
    } | null,
    openingDay?:  {
      __typename: "OpeningDay",
    } | null,
    deliverectId: string,
    status: LocationStatus,
    timeZone: string,
    menu?:  {
      __typename: "ModelMenuConnection",
      nextToken?: string | null,
    } | null,
    menuSortOrder?: Array< string | null > | null,
    floors?:  {
      __typename: "ModelFloorConnection",
      nextToken?: string | null,
    } | null,
    credit: number,
    selfOrdering: boolean,
    externalLinks?:  Array< {
      __typename: "ExternaLink",
      link: string,
      label: string,
      icon: string,
    } > | null,
    template: Template,
    color: string,
    showCurrency: boolean,
    showWineList?: boolean | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    phoneNumber?: string | null,
    social?:  Array< {
      __typename: "SocialLink",
      link: string,
      label: string,
      icon: string,
    } | null > | null,
    email?: string | null,
    languageDefault: string,
    languagesEnabled?: Array< string | null > | null,
    translationsInProgress?: boolean | null,
    coverPrice?: number | null,
    deliveryCost?: number | null,
    generalVatRate?: number | null,
    businessLocationsId: string,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    associatedCorporate?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    bankDetails?:  {
      __typename: "BankDetails",
      accountHolder: string,
      iban: string,
      bic: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePostMutationVariables = {
  input: CreatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type CreatePostMutation = {
  createPost?:  {
    __typename: "Post",
    id: string,
    image?: string | null,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    userId: string,
    userName: string,
    postLocationId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePostMutationVariables = {
  input: UpdatePostInput,
  condition?: ModelPostConditionInput | null,
};

export type UpdatePostMutation = {
  updatePost?:  {
    __typename: "Post",
    id: string,
    image?: string | null,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    userId: string,
    userName: string,
    postLocationId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePostMutationVariables = {
  input: DeletePostInput,
  condition?: ModelPostConditionInput | null,
};

export type DeletePostMutation = {
  deletePost?:  {
    __typename: "Post",
    id: string,
    image?: string | null,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    userId: string,
    userName: string,
    postLocationId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMenuMutationVariables = {
  input: CreateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type CreateMenuMutation = {
  createMenu?:  {
    __typename: "Menu",
    id: string,
    businessId: string,
    menuLocationId: string,
    location:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    type?: number | null,
    nestedModifiers: boolean,
    image?: Array< string | null > | null,
    currency?: number | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    onlyAssociated?: boolean | null,
    categorySortOrder?: Array< string | null > | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMenuMutationVariables = {
  input: UpdateMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type UpdateMenuMutation = {
  updateMenu?:  {
    __typename: "Menu",
    id: string,
    businessId: string,
    menuLocationId: string,
    location:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    type?: number | null,
    nestedModifiers: boolean,
    image?: Array< string | null > | null,
    currency?: number | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    onlyAssociated?: boolean | null,
    categorySortOrder?: Array< string | null > | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMenuMutationVariables = {
  input: DeleteMenuInput,
  condition?: ModelMenuConditionInput | null,
};

export type DeleteMenuMutation = {
  deleteMenu?:  {
    __typename: "Menu",
    id: string,
    businessId: string,
    menuLocationId: string,
    location:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    type?: number | null,
    nestedModifiers: boolean,
    image?: Array< string | null > | null,
    currency?: number | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    onlyAssociated?: boolean | null,
    categorySortOrder?: Array< string | null > | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    businessId: string,
    menuCategoriesId: string,
    locationId: string,
    menu:  {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    posCategoryType?: Array< string | null > | null,
    posCategoryId?: string | null,
    posLocationId?: string | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    level?: number | null,
    sortedChannelProductIds?: Array< string | null > | null,
    subProductSortOrder?: Array< string | null > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    categorySubCategoriesId?: string | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    businessId: string,
    menuCategoriesId: string,
    locationId: string,
    menu:  {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    posCategoryType?: Array< string | null > | null,
    posCategoryId?: string | null,
    posLocationId?: string | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    level?: number | null,
    sortedChannelProductIds?: Array< string | null > | null,
    subProductSortOrder?: Array< string | null > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    categorySubCategoriesId?: string | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    businessId: string,
    menuCategoriesId: string,
    locationId: string,
    menu:  {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    posCategoryType?: Array< string | null > | null,
    posCategoryId?: string | null,
    posLocationId?: string | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    level?: number | null,
    sortedChannelProductIds?: Array< string | null > | null,
    subProductSortOrder?: Array< string | null > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    categorySubCategoriesId?: string | null,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    categories?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    locationId: string,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    capacityUsages?: Array< string | null > | null,
    deliveryTax?: number | null,
    eatInTax?: number | null,
    takeawayTax?: number | null,
    max?: number | null,
    min?: number | null,
    isVariant?: boolean | null,
    multiMax?: number | null,
    multiply?: number | null,
    plu: string,
    disabled?: boolean | null,
    snoozeStart?: string | null,
    snoozeEnd?: string | null,
    price: number,
    posCategoryIds?: Array< string | null > | null,
    posProductCategoryId?: string | null,
    posProductId?: string | null,
    productTags?: Array< string | null > | null,
    productType: number,
    isWine?: boolean | null,
    wineInfo?:  {
      __typename: "WineInfo",
      caption?: string | null,
      year?: number | null,
      perfectFor?: Array< number | null > | null,
      typology?: number | null,
      country?: number | null,
      region?: number | null,
      alcoholPercentage?: number | null,
      servingTemperature?: number | null,
      color?: string | null,
      perfume?: string | null,
      taste?: string | null,
      producer?: string | null,
      productionMethod?: number | null,
    } | null,
    priceType?: PriceType | null,
    subProductSortOrder?: Array< string | null > | null,
    subProducts?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    categories?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    locationId: string,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    capacityUsages?: Array< string | null > | null,
    deliveryTax?: number | null,
    eatInTax?: number | null,
    takeawayTax?: number | null,
    max?: number | null,
    min?: number | null,
    isVariant?: boolean | null,
    multiMax?: number | null,
    multiply?: number | null,
    plu: string,
    disabled?: boolean | null,
    snoozeStart?: string | null,
    snoozeEnd?: string | null,
    price: number,
    posCategoryIds?: Array< string | null > | null,
    posProductCategoryId?: string | null,
    posProductId?: string | null,
    productTags?: Array< string | null > | null,
    productType: number,
    isWine?: boolean | null,
    wineInfo?:  {
      __typename: "WineInfo",
      caption?: string | null,
      year?: number | null,
      perfectFor?: Array< number | null > | null,
      typology?: number | null,
      country?: number | null,
      region?: number | null,
      alcoholPercentage?: number | null,
      servingTemperature?: number | null,
      color?: string | null,
      perfume?: string | null,
      taste?: string | null,
      producer?: string | null,
      productionMethod?: number | null,
    } | null,
    priceType?: PriceType | null,
    subProductSortOrder?: Array< string | null > | null,
    subProducts?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    categories?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    locationId: string,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    capacityUsages?: Array< string | null > | null,
    deliveryTax?: number | null,
    eatInTax?: number | null,
    takeawayTax?: number | null,
    max?: number | null,
    min?: number | null,
    isVariant?: boolean | null,
    multiMax?: number | null,
    multiply?: number | null,
    plu: string,
    disabled?: boolean | null,
    snoozeStart?: string | null,
    snoozeEnd?: string | null,
    price: number,
    posCategoryIds?: Array< string | null > | null,
    posProductCategoryId?: string | null,
    posProductId?: string | null,
    productTags?: Array< string | null > | null,
    productType: number,
    isWine?: boolean | null,
    wineInfo?:  {
      __typename: "WineInfo",
      caption?: string | null,
      year?: number | null,
      perfectFor?: Array< number | null > | null,
      typology?: number | null,
      country?: number | null,
      region?: number | null,
      alcoholPercentage?: number | null,
      servingTemperature?: number | null,
      color?: string | null,
      perfume?: string | null,
      taste?: string | null,
      producer?: string | null,
      productionMethod?: number | null,
    } | null,
    priceType?: PriceType | null,
    subProductSortOrder?: Array< string | null > | null,
    subProducts?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCategoriesProductsMutationVariables = {
  input: CreateCategoriesProductsInput,
  condition?: ModelCategoriesProductsConditionInput | null,
};

export type CreateCategoriesProductsMutation = {
  createCategoriesProducts?:  {
    __typename: "CategoriesProducts",
    id: string,
    locationId: string,
    productID: string,
    categoryID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    category?:  {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoriesProductsMutationVariables = {
  input: UpdateCategoriesProductsInput,
  condition?: ModelCategoriesProductsConditionInput | null,
};

export type UpdateCategoriesProductsMutation = {
  updateCategoriesProducts?:  {
    __typename: "CategoriesProducts",
    id: string,
    locationId: string,
    productID: string,
    categoryID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    category?:  {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoriesProductsMutationVariables = {
  input: DeleteCategoriesProductsInput,
  condition?: ModelCategoriesProductsConditionInput | null,
};

export type DeleteCategoriesProductsMutation = {
  deleteCategoriesProducts?:  {
    __typename: "CategoriesProducts",
    id: string,
    locationId: string,
    productID: string,
    categoryID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    category?:  {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSubProductsMutationVariables = {
  input: CreateSubProductsInput,
  condition?: ModelSubProductsConditionInput | null,
};

export type CreateSubProductsMutation = {
  createSubProducts?:  {
    __typename: "SubProducts",
    id: string,
    businessId: string,
    locationId: string,
    productId: string,
    product:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    products?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSubProductsMutationVariables = {
  input: UpdateSubProductsInput,
  condition?: ModelSubProductsConditionInput | null,
};

export type UpdateSubProductsMutation = {
  updateSubProducts?:  {
    __typename: "SubProducts",
    id: string,
    businessId: string,
    locationId: string,
    productId: string,
    product:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    products?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSubProductsMutationVariables = {
  input: DeleteSubProductsInput,
  condition?: ModelSubProductsConditionInput | null,
};

export type DeleteSubProductsMutation = {
  deleteSubProducts?:  {
    __typename: "SubProducts",
    id: string,
    businessId: string,
    locationId: string,
    productId: string,
    product:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    products?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductsSubProductsMutationVariables = {
  input: CreateProductsSubProductsInput,
  condition?: ModelProductsSubProductsConditionInput | null,
};

export type CreateProductsSubProductsMutation = {
  createProductsSubProducts?:  {
    __typename: "ProductsSubProducts",
    id: string,
    locationId: string,
    productID: string,
    subProductsID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subProducts?:  {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductsSubProductsMutationVariables = {
  input: UpdateProductsSubProductsInput,
  condition?: ModelProductsSubProductsConditionInput | null,
};

export type UpdateProductsSubProductsMutation = {
  updateProductsSubProducts?:  {
    __typename: "ProductsSubProducts",
    id: string,
    locationId: string,
    productID: string,
    subProductsID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subProducts?:  {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductsSubProductsMutationVariables = {
  input: DeleteProductsSubProductsInput,
  condition?: ModelProductsSubProductsConditionInput | null,
};

export type DeleteProductsSubProductsMutation = {
  deleteProductsSubProducts?:  {
    __typename: "ProductsSubProducts",
    id: string,
    locationId: string,
    productID: string,
    subProductsID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subProducts?:  {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFloorMutationVariables = {
  input: CreateFloorInput,
  condition?: ModelFloorConditionInput | null,
};

export type CreateFloorMutation = {
  createFloor?:  {
    __typename: "Floor",
    id: string,
    businessId: string,
    locationFloorsId: string,
    name: string,
    tables?:  {
      __typename: "ModelTableConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFloorMutationVariables = {
  input: UpdateFloorInput,
  condition?: ModelFloorConditionInput | null,
};

export type UpdateFloorMutation = {
  updateFloor?:  {
    __typename: "Floor",
    id: string,
    businessId: string,
    locationFloorsId: string,
    name: string,
    tables?:  {
      __typename: "ModelTableConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFloorMutationVariables = {
  input: DeleteFloorInput,
  condition?: ModelFloorConditionInput | null,
};

export type DeleteFloorMutation = {
  deleteFloor?:  {
    __typename: "Floor",
    id: string,
    businessId: string,
    locationFloorsId: string,
    name: string,
    tables?:  {
      __typename: "ModelTableConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTableMutationVariables = {
  input: CreateTableInput,
  condition?: ModelTableConditionInput | null,
};

export type CreateTableMutation = {
  createTable?:  {
    __typename: "Table",
    id: string,
    businessId: string,
    locationId: string,
    name: string,
    seats: number,
    activeOrder?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    floorTablesId?: string | null,
    tableActiveOrderId?: string | null,
  } | null,
};

export type UpdateTableMutationVariables = {
  input: UpdateTableInput,
  condition?: ModelTableConditionInput | null,
};

export type UpdateTableMutation = {
  updateTable?:  {
    __typename: "Table",
    id: string,
    businessId: string,
    locationId: string,
    name: string,
    seats: number,
    activeOrder?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    floorTablesId?: string | null,
    tableActiveOrderId?: string | null,
  } | null,
};

export type DeleteTableMutationVariables = {
  input: DeleteTableInput,
  condition?: ModelTableConditionInput | null,
};

export type DeleteTableMutation = {
  deleteTable?:  {
    __typename: "Table",
    id: string,
    businessId: string,
    locationId: string,
    name: string,
    seats: number,
    activeOrder?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    floorTablesId?: string | null,
    tableActiveOrderId?: string | null,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    businessId: string,
    locationId: string,
    table?:  {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null,
    destination?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    note?: string | null,
    billingInfo?:  {
      __typename: "BillingInfo",
      email?: string | null,
      phoneNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      vatNumber?: string | null,
      sdi?: string | null,
    } | null,
    type?: OrderType | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    orderItemsSortOrder?: Array< string | null > | null,
    subTotal: number,
    total: number,
    paid: number,
    deliverectParams?:  {
      __typename: "OrderDeliverectParams",
      id?: string | null,
      type: number,
      isAlreadyPaid: boolean,
      paymentType: number,
      paymentAmount: number,
      decimalDigits: number,
      status: number,
    } | null,
    ristoQuickStatus?: RistoQuickStatus | null,
    status: OrderStatus,
    lastPaymentsRistoQuick?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    generalVatRate?: number | null,
    deliveryCost?: number | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    orderTableId?: string | null,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    businessId: string,
    locationId: string,
    table?:  {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null,
    destination?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    note?: string | null,
    billingInfo?:  {
      __typename: "BillingInfo",
      email?: string | null,
      phoneNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      vatNumber?: string | null,
      sdi?: string | null,
    } | null,
    type?: OrderType | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    orderItemsSortOrder?: Array< string | null > | null,
    subTotal: number,
    total: number,
    paid: number,
    deliverectParams?:  {
      __typename: "OrderDeliverectParams",
      id?: string | null,
      type: number,
      isAlreadyPaid: boolean,
      paymentType: number,
      paymentAmount: number,
      decimalDigits: number,
      status: number,
    } | null,
    ristoQuickStatus?: RistoQuickStatus | null,
    status: OrderStatus,
    lastPaymentsRistoQuick?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    generalVatRate?: number | null,
    deliveryCost?: number | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    orderTableId?: string | null,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    businessId: string,
    locationId: string,
    table?:  {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null,
    destination?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    note?: string | null,
    billingInfo?:  {
      __typename: "BillingInfo",
      email?: string | null,
      phoneNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      vatNumber?: string | null,
      sdi?: string | null,
    } | null,
    type?: OrderType | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    orderItemsSortOrder?: Array< string | null > | null,
    subTotal: number,
    total: number,
    paid: number,
    deliverectParams?:  {
      __typename: "OrderDeliverectParams",
      id?: string | null,
      type: number,
      isAlreadyPaid: boolean,
      paymentType: number,
      paymentAmount: number,
      decimalDigits: number,
      status: number,
    } | null,
    ristoQuickStatus?: RistoQuickStatus | null,
    status: OrderStatus,
    lastPaymentsRistoQuick?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    generalVatRate?: number | null,
    deliveryCost?: number | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    orderTableId?: string | null,
  } | null,
};

export type PayOrderMutationVariables = {
  orderID: string,
  payAmount: number,
  useCoin: boolean,
  invoiceUserAttribues?: InvoiceUserAttributes | null,
};

export type PayOrderMutation = {
  payOrder: ( {
      __typename: "PayOrderResponseSuccess",
      orderID?: string | null,
      clientSecret?: string | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type StripeUpdateAccountMutationVariables = {
  data: StripeAccountData,
};

export type StripeUpdateAccountMutation = {
  stripeUpdateAccount: ( {
      __typename: "StripeUpdateAccountResponseSuccess",
      success?: boolean | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type StripeHandlePaymentMethodMutationVariables = {
  type: Crud,
  paymentMethodId: string,
  locationId?: string | null,
  defaultSource?: boolean | null,
};

export type StripeHandlePaymentMethodMutation = {
  stripeHandlePaymentMethod: ( {
      __typename: "StripeHandlePaymentMethodResponseSuccess",
      success?: boolean | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type StripeHandleCancelSubscriptionMutationVariables = {
  type: Crud,
  locationId?: string | null,
};

export type StripeHandleCancelSubscriptionMutation = {
  stripeHandleCancelSubscription: ( {
      __typename: "StripeHandleCancelSubscriptionResponseSuccess",
      success?: boolean | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type StripeChangePlanSubscriptionMutationVariables = {
  subscriptionType: StripeSubscriptionType,
  locationId?: string | null,
};

export type StripeChangePlanSubscriptionMutation = {
  stripeChangePlanSubscription: ( {
      __typename: "StripeChangePlanSubscriptionResponseSuccess",
      success?: boolean | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type TranslateLocationMutationVariables = {
  locationID: string,
  languages: Array< string | null >,
};

export type TranslateLocationMutation = {
  translateLocation: ( {
      __typename: "TranslateLocationResponseSuccess",
      success: boolean,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type StorePresenceKeyMutationVariables = {
  locationID: string,
  presenceKey: string,
};

export type StorePresenceKeyMutation = {
  storePresenceKey: ( {
      __typename: "StorePresenceKeyResponseSuccess",
      userId: string,
      status: string,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type CorporateInviteAssociatedsMutationVariables = {
  corporateID: string,
  userEmails: Array< string >,
};

export type CorporateInviteAssociatedsMutation = {
  corporateInviteAssociateds: ( {
      __typename: "CorporateInviteAssociatedsResponseSuccess",
      success: boolean,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type CreateOrderNotPaidMutationVariables = {
  locationID: string,
  serviceType: OrderType,
  billingInfo: BillingInfoInput,
  orderItems: Array< OrderItemInput >,
  note?: string | null,
};

export type CreateOrderNotPaidMutation = {
  createOrderNotPaid: ( {
      __typename: "CreateOrderNotPaidResponseSuccess",
      orderId: string,
      success: boolean,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type GetCardsQueryVariables = {
};

export type GetCardsQuery = {
  getCards: ( {
      __typename: "GetCardsResponseSuccess",
      userCards?:  Array< {
        __typename: string,
        id: string,
        brand: string,
        last4: string,
        expMonth: string,
        expYear: string,
      } | null > | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type GetJWTQueryVariables = {
};

export type GetJWTQuery = {
  getJWT: ( {
      __typename: "GetJWTResponseSuccess",
      token?: string | null,
      code?: number | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type GetBusinessQueryVariables = {
  id: string,
};

export type GetBusinessQuery = {
  getBusiness?:  {
    __typename: "Business",
    id: string,
    name: string,
    locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    corporate?:  {
      __typename: "ModelCorporateConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessesQueryVariables = {
  id?: string | null,
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBusinessesQuery = {
  listBusinesses?:  {
    __typename: "ModelBusinessConnection",
    items:  Array< {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email: string,
    type: UserType,
    coin?: number | null,
    totalCoin?: number | null,
    stripeCustomerId?: string | null,
    association?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      nextToken?: string | null,
    } | null,
    firstName?: string | null,
    businessName?: string | null,
    taxCode?: string | null,
    sdi?: string | null,
    lastName?: string | null,
    fiscalCode?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    gender?: GenderType | null,
    dob?:  {
      __typename: "DoB",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  id?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailQuery = {
  usersByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAssociatedQueryVariables = {
  id: string,
};

export type GetAssociatedQuery = {
  getAssociated?:  {
    __typename: "Associated",
    id: string,
    userId?: string | null,
    corporateID: string,
    userEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    status: AssociatedStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAssociatedsQueryVariables = {
  id?: string | null,
  filter?: ModelAssociatedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAssociatedsQuery = {
  listAssociateds?:  {
    __typename: "ModelAssociatedConnection",
    items:  Array< {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssociatedByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssociatedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssociatedByUserQuery = {
  associatedByUser?:  {
    __typename: "ModelAssociatedConnection",
    items:  Array< {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssociatedByCorporateQueryVariables = {
  corporateID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssociatedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssociatedByCorporateQuery = {
  associatedByCorporate?:  {
    __typename: "ModelAssociatedConnection",
    items:  Array< {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssociatedByEmailQueryVariables = {
  userEmail: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssociatedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssociatedByEmailQuery = {
  associatedByEmail?:  {
    __typename: "ModelAssociatedConnection",
    items:  Array< {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCorporateQueryVariables = {
  id: string,
};

export type GetCorporateQuery = {
  getCorporate?:  {
    __typename: "Corporate",
    id: string,
    businessCorporateId: string,
    name?: string | null,
    associates?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    associatedLocations?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCorporatesQueryVariables = {
  id?: string | null,
  filter?: ModelCorporateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCorporatesQuery = {
  listCorporates?:  {
    __typename: "ModelCorporateConnection",
    items:  Array< {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CorporateByBusinessQueryVariables = {
  businessCorporateId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCorporateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CorporateByBusinessQuery = {
  corporateByBusiness?:  {
    __typename: "ModelCorporateConnection",
    items:  Array< {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    images?: Array< string | null > | null,
    logo?: string | null,
    bio?: string | null,
    bioTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    gallery?:  Array< {
      __typename: "ImageGallery",
      image: string,
      name: string,
    } | null > | null,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    invoiceInfo?:  {
      __typename: "InvoiceInfo",
      taxCode: string,
      broadcastFormat: string,
      businessName: string,
      taxRegime: string,
    } | null,
    openingDay?:  {
      __typename: "OpeningDay",
    } | null,
    deliverectId: string,
    status: LocationStatus,
    timeZone: string,
    menu?:  {
      __typename: "ModelMenuConnection",
      nextToken?: string | null,
    } | null,
    menuSortOrder?: Array< string | null > | null,
    floors?:  {
      __typename: "ModelFloorConnection",
      nextToken?: string | null,
    } | null,
    credit: number,
    selfOrdering: boolean,
    externalLinks?:  Array< {
      __typename: "ExternaLink",
      link: string,
      label: string,
      icon: string,
    } > | null,
    template: Template,
    color: string,
    showCurrency: boolean,
    showWineList?: boolean | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    phoneNumber?: string | null,
    social?:  Array< {
      __typename: "SocialLink",
      link: string,
      label: string,
      icon: string,
    } | null > | null,
    email?: string | null,
    languageDefault: string,
    languagesEnabled?: Array< string | null > | null,
    translationsInProgress?: boolean | null,
    coverPrice?: number | null,
    deliveryCost?: number | null,
    generalVatRate?: number | null,
    businessLocationsId: string,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    associatedCorporate?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    bankDetails?:  {
      __typename: "BankDetails",
      accountHolder: string,
      iban: string,
      bic: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLocationsQueryVariables = {
  id?: string | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LocationByDeliverectIdQueryVariables = {
  deliverectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LocationByDeliverectIdQuery = {
  locationByDeliverectId?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LocationByBusinessQueryVariables = {
  businessLocationsId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LocationByBusinessQuery = {
  locationByBusiness?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPostQueryVariables = {
  id: string,
};

export type GetPostQuery = {
  getPost?:  {
    __typename: "Post",
    id: string,
    image?: string | null,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    userId: string,
    userName: string,
    postLocationId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPostsQueryVariables = {
  id?: string | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPostsQuery = {
  listPosts?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      image?: string | null,
      name: string,
      userId: string,
      userName: string,
      postLocationId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PostByLocationQueryVariables = {
  postLocationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PostByLocationQuery = {
  postByLocation?:  {
    __typename: "ModelPostConnection",
    items:  Array< {
      __typename: "Post",
      id: string,
      image?: string | null,
      name: string,
      userId: string,
      userName: string,
      postLocationId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCorporateLocationsQueryVariables = {
  id: string,
};

export type GetCorporateLocationsQuery = {
  getCorporateLocations?:  {
    __typename: "CorporateLocations",
    id: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCorporateLocationsQueryVariables = {
  id?: string | null,
  filter?: ModelCorporateLocationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCorporateLocationsQuery = {
  listCorporateLocations?:  {
    __typename: "ModelCorporateLocationsConnection",
    items:  Array< {
      __typename: "CorporateLocations",
      id: string,
      locationID: string,
      corporateID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAssociatedPresenceQueryVariables = {
  id: string,
};

export type GetAssociatedPresenceQuery = {
  getAssociatedPresence?:  {
    __typename: "AssociatedPresence",
    id: string,
    associatedID: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    associated?:  {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAssociatedPresencesQueryVariables = {
  id?: string | null,
  filter?: ModelAssociatedPresenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAssociatedPresencesQuery = {
  listAssociatedPresences?:  {
    __typename: "ModelAssociatedPresenceConnection",
    items:  Array< {
      __typename: "AssociatedPresence",
      id: string,
      associatedID: string,
      locationID: string,
      corporateID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssociatedPresenceByAssociatedQueryVariables = {
  associatedID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssociatedPresenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssociatedPresenceByAssociatedQuery = {
  associatedPresenceByAssociated?:  {
    __typename: "ModelAssociatedPresenceConnection",
    items:  Array< {
      __typename: "AssociatedPresence",
      id: string,
      associatedID: string,
      locationID: string,
      corporateID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssociatedPresenceByLocationQueryVariables = {
  locationID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssociatedPresenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssociatedPresenceByLocationQuery = {
  associatedPresenceByLocation?:  {
    __typename: "ModelAssociatedPresenceConnection",
    items:  Array< {
      __typename: "AssociatedPresence",
      id: string,
      associatedID: string,
      locationID: string,
      corporateID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssociatedPresenceByCorporateQueryVariables = {
  corporateID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssociatedPresenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssociatedPresenceByCorporateQuery = {
  associatedPresenceByCorporate?:  {
    __typename: "ModelAssociatedPresenceConnection",
    items:  Array< {
      __typename: "AssociatedPresence",
      id: string,
      associatedID: string,
      locationID: string,
      corporateID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMenuQueryVariables = {
  id: string,
};

export type GetMenuQuery = {
  getMenu?:  {
    __typename: "Menu",
    id: string,
    businessId: string,
    menuLocationId: string,
    location:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    type?: number | null,
    nestedModifiers: boolean,
    image?: Array< string | null > | null,
    currency?: number | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    onlyAssociated?: boolean | null,
    categorySortOrder?: Array< string | null > | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMenusQueryVariables = {
  id?: string | null,
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMenusQuery = {
  listMenus?:  {
    __typename: "ModelMenuConnection",
    items:  Array< {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MenuByBusinessQueryVariables = {
  businessId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MenuByBusinessQuery = {
  menuByBusiness?:  {
    __typename: "ModelMenuConnection",
    items:  Array< {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MenuByLocationQueryVariables = {
  menuLocationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MenuByLocationQuery = {
  menuByLocation?:  {
    __typename: "ModelMenuConnection",
    items:  Array< {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    businessId: string,
    menuCategoriesId: string,
    locationId: string,
    menu:  {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    posCategoryType?: Array< string | null > | null,
    posCategoryId?: string | null,
    posLocationId?: string | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    level?: number | null,
    sortedChannelProductIds?: Array< string | null > | null,
    subProductSortOrder?: Array< string | null > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    categorySubCategoriesId?: string | null,
  } | null,
};

export type ListCategoriesQueryVariables = {
  id?: string | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoryByBusinessQueryVariables = {
  businessId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByBusinessQuery = {
  categoryByBusiness?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoryByMenuQueryVariables = {
  menuCategoriesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByMenuQuery = {
  categoryByMenu?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoryByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByLocationQuery = {
  categoryByLocation?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    categories?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    locationId: string,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    capacityUsages?: Array< string | null > | null,
    deliveryTax?: number | null,
    eatInTax?: number | null,
    takeawayTax?: number | null,
    max?: number | null,
    min?: number | null,
    isVariant?: boolean | null,
    multiMax?: number | null,
    multiply?: number | null,
    plu: string,
    disabled?: boolean | null,
    snoozeStart?: string | null,
    snoozeEnd?: string | null,
    price: number,
    posCategoryIds?: Array< string | null > | null,
    posProductCategoryId?: string | null,
    posProductId?: string | null,
    productTags?: Array< string | null > | null,
    productType: number,
    isWine?: boolean | null,
    wineInfo?:  {
      __typename: "WineInfo",
      caption?: string | null,
      year?: number | null,
      perfectFor?: Array< number | null > | null,
      typology?: number | null,
      country?: number | null,
      region?: number | null,
      alcoholPercentage?: number | null,
      servingTemperature?: number | null,
      color?: string | null,
      perfume?: string | null,
      taste?: string | null,
      producer?: string | null,
      productionMethod?: number | null,
    } | null,
    priceType?: PriceType | null,
    subProductSortOrder?: Array< string | null > | null,
    subProducts?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductsQueryVariables = {
  id?: string | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByBusinessQueryVariables = {
  businessId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByBusinessQuery = {
  productByBusiness?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByLocationQuery = {
  productByLocation?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByPluQueryVariables = {
  plu: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByPluQuery = {
  productByPlu?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoriesProductsQueryVariables = {
  id: string,
};

export type GetCategoriesProductsQuery = {
  getCategoriesProducts?:  {
    __typename: "CategoriesProducts",
    id: string,
    locationId: string,
    productID: string,
    categoryID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    category?:  {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategoriesProductsQueryVariables = {
  id?: string | null,
  filter?: ModelCategoriesProductsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCategoriesProductsQuery = {
  listCategoriesProducts?:  {
    __typename: "ModelCategoriesProductsConnection",
    items:  Array< {
      __typename: "CategoriesProducts",
      id: string,
      locationId: string,
      productID: string,
      categoryID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesProductsByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoriesProductsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesProductsByLocationQuery = {
  categoriesProductsByLocation?:  {
    __typename: "ModelCategoriesProductsConnection",
    items:  Array< {
      __typename: "CategoriesProducts",
      id: string,
      locationId: string,
      productID: string,
      categoryID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSubProductsQueryVariables = {
  id: string,
};

export type GetSubProductsQuery = {
  getSubProducts?:  {
    __typename: "SubProducts",
    id: string,
    businessId: string,
    locationId: string,
    productId: string,
    product:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    products?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSubProductsQueryVariables = {
  id?: string | null,
  filter?: ModelSubProductsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSubProductsQuery = {
  listSubProducts?:  {
    __typename: "ModelSubProductsConnection",
    items:  Array< {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SubProductByBusinessQueryVariables = {
  businessId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubProductsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SubProductByBusinessQuery = {
  subProductByBusiness?:  {
    __typename: "ModelSubProductsConnection",
    items:  Array< {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SubProductByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubProductsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SubProductByLocationQuery = {
  subProductByLocation?:  {
    __typename: "ModelSubProductsConnection",
    items:  Array< {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductsSubProductsQueryVariables = {
  id: string,
};

export type GetProductsSubProductsQuery = {
  getProductsSubProducts?:  {
    __typename: "ProductsSubProducts",
    id: string,
    locationId: string,
    productID: string,
    subProductsID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subProducts?:  {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductsSubProductsQueryVariables = {
  id?: string | null,
  filter?: ModelProductsSubProductsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductsSubProductsQuery = {
  listProductsSubProducts?:  {
    __typename: "ModelProductsSubProductsConnection",
    items:  Array< {
      __typename: "ProductsSubProducts",
      id: string,
      locationId: string,
      productID: string,
      subProductsID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductsSubProductsByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductsSubProductsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductsSubProductsByLocationQuery = {
  productsSubProductsByLocation?:  {
    __typename: "ModelProductsSubProductsConnection",
    items:  Array< {
      __typename: "ProductsSubProducts",
      id: string,
      locationId: string,
      productID: string,
      subProductsID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFloorQueryVariables = {
  id: string,
};

export type GetFloorQuery = {
  getFloor?:  {
    __typename: "Floor",
    id: string,
    businessId: string,
    locationFloorsId: string,
    name: string,
    tables?:  {
      __typename: "ModelTableConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFloorsQueryVariables = {
  id?: string | null,
  filter?: ModelFloorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFloorsQuery = {
  listFloors?:  {
    __typename: "ModelFloorConnection",
    items:  Array< {
      __typename: "Floor",
      id: string,
      businessId: string,
      locationFloorsId: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FloorByBusinessQueryVariables = {
  businessId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFloorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FloorByBusinessQuery = {
  floorByBusiness?:  {
    __typename: "ModelFloorConnection",
    items:  Array< {
      __typename: "Floor",
      id: string,
      businessId: string,
      locationFloorsId: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FloorByLocationQueryVariables = {
  locationFloorsId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFloorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FloorByLocationQuery = {
  floorByLocation?:  {
    __typename: "ModelFloorConnection",
    items:  Array< {
      __typename: "Floor",
      id: string,
      businessId: string,
      locationFloorsId: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTableQueryVariables = {
  id: string,
};

export type GetTableQuery = {
  getTable?:  {
    __typename: "Table",
    id: string,
    businessId: string,
    locationId: string,
    name: string,
    seats: number,
    activeOrder?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    floorTablesId?: string | null,
    tableActiveOrderId?: string | null,
  } | null,
};

export type ListTablesQueryVariables = {
  id?: string | null,
  filter?: ModelTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTablesQuery = {
  listTables?:  {
    __typename: "ModelTableConnection",
    items:  Array< {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TableByBusinessQueryVariables = {
  businessId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TableByBusinessQuery = {
  tableByBusiness?:  {
    __typename: "ModelTableConnection",
    items:  Array< {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TableByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TableByLocationQuery = {
  tableByLocation?:  {
    __typename: "ModelTableConnection",
    items:  Array< {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderItemQueryVariables = {
  id: string,
};

export type GetOrderItemQuery = {
  getOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    plu: string,
    name: string,
    quantity: number,
    price: number,
    total: number,
    type: number,
    payment?: boolean | null,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    orderItemSubItemsId?: string | null,
    orderOrderItemsId?: string | null,
    orderItemProductId?: string | null,
  } | null,
};

export type ListOrderItemsQueryVariables = {
  id?: string | null,
  filter?: ModelOrderItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrderItemsQuery = {
  listOrderItems?:  {
    __typename: "ModelOrderItemConnection",
    items:  Array< {
      __typename: "OrderItem",
      id: string,
      plu: string,
      name: string,
      quantity: number,
      price: number,
      total: number,
      type: number,
      payment?: boolean | null,
      createdAt: string,
      updatedAt: string,
      orderItemSubItemsId?: string | null,
      orderOrderItemsId?: string | null,
      orderItemProductId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    businessId: string,
    locationId: string,
    table?:  {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null,
    destination?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    note?: string | null,
    billingInfo?:  {
      __typename: "BillingInfo",
      email?: string | null,
      phoneNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      vatNumber?: string | null,
      sdi?: string | null,
    } | null,
    type?: OrderType | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    orderItemsSortOrder?: Array< string | null > | null,
    subTotal: number,
    total: number,
    paid: number,
    deliverectParams?:  {
      __typename: "OrderDeliverectParams",
      id?: string | null,
      type: number,
      isAlreadyPaid: boolean,
      paymentType: number,
      paymentAmount: number,
      decimalDigits: number,
      status: number,
    } | null,
    ristoQuickStatus?: RistoQuickStatus | null,
    status: OrderStatus,
    lastPaymentsRistoQuick?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    generalVatRate?: number | null,
    deliveryCost?: number | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    orderTableId?: string | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  id?: string | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderByBusinessQueryVariables = {
  businessId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByBusinessQuery = {
  orderByBusiness?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderByLocationQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByLocationQuery = {
  orderByLocation?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrderByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByUserQuery = {
  orderByUser?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentQueryVariables = {
  id: string,
};

export type GetPaymentQuery = {
  getPayment?:  {
    __typename: "Payment",
    id: string,
    orderID: string,
    orderIDRistoQuick?: string | null,
    status: PaymentStatus,
    userID?: string | null,
    useCoin: boolean,
    userEmail: string,
    customerName?: string | null,
    paymentID: string,
    paymentAmount: number,
    invoiceID?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPaymentsQueryVariables = {
  id?: string | null,
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPaymentsQuery = {
  listPayments?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      orderID: string,
      orderIDRistoQuick?: string | null,
      status: PaymentStatus,
      userID?: string | null,
      useCoin: boolean,
      userEmail: string,
      customerName?: string | null,
      paymentID: string,
      paymentAmount: number,
      invoiceID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentByInvoiceQueryVariables = {
  invoiceID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentByInvoiceQuery = {
  paymentByInvoice?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      orderID: string,
      orderIDRistoQuick?: string | null,
      status: PaymentStatus,
      userID?: string | null,
      useCoin: boolean,
      userEmail: string,
      customerName?: string | null,
      paymentID: string,
      paymentAmount: number,
      invoiceID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIntegrationGeneralQueryVariables = {
  id: string,
};

export type GetIntegrationGeneralQuery = {
  getIntegrationGeneral?:  {
    __typename: "IntegrationGeneral",
    id: string,
    latestVersion: string,
    latestLink: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIntegrationGeneralsQueryVariables = {
  id?: string | null,
  filter?: ModelIntegrationGeneralFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIntegrationGeneralsQuery = {
  listIntegrationGenerals?:  {
    __typename: "ModelIntegrationGeneralConnection",
    items:  Array< {
      __typename: "IntegrationGeneral",
      id: string,
      latestVersion: string,
      latestLink: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIntegrationQueryVariables = {
  id: string,
};

export type GetIntegrationQuery = {
  getIntegration?:  {
    __typename: "Integration",
    id: string,
    run: boolean,
    integrationGeneralId: string,
    integrationGeneral?:  {
      __typename: "IntegrationGeneral",
      id: string,
      latestVersion: string,
      latestLink: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIntegrationsQueryVariables = {
  id?: string | null,
  filter?: ModelIntegrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIntegrationsQuery = {
  listIntegrations?:  {
    __typename: "ModelIntegrationConnection",
    items:  Array< {
      __typename: "Integration",
      id: string,
      run: boolean,
      integrationGeneralId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IntegrationbyIntegrationGeneralIdQueryVariables = {
  integrationGeneralId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIntegrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IntegrationbyIntegrationGeneralIdQuery = {
  IntegrationbyIntegrationGeneralId?:  {
    __typename: "ModelIntegrationConnection",
    items:  Array< {
      __typename: "Integration",
      id: string,
      run: boolean,
      integrationGeneralId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserPaymentMethodsQueryVariables = {
  locationId?: string | null,
};

export type GetUserPaymentMethodsQuery = {
  getUserPaymentMethods: ( {
      __typename: "GetUserPaymentMethodsResponseSuccess",
      userPaymentMethods?:  Array< {
        __typename: string,
        id: string,
        brand: string,
        last4: string,
        default_source: boolean,
      } | null > | null,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type GetLocationSubscriptionQueryVariables = {
  locationId?: string | null,
};

export type GetLocationSubscriptionQuery = {
  getLocationSubscription: ( {
      __typename: "GetLocationSubscriptionResponseSuccess",
      paymentMethods?:  Array< {
        __typename: string,
        id: string,
        brand: string,
        last4: string,
        default_source: boolean,
      } | null > | null,
      subscription:  {
        __typename: string,
        id: string,
        current_period_end: number,
        schedule: boolean,
      },
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type GetPresenceKeyQueryVariables = {
};

export type GetPresenceKeyQuery = {
  getPresenceKey: ( {
      __typename: "GetPresenceKeyResponseSuccess",
      presenceKey: string,
      valid: boolean,
    } | {
      __typename: "ErrorResponse",
      error?: string | null,
      code?: number | null,
    }
  ),
};

export type OnCreateBusinessSubscriptionVariables = {
  filter?: ModelSubscriptionBusinessFilterInput | null,
};

export type OnCreateBusinessSubscription = {
  onCreateBusiness?:  {
    __typename: "Business",
    id: string,
    name: string,
    locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    corporate?:  {
      __typename: "ModelCorporateConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessSubscriptionVariables = {
  filter?: ModelSubscriptionBusinessFilterInput | null,
};

export type OnUpdateBusinessSubscription = {
  onUpdateBusiness?:  {
    __typename: "Business",
    id: string,
    name: string,
    locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    corporate?:  {
      __typename: "ModelCorporateConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessSubscriptionVariables = {
  filter?: ModelSubscriptionBusinessFilterInput | null,
};

export type OnDeleteBusinessSubscription = {
  onDeleteBusiness?:  {
    __typename: "Business",
    id: string,
    name: string,
    locations?:  {
      __typename: "ModelLocationConnection",
      nextToken?: string | null,
    } | null,
    corporate?:  {
      __typename: "ModelCorporateConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    type: UserType,
    coin?: number | null,
    totalCoin?: number | null,
    stripeCustomerId?: string | null,
    association?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      nextToken?: string | null,
    } | null,
    firstName?: string | null,
    businessName?: string | null,
    taxCode?: string | null,
    sdi?: string | null,
    lastName?: string | null,
    fiscalCode?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    gender?: GenderType | null,
    dob?:  {
      __typename: "DoB",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    type: UserType,
    coin?: number | null,
    totalCoin?: number | null,
    stripeCustomerId?: string | null,
    association?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      nextToken?: string | null,
    } | null,
    firstName?: string | null,
    businessName?: string | null,
    taxCode?: string | null,
    sdi?: string | null,
    lastName?: string | null,
    fiscalCode?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    gender?: GenderType | null,
    dob?:  {
      __typename: "DoB",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    type: UserType,
    coin?: number | null,
    totalCoin?: number | null,
    stripeCustomerId?: string | null,
    association?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      nextToken?: string | null,
    } | null,
    firstName?: string | null,
    businessName?: string | null,
    taxCode?: string | null,
    sdi?: string | null,
    lastName?: string | null,
    fiscalCode?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    gender?: GenderType | null,
    dob?:  {
      __typename: "DoB",
      day?: number | null,
      month?: number | null,
      year?: number | null,
    } | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAssociatedSubscriptionVariables = {
  filter?: ModelSubscriptionAssociatedFilterInput | null,
  userId?: string | null,
};

export type OnCreateAssociatedSubscription = {
  onCreateAssociated?:  {
    __typename: "Associated",
    id: string,
    userId?: string | null,
    corporateID: string,
    userEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    status: AssociatedStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAssociatedSubscriptionVariables = {
  filter?: ModelSubscriptionAssociatedFilterInput | null,
  userId?: string | null,
};

export type OnUpdateAssociatedSubscription = {
  onUpdateAssociated?:  {
    __typename: "Associated",
    id: string,
    userId?: string | null,
    corporateID: string,
    userEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    status: AssociatedStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAssociatedSubscriptionVariables = {
  filter?: ModelSubscriptionAssociatedFilterInput | null,
  userId?: string | null,
};

export type OnDeleteAssociatedSubscription = {
  onDeleteAssociated?:  {
    __typename: "Associated",
    id: string,
    userId?: string | null,
    corporateID: string,
    userEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    status: AssociatedStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCorporateSubscriptionVariables = {
  filter?: ModelSubscriptionCorporateFilterInput | null,
};

export type OnCreateCorporateSubscription = {
  onCreateCorporate?:  {
    __typename: "Corporate",
    id: string,
    businessCorporateId: string,
    name?: string | null,
    associates?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    associatedLocations?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCorporateSubscriptionVariables = {
  filter?: ModelSubscriptionCorporateFilterInput | null,
};

export type OnUpdateCorporateSubscription = {
  onUpdateCorporate?:  {
    __typename: "Corporate",
    id: string,
    businessCorporateId: string,
    name?: string | null,
    associates?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    associatedLocations?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCorporateSubscriptionVariables = {
  filter?: ModelSubscriptionCorporateFilterInput | null,
};

export type OnDeleteCorporateSubscription = {
  onDeleteCorporate?:  {
    __typename: "Corporate",
    id: string,
    businessCorporateId: string,
    name?: string | null,
    associates?:  {
      __typename: "ModelAssociatedConnection",
      nextToken?: string | null,
    } | null,
    associatedLocations?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    images?: Array< string | null > | null,
    logo?: string | null,
    bio?: string | null,
    bioTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    gallery?:  Array< {
      __typename: "ImageGallery",
      image: string,
      name: string,
    } | null > | null,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    invoiceInfo?:  {
      __typename: "InvoiceInfo",
      taxCode: string,
      broadcastFormat: string,
      businessName: string,
      taxRegime: string,
    } | null,
    openingDay?:  {
      __typename: "OpeningDay",
    } | null,
    deliverectId: string,
    status: LocationStatus,
    timeZone: string,
    menu?:  {
      __typename: "ModelMenuConnection",
      nextToken?: string | null,
    } | null,
    menuSortOrder?: Array< string | null > | null,
    floors?:  {
      __typename: "ModelFloorConnection",
      nextToken?: string | null,
    } | null,
    credit: number,
    selfOrdering: boolean,
    externalLinks?:  Array< {
      __typename: "ExternaLink",
      link: string,
      label: string,
      icon: string,
    } > | null,
    template: Template,
    color: string,
    showCurrency: boolean,
    showWineList?: boolean | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    phoneNumber?: string | null,
    social?:  Array< {
      __typename: "SocialLink",
      link: string,
      label: string,
      icon: string,
    } | null > | null,
    email?: string | null,
    languageDefault: string,
    languagesEnabled?: Array< string | null > | null,
    translationsInProgress?: boolean | null,
    coverPrice?: number | null,
    deliveryCost?: number | null,
    generalVatRate?: number | null,
    businessLocationsId: string,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    associatedCorporate?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    bankDetails?:  {
      __typename: "BankDetails",
      accountHolder: string,
      iban: string,
      bic: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    images?: Array< string | null > | null,
    logo?: string | null,
    bio?: string | null,
    bioTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    gallery?:  Array< {
      __typename: "ImageGallery",
      image: string,
      name: string,
    } | null > | null,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    invoiceInfo?:  {
      __typename: "InvoiceInfo",
      taxCode: string,
      broadcastFormat: string,
      businessName: string,
      taxRegime: string,
    } | null,
    openingDay?:  {
      __typename: "OpeningDay",
    } | null,
    deliverectId: string,
    status: LocationStatus,
    timeZone: string,
    menu?:  {
      __typename: "ModelMenuConnection",
      nextToken?: string | null,
    } | null,
    menuSortOrder?: Array< string | null > | null,
    floors?:  {
      __typename: "ModelFloorConnection",
      nextToken?: string | null,
    } | null,
    credit: number,
    selfOrdering: boolean,
    externalLinks?:  Array< {
      __typename: "ExternaLink",
      link: string,
      label: string,
      icon: string,
    } > | null,
    template: Template,
    color: string,
    showCurrency: boolean,
    showWineList?: boolean | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    phoneNumber?: string | null,
    social?:  Array< {
      __typename: "SocialLink",
      link: string,
      label: string,
      icon: string,
    } | null > | null,
    email?: string | null,
    languageDefault: string,
    languagesEnabled?: Array< string | null > | null,
    translationsInProgress?: boolean | null,
    coverPrice?: number | null,
    deliveryCost?: number | null,
    generalVatRate?: number | null,
    businessLocationsId: string,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    associatedCorporate?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    bankDetails?:  {
      __typename: "BankDetails",
      accountHolder: string,
      iban: string,
      bic: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    images?: Array< string | null > | null,
    logo?: string | null,
    bio?: string | null,
    bioTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    gallery?:  Array< {
      __typename: "ImageGallery",
      image: string,
      name: string,
    } | null > | null,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    invoiceInfo?:  {
      __typename: "InvoiceInfo",
      taxCode: string,
      broadcastFormat: string,
      businessName: string,
      taxRegime: string,
    } | null,
    openingDay?:  {
      __typename: "OpeningDay",
    } | null,
    deliverectId: string,
    status: LocationStatus,
    timeZone: string,
    menu?:  {
      __typename: "ModelMenuConnection",
      nextToken?: string | null,
    } | null,
    menuSortOrder?: Array< string | null > | null,
    floors?:  {
      __typename: "ModelFloorConnection",
      nextToken?: string | null,
    } | null,
    credit: number,
    selfOrdering: boolean,
    externalLinks?:  Array< {
      __typename: "ExternaLink",
      link: string,
      label: string,
      icon: string,
    } > | null,
    template: Template,
    color: string,
    showCurrency: boolean,
    showWineList?: boolean | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    phoneNumber?: string | null,
    social?:  Array< {
      __typename: "SocialLink",
      link: string,
      label: string,
      icon: string,
    } | null > | null,
    email?: string | null,
    languageDefault: string,
    languagesEnabled?: Array< string | null > | null,
    translationsInProgress?: boolean | null,
    coverPrice?: number | null,
    deliveryCost?: number | null,
    generalVatRate?: number | null,
    businessLocationsId: string,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    associatedCorporate?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    bankDetails?:  {
      __typename: "BankDetails",
      accountHolder: string,
      iban: string,
      bic: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnCreatePostSubscription = {
  onCreatePost?:  {
    __typename: "Post",
    id: string,
    image?: string | null,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    userId: string,
    userName: string,
    postLocationId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnUpdatePostSubscription = {
  onUpdatePost?:  {
    __typename: "Post",
    id: string,
    image?: string | null,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    userId: string,
    userName: string,
    postLocationId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePostSubscriptionVariables = {
  filter?: ModelSubscriptionPostFilterInput | null,
};

export type OnDeletePostSubscription = {
  onDeletePost?:  {
    __typename: "Post",
    id: string,
    image?: string | null,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    userId: string,
    userName: string,
    postLocationId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCorporateLocationsSubscriptionVariables = {
  filter?: ModelSubscriptionCorporateLocationsFilterInput | null,
};

export type OnCreateCorporateLocationsSubscription = {
  onCreateCorporateLocations?:  {
    __typename: "CorporateLocations",
    id: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCorporateLocationsSubscriptionVariables = {
  filter?: ModelSubscriptionCorporateLocationsFilterInput | null,
};

export type OnUpdateCorporateLocationsSubscription = {
  onUpdateCorporateLocations?:  {
    __typename: "CorporateLocations",
    id: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCorporateLocationsSubscriptionVariables = {
  filter?: ModelSubscriptionCorporateLocationsFilterInput | null,
};

export type OnDeleteCorporateLocationsSubscription = {
  onDeleteCorporateLocations?:  {
    __typename: "CorporateLocations",
    id: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAssociatedPresenceSubscriptionVariables = {
  filter?: ModelSubscriptionAssociatedPresenceFilterInput | null,
};

export type OnCreateAssociatedPresenceSubscription = {
  onCreateAssociatedPresence?:  {
    __typename: "AssociatedPresence",
    id: string,
    associatedID: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    associated?:  {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAssociatedPresenceSubscriptionVariables = {
  filter?: ModelSubscriptionAssociatedPresenceFilterInput | null,
};

export type OnUpdateAssociatedPresenceSubscription = {
  onUpdateAssociatedPresence?:  {
    __typename: "AssociatedPresence",
    id: string,
    associatedID: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    associated?:  {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAssociatedPresenceSubscriptionVariables = {
  filter?: ModelSubscriptionAssociatedPresenceFilterInput | null,
};

export type OnDeleteAssociatedPresenceSubscription = {
  onDeleteAssociatedPresence?:  {
    __typename: "AssociatedPresence",
    id: string,
    associatedID: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    associated?:  {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMenuSubscriptionVariables = {
  filter?: ModelSubscriptionMenuFilterInput | null,
};

export type OnCreateMenuSubscription = {
  onCreateMenu?:  {
    __typename: "Menu",
    id: string,
    businessId: string,
    menuLocationId: string,
    location:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    type?: number | null,
    nestedModifiers: boolean,
    image?: Array< string | null > | null,
    currency?: number | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    onlyAssociated?: boolean | null,
    categorySortOrder?: Array< string | null > | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMenuSubscriptionVariables = {
  filter?: ModelSubscriptionMenuFilterInput | null,
};

export type OnUpdateMenuSubscription = {
  onUpdateMenu?:  {
    __typename: "Menu",
    id: string,
    businessId: string,
    menuLocationId: string,
    location:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    type?: number | null,
    nestedModifiers: boolean,
    image?: Array< string | null > | null,
    currency?: number | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    onlyAssociated?: boolean | null,
    categorySortOrder?: Array< string | null > | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMenuSubscriptionVariables = {
  filter?: ModelSubscriptionMenuFilterInput | null,
};

export type OnDeleteMenuSubscription = {
  onDeleteMenu?:  {
    __typename: "Menu",
    id: string,
    businessId: string,
    menuLocationId: string,
    location:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    type?: number | null,
    nestedModifiers: boolean,
    image?: Array< string | null > | null,
    currency?: number | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    onlyAssociated?: boolean | null,
    categorySortOrder?: Array< string | null > | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    businessId: string,
    menuCategoriesId: string,
    locationId: string,
    menu:  {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    posCategoryType?: Array< string | null > | null,
    posCategoryId?: string | null,
    posLocationId?: string | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    level?: number | null,
    sortedChannelProductIds?: Array< string | null > | null,
    subProductSortOrder?: Array< string | null > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    categorySubCategoriesId?: string | null,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    businessId: string,
    menuCategoriesId: string,
    locationId: string,
    menu:  {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    posCategoryType?: Array< string | null > | null,
    posCategoryId?: string | null,
    posLocationId?: string | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    level?: number | null,
    sortedChannelProductIds?: Array< string | null > | null,
    subProductSortOrder?: Array< string | null > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    categorySubCategoriesId?: string | null,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    businessId: string,
    menuCategoriesId: string,
    locationId: string,
    menu:  {
      __typename: "Menu",
      id: string,
      businessId: string,
      menuLocationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      type?: number | null,
      nestedModifiers: boolean,
      image?: Array< string | null > | null,
      currency?: number | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      onlyAssociated?: boolean | null,
      categorySortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    posCategoryType?: Array< string | null > | null,
    posCategoryId?: string | null,
    posLocationId?: string | null,
    availabilities?:  Array< {
      __typename: "Availability",
      dayOfWeek?: number | null,
      startTime?: string | null,
      endTime?: string | null,
    } > | null,
    level?: number | null,
    sortedChannelProductIds?: Array< string | null > | null,
    subProductSortOrder?: Array< string | null > | null,
    disabled?: boolean | null,
    wineList?: boolean | null,
    subCategories?:  {
      __typename: "ModelCategoryConnection",
      nextToken?: string | null,
    } | null,
    products?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    categorySubCategoriesId?: string | null,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    categories?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    locationId: string,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    capacityUsages?: Array< string | null > | null,
    deliveryTax?: number | null,
    eatInTax?: number | null,
    takeawayTax?: number | null,
    max?: number | null,
    min?: number | null,
    isVariant?: boolean | null,
    multiMax?: number | null,
    multiply?: number | null,
    plu: string,
    disabled?: boolean | null,
    snoozeStart?: string | null,
    snoozeEnd?: string | null,
    price: number,
    posCategoryIds?: Array< string | null > | null,
    posProductCategoryId?: string | null,
    posProductId?: string | null,
    productTags?: Array< string | null > | null,
    productType: number,
    isWine?: boolean | null,
    wineInfo?:  {
      __typename: "WineInfo",
      caption?: string | null,
      year?: number | null,
      perfectFor?: Array< number | null > | null,
      typology?: number | null,
      country?: number | null,
      region?: number | null,
      alcoholPercentage?: number | null,
      servingTemperature?: number | null,
      color?: string | null,
      perfume?: string | null,
      taste?: string | null,
      producer?: string | null,
      productionMethod?: number | null,
    } | null,
    priceType?: PriceType | null,
    subProductSortOrder?: Array< string | null > | null,
    subProducts?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    categories?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    locationId: string,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    capacityUsages?: Array< string | null > | null,
    deliveryTax?: number | null,
    eatInTax?: number | null,
    takeawayTax?: number | null,
    max?: number | null,
    min?: number | null,
    isVariant?: boolean | null,
    multiMax?: number | null,
    multiply?: number | null,
    plu: string,
    disabled?: boolean | null,
    snoozeStart?: string | null,
    snoozeEnd?: string | null,
    price: number,
    posCategoryIds?: Array< string | null > | null,
    posProductCategoryId?: string | null,
    posProductId?: string | null,
    productTags?: Array< string | null > | null,
    productType: number,
    isWine?: boolean | null,
    wineInfo?:  {
      __typename: "WineInfo",
      caption?: string | null,
      year?: number | null,
      perfectFor?: Array< number | null > | null,
      typology?: number | null,
      country?: number | null,
      region?: number | null,
      alcoholPercentage?: number | null,
      servingTemperature?: number | null,
      color?: string | null,
      perfume?: string | null,
      taste?: string | null,
      producer?: string | null,
      productionMethod?: number | null,
    } | null,
    priceType?: PriceType | null,
    subProductSortOrder?: Array< string | null > | null,
    subProducts?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    businessId: string,
    categories?:  {
      __typename: "ModelCategoriesProductsConnection",
      nextToken?: string | null,
    } | null,
    locationId: string,
    name: string,
    nameTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    dense?: boolean | null,
    image?: Array< string | null > | null,
    capacityUsages?: Array< string | null > | null,
    deliveryTax?: number | null,
    eatInTax?: number | null,
    takeawayTax?: number | null,
    max?: number | null,
    min?: number | null,
    isVariant?: boolean | null,
    multiMax?: number | null,
    multiply?: number | null,
    plu: string,
    disabled?: boolean | null,
    snoozeStart?: string | null,
    snoozeEnd?: string | null,
    price: number,
    posCategoryIds?: Array< string | null > | null,
    posProductCategoryId?: string | null,
    posProductId?: string | null,
    productTags?: Array< string | null > | null,
    productType: number,
    isWine?: boolean | null,
    wineInfo?:  {
      __typename: "WineInfo",
      caption?: string | null,
      year?: number | null,
      perfectFor?: Array< number | null > | null,
      typology?: number | null,
      country?: number | null,
      region?: number | null,
      alcoholPercentage?: number | null,
      servingTemperature?: number | null,
      color?: string | null,
      perfume?: string | null,
      taste?: string | null,
      producer?: string | null,
      productionMethod?: number | null,
    } | null,
    priceType?: PriceType | null,
    subProductSortOrder?: Array< string | null > | null,
    subProducts?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCategoriesProductsSubscriptionVariables = {
  filter?: ModelSubscriptionCategoriesProductsFilterInput | null,
};

export type OnCreateCategoriesProductsSubscription = {
  onCreateCategoriesProducts?:  {
    __typename: "CategoriesProducts",
    id: string,
    locationId: string,
    productID: string,
    categoryID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    category?:  {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCategoriesProductsSubscriptionVariables = {
  filter?: ModelSubscriptionCategoriesProductsFilterInput | null,
};

export type OnUpdateCategoriesProductsSubscription = {
  onUpdateCategoriesProducts?:  {
    __typename: "CategoriesProducts",
    id: string,
    locationId: string,
    productID: string,
    categoryID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    category?:  {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCategoriesProductsSubscriptionVariables = {
  filter?: ModelSubscriptionCategoriesProductsFilterInput | null,
};

export type OnDeleteCategoriesProductsSubscription = {
  onDeleteCategoriesProducts?:  {
    __typename: "CategoriesProducts",
    id: string,
    locationId: string,
    productID: string,
    categoryID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    category?:  {
      __typename: "Category",
      id: string,
      businessId: string,
      menuCategoriesId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      posCategoryType?: Array< string | null > | null,
      posCategoryId?: string | null,
      posLocationId?: string | null,
      level?: number | null,
      sortedChannelProductIds?: Array< string | null > | null,
      subProductSortOrder?: Array< string | null > | null,
      disabled?: boolean | null,
      wineList?: boolean | null,
      createdAt: string,
      updatedAt: string,
      categorySubCategoriesId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSubProductsSubscriptionVariables = {
  filter?: ModelSubscriptionSubProductsFilterInput | null,
};

export type OnCreateSubProductsSubscription = {
  onCreateSubProducts?:  {
    __typename: "SubProducts",
    id: string,
    businessId: string,
    locationId: string,
    productId: string,
    product:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    products?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSubProductsSubscriptionVariables = {
  filter?: ModelSubscriptionSubProductsFilterInput | null,
};

export type OnUpdateSubProductsSubscription = {
  onUpdateSubProducts?:  {
    __typename: "SubProducts",
    id: string,
    businessId: string,
    locationId: string,
    productId: string,
    product:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    products?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSubProductsSubscriptionVariables = {
  filter?: ModelSubscriptionSubProductsFilterInput | null,
};

export type OnDeleteSubProductsSubscription = {
  onDeleteSubProducts?:  {
    __typename: "SubProducts",
    id: string,
    businessId: string,
    locationId: string,
    productId: string,
    product:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    },
    products?:  {
      __typename: "ModelProductsSubProductsConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductsSubProductsSubscriptionVariables = {
  filter?: ModelSubscriptionProductsSubProductsFilterInput | null,
};

export type OnCreateProductsSubProductsSubscription = {
  onCreateProductsSubProducts?:  {
    __typename: "ProductsSubProducts",
    id: string,
    locationId: string,
    productID: string,
    subProductsID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subProducts?:  {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductsSubProductsSubscriptionVariables = {
  filter?: ModelSubscriptionProductsSubProductsFilterInput | null,
};

export type OnUpdateProductsSubProductsSubscription = {
  onUpdateProductsSubProducts?:  {
    __typename: "ProductsSubProducts",
    id: string,
    locationId: string,
    productID: string,
    subProductsID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subProducts?:  {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductsSubProductsSubscriptionVariables = {
  filter?: ModelSubscriptionProductsSubProductsFilterInput | null,
};

export type OnDeleteProductsSubProductsSubscription = {
  onDeleteProductsSubProducts?:  {
    __typename: "ProductsSubProducts",
    id: string,
    locationId: string,
    productID: string,
    subProductsID: string,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subProducts?:  {
      __typename: "SubProducts",
      id: string,
      businessId: string,
      locationId: string,
      productId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFloorSubscriptionVariables = {
  filter?: ModelSubscriptionFloorFilterInput | null,
};

export type OnCreateFloorSubscription = {
  onCreateFloor?:  {
    __typename: "Floor",
    id: string,
    businessId: string,
    locationFloorsId: string,
    name: string,
    tables?:  {
      __typename: "ModelTableConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFloorSubscriptionVariables = {
  filter?: ModelSubscriptionFloorFilterInput | null,
};

export type OnUpdateFloorSubscription = {
  onUpdateFloor?:  {
    __typename: "Floor",
    id: string,
    businessId: string,
    locationFloorsId: string,
    name: string,
    tables?:  {
      __typename: "ModelTableConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFloorSubscriptionVariables = {
  filter?: ModelSubscriptionFloorFilterInput | null,
};

export type OnDeleteFloorSubscription = {
  onDeleteFloor?:  {
    __typename: "Floor",
    id: string,
    businessId: string,
    locationFloorsId: string,
    name: string,
    tables?:  {
      __typename: "ModelTableConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTableSubscriptionVariables = {
  filter?: ModelSubscriptionTableFilterInput | null,
};

export type OnCreateTableSubscription = {
  onCreateTable?:  {
    __typename: "Table",
    id: string,
    businessId: string,
    locationId: string,
    name: string,
    seats: number,
    activeOrder?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    floorTablesId?: string | null,
    tableActiveOrderId?: string | null,
  } | null,
};

export type OnUpdateTableSubscriptionVariables = {
  filter?: ModelSubscriptionTableFilterInput | null,
};

export type OnUpdateTableSubscription = {
  onUpdateTable?:  {
    __typename: "Table",
    id: string,
    businessId: string,
    locationId: string,
    name: string,
    seats: number,
    activeOrder?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    floorTablesId?: string | null,
    tableActiveOrderId?: string | null,
  } | null,
};

export type OnDeleteTableSubscriptionVariables = {
  filter?: ModelSubscriptionTableFilterInput | null,
};

export type OnDeleteTableSubscription = {
  onDeleteTable?:  {
    __typename: "Table",
    id: string,
    businessId: string,
    locationId: string,
    name: string,
    seats: number,
    activeOrder?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    floorTablesId?: string | null,
    tableActiveOrderId?: string | null,
  } | null,
};

export type OnCreateOrderItemSubscriptionVariables = {
  filter?: ModelSubscriptionOrderItemFilterInput | null,
};

export type OnCreateOrderItemSubscription = {
  onCreateOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    plu: string,
    name: string,
    quantity: number,
    price: number,
    total: number,
    type: number,
    payment?: boolean | null,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    orderItemSubItemsId?: string | null,
    orderOrderItemsId?: string | null,
    orderItemProductId?: string | null,
  } | null,
};

export type OnUpdateOrderItemSubscriptionVariables = {
  filter?: ModelSubscriptionOrderItemFilterInput | null,
};

export type OnUpdateOrderItemSubscription = {
  onUpdateOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    plu: string,
    name: string,
    quantity: number,
    price: number,
    total: number,
    type: number,
    payment?: boolean | null,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    orderItemSubItemsId?: string | null,
    orderOrderItemsId?: string | null,
    orderItemProductId?: string | null,
  } | null,
};

export type OnDeleteOrderItemSubscriptionVariables = {
  filter?: ModelSubscriptionOrderItemFilterInput | null,
};

export type OnDeleteOrderItemSubscription = {
  onDeleteOrderItem?:  {
    __typename: "OrderItem",
    id: string,
    plu: string,
    name: string,
    quantity: number,
    price: number,
    total: number,
    type: number,
    payment?: boolean | null,
    product?:  {
      __typename: "Product",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      description?: string | null,
      dense?: boolean | null,
      image?: Array< string | null > | null,
      capacityUsages?: Array< string | null > | null,
      deliveryTax?: number | null,
      eatInTax?: number | null,
      takeawayTax?: number | null,
      max?: number | null,
      min?: number | null,
      isVariant?: boolean | null,
      multiMax?: number | null,
      multiply?: number | null,
      plu: string,
      disabled?: boolean | null,
      snoozeStart?: string | null,
      snoozeEnd?: string | null,
      price: number,
      posCategoryIds?: Array< string | null > | null,
      posProductCategoryId?: string | null,
      posProductId?: string | null,
      productTags?: Array< string | null > | null,
      productType: number,
      isWine?: boolean | null,
      priceType?: PriceType | null,
      subProductSortOrder?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    subItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    orderItemSubItemsId?: string | null,
    orderOrderItemsId?: string | null,
    orderItemProductId?: string | null,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    businessId: string,
    locationId: string,
    table?:  {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null,
    destination?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    note?: string | null,
    billingInfo?:  {
      __typename: "BillingInfo",
      email?: string | null,
      phoneNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      vatNumber?: string | null,
      sdi?: string | null,
    } | null,
    type?: OrderType | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    orderItemsSortOrder?: Array< string | null > | null,
    subTotal: number,
    total: number,
    paid: number,
    deliverectParams?:  {
      __typename: "OrderDeliverectParams",
      id?: string | null,
      type: number,
      isAlreadyPaid: boolean,
      paymentType: number,
      paymentAmount: number,
      decimalDigits: number,
      status: number,
    } | null,
    ristoQuickStatus?: RistoQuickStatus | null,
    status: OrderStatus,
    lastPaymentsRistoQuick?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    generalVatRate?: number | null,
    deliveryCost?: number | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    orderTableId?: string | null,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    businessId: string,
    locationId: string,
    table?:  {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null,
    destination?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    note?: string | null,
    billingInfo?:  {
      __typename: "BillingInfo",
      email?: string | null,
      phoneNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      vatNumber?: string | null,
      sdi?: string | null,
    } | null,
    type?: OrderType | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    orderItemsSortOrder?: Array< string | null > | null,
    subTotal: number,
    total: number,
    paid: number,
    deliverectParams?:  {
      __typename: "OrderDeliverectParams",
      id?: string | null,
      type: number,
      isAlreadyPaid: boolean,
      paymentType: number,
      paymentAmount: number,
      decimalDigits: number,
      status: number,
    } | null,
    ristoQuickStatus?: RistoQuickStatus | null,
    status: OrderStatus,
    lastPaymentsRistoQuick?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    generalVatRate?: number | null,
    deliveryCost?: number | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    orderTableId?: string | null,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    businessId: string,
    locationId: string,
    table?:  {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null,
    destination?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    note?: string | null,
    billingInfo?:  {
      __typename: "BillingInfo",
      email?: string | null,
      phoneNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      vatNumber?: string | null,
      sdi?: string | null,
    } | null,
    type?: OrderType | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    orderItemsSortOrder?: Array< string | null > | null,
    subTotal: number,
    total: number,
    paid: number,
    deliverectParams?:  {
      __typename: "OrderDeliverectParams",
      id?: string | null,
      type: number,
      isAlreadyPaid: boolean,
      paymentType: number,
      paymentAmount: number,
      decimalDigits: number,
      status: number,
    } | null,
    ristoQuickStatus?: RistoQuickStatus | null,
    status: OrderStatus,
    lastPaymentsRistoQuick?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    generalVatRate?: number | null,
    deliveryCost?: number | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    orderTableId?: string | null,
  } | null,
};

export type OnCreatePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentFilterInput | null,
};

export type OnCreatePaymentSubscription = {
  onCreatePayment?:  {
    __typename: "Payment",
    id: string,
    orderID: string,
    orderIDRistoQuick?: string | null,
    status: PaymentStatus,
    userID?: string | null,
    useCoin: boolean,
    userEmail: string,
    customerName?: string | null,
    paymentID: string,
    paymentAmount: number,
    invoiceID?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentFilterInput | null,
};

export type OnUpdatePaymentSubscription = {
  onUpdatePayment?:  {
    __typename: "Payment",
    id: string,
    orderID: string,
    orderIDRistoQuick?: string | null,
    status: PaymentStatus,
    userID?: string | null,
    useCoin: boolean,
    userEmail: string,
    customerName?: string | null,
    paymentID: string,
    paymentAmount: number,
    invoiceID?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePaymentSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentFilterInput | null,
};

export type OnDeletePaymentSubscription = {
  onDeletePayment?:  {
    __typename: "Payment",
    id: string,
    orderID: string,
    orderIDRistoQuick?: string | null,
    status: PaymentStatus,
    userID?: string | null,
    useCoin: boolean,
    userEmail: string,
    customerName?: string | null,
    paymentID: string,
    paymentAmount: number,
    invoiceID?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      businessId: string,
      locationId: string,
      note?: string | null,
      type?: OrderType | null,
      orderItemsSortOrder?: Array< string | null > | null,
      subTotal: number,
      total: number,
      paid: number,
      ristoQuickStatus?: RistoQuickStatus | null,
      status: OrderStatus,
      userId?: string | null,
      generalVatRate?: number | null,
      deliveryCost?: number | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      orderTableId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIntegrationGeneralSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationGeneralFilterInput | null,
};

export type OnCreateIntegrationGeneralSubscription = {
  onCreateIntegrationGeneral?:  {
    __typename: "IntegrationGeneral",
    id: string,
    latestVersion: string,
    latestLink: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIntegrationGeneralSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationGeneralFilterInput | null,
};

export type OnUpdateIntegrationGeneralSubscription = {
  onUpdateIntegrationGeneral?:  {
    __typename: "IntegrationGeneral",
    id: string,
    latestVersion: string,
    latestLink: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIntegrationGeneralSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationGeneralFilterInput | null,
};

export type OnDeleteIntegrationGeneralSubscription = {
  onDeleteIntegrationGeneral?:  {
    __typename: "IntegrationGeneral",
    id: string,
    latestVersion: string,
    latestLink: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIntegrationSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationFilterInput | null,
};

export type OnCreateIntegrationSubscription = {
  onCreateIntegration?:  {
    __typename: "Integration",
    id: string,
    run: boolean,
    integrationGeneralId: string,
    integrationGeneral?:  {
      __typename: "IntegrationGeneral",
      id: string,
      latestVersion: string,
      latestLink: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIntegrationSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationFilterInput | null,
};

export type OnUpdateIntegrationSubscription = {
  onUpdateIntegration?:  {
    __typename: "Integration",
    id: string,
    run: boolean,
    integrationGeneralId: string,
    integrationGeneral?:  {
      __typename: "IntegrationGeneral",
      id: string,
      latestVersion: string,
      latestLink: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIntegrationSubscriptionVariables = {
  filter?: ModelSubscriptionIntegrationFilterInput | null,
};

export type OnDeleteIntegrationSubscription = {
  onDeleteIntegration?:  {
    __typename: "Integration",
    id: string,
    run: boolean,
    integrationGeneralId: string,
    integrationGeneral?:  {
      __typename: "IntegrationGeneral",
      id: string,
      latestVersion: string,
      latestLink: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateOrderByIdSubscription = {
  onUpdateOrderById?:  {
    __typename: "Order",
    id: string,
    businessId: string,
    locationId: string,
    table?:  {
      __typename: "Table",
      id: string,
      businessId: string,
      locationId: string,
      name: string,
      seats: number,
      createdAt: string,
      updatedAt: string,
      floorTablesId?: string | null,
      tableActiveOrderId?: string | null,
    } | null,
    destination?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    note?: string | null,
    billingInfo?:  {
      __typename: "BillingInfo",
      email?: string | null,
      phoneNumber?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      vatNumber?: string | null,
      sdi?: string | null,
    } | null,
    type?: OrderType | null,
    orderItems?:  {
      __typename: "ModelOrderItemConnection",
      nextToken?: string | null,
    } | null,
    orderItemsSortOrder?: Array< string | null > | null,
    subTotal: number,
    total: number,
    paid: number,
    deliverectParams?:  {
      __typename: "OrderDeliverectParams",
      id?: string | null,
      type: number,
      isAlreadyPaid: boolean,
      paymentType: number,
      paymentAmount: number,
      decimalDigits: number,
      status: number,
    } | null,
    ristoQuickStatus?: RistoQuickStatus | null,
    status: OrderStatus,
    lastPaymentsRistoQuick?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    payments?:  {
      __typename: "ModelPaymentConnection",
      nextToken?: string | null,
    } | null,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      type: UserType,
      coin?: number | null,
      totalCoin?: number | null,
      stripeCustomerId?: string | null,
      firstName?: string | null,
      businessName?: string | null,
      taxCode?: string | null,
      sdi?: string | null,
      lastName?: string | null,
      fiscalCode?: string | null,
      gender?: GenderType | null,
      phoneNumber?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    generalVatRate?: number | null,
    deliveryCost?: number | null,
    phoneNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    orderTableId?: string | null,
  } | null,
};

export type OnUpdateLocationByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateLocationByIdSubscription = {
  onUpdateLocationById?:  {
    __typename: "Location",
    id: string,
    name?: string | null,
    images?: Array< string | null > | null,
    logo?: string | null,
    bio?: string | null,
    bioTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    description?: string | null,
    descriptionTranslations?:  Array< {
      __typename: "Translation",
      language: string,
      body: string,
    } | null > | null,
    gallery?:  Array< {
      __typename: "ImageGallery",
      image: string,
      name: string,
    } | null > | null,
    posts?:  {
      __typename: "ModelPostConnection",
      nextToken?: string | null,
    } | null,
    stripeInfo?:  {
      __typename: "StripeInfo",
      stripeCostumer?: string | null,
      stripeAccount?: string | null,
      stripeSubscriptionType?: StripeSubscriptionType | null,
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null,
      stripeDefaultPaymentMethod?: boolean | null,
    } | null,
    invoiceInfo?:  {
      __typename: "InvoiceInfo",
      taxCode: string,
      broadcastFormat: string,
      businessName: string,
      taxRegime: string,
    } | null,
    openingDay?:  {
      __typename: "OpeningDay",
    } | null,
    deliverectId: string,
    status: LocationStatus,
    timeZone: string,
    menu?:  {
      __typename: "ModelMenuConnection",
      nextToken?: string | null,
    } | null,
    menuSortOrder?: Array< string | null > | null,
    floors?:  {
      __typename: "ModelFloorConnection",
      nextToken?: string | null,
    } | null,
    credit: number,
    selfOrdering: boolean,
    externalLinks?:  Array< {
      __typename: "ExternaLink",
      link: string,
      label: string,
      icon: string,
    } > | null,
    template: Template,
    color: string,
    showCurrency: boolean,
    showWineList?: boolean | null,
    address?:  {
      __typename: "Address",
      street: string,
      houseNumber: string,
      postalCode: string,
      city: string,
      province: string,
      country: string,
    } | null,
    phoneNumber?: string | null,
    social?:  Array< {
      __typename: "SocialLink",
      link: string,
      label: string,
      icon: string,
    } | null > | null,
    email?: string | null,
    languageDefault: string,
    languagesEnabled?: Array< string | null > | null,
    translationsInProgress?: boolean | null,
    coverPrice?: number | null,
    deliveryCost?: number | null,
    generalVatRate?: number | null,
    businessLocationsId: string,
    business?:  {
      __typename: "Business",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    associatedCorporate?:  {
      __typename: "ModelCorporateLocationsConnection",
      nextToken?: string | null,
    } | null,
    presences?:  {
      __typename: "ModelAssociatedPresenceConnection",
      nextToken?: string | null,
    } | null,
    bankDetails?:  {
      __typename: "BankDetails",
      accountHolder: string,
      iban: string,
      bic: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAssociatedPresenceByAssociatedSubscriptionVariables = {
  associatedID: string,
};

export type OnCreateAssociatedPresenceByAssociatedSubscription = {
  onCreateAssociatedPresenceByAssociated?:  {
    __typename: "AssociatedPresence",
    id: string,
    associatedID: string,
    locationID: string,
    corporateID: string,
    location?:  {
      __typename: "Location",
      id: string,
      name?: string | null,
      images?: Array< string | null > | null,
      logo?: string | null,
      bio?: string | null,
      description?: string | null,
      deliverectId: string,
      status: LocationStatus,
      timeZone: string,
      menuSortOrder?: Array< string | null > | null,
      credit: number,
      selfOrdering: boolean,
      template: Template,
      color: string,
      showCurrency: boolean,
      showWineList?: boolean | null,
      phoneNumber?: string | null,
      email?: string | null,
      languageDefault: string,
      languagesEnabled?: Array< string | null > | null,
      translationsInProgress?: boolean | null,
      coverPrice?: number | null,
      deliveryCost?: number | null,
      generalVatRate?: number | null,
      businessLocationsId: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    corporate?:  {
      __typename: "Corporate",
      id: string,
      businessCorporateId: string,
      name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    associated?:  {
      __typename: "Associated",
      id: string,
      userId?: string | null,
      corporateID: string,
      userEmail: string,
      status: AssociatedStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

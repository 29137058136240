/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "deliverectRest",
            "endpoint": "https://ip2p6lv3yg.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "integration",
            "endpoint": "https://j9mvhwarpa.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "invoiceManagerRest",
            "endpoint": "https://xz9xyk1e40.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "stripeRest",
            "endpoint": "https://t51fqsqv08.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6hyibamrzfcbvgxqjbgod4lc2a.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "eu-west-1:63c7f19b-a798-47f3-8139-b322593cf28a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_74nR4J7v9",
    "aws_user_pools_web_client_id": "1et75diii5prlsioi81gafpen0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "wannapay-bucket160621-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "predictions": {
        "convert": {
            "translateText": {
                "region": "eu-west-1",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "it",
                    "targetLanguage": "en"
                }
            }
        }
    }
};


export default awsmobile;

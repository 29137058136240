import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { getLocationQuery, menuByLocationQuery, getMenuQuery } from 'src/backend/queries';
import * as mutations from 'src/backend/graphql/mutations';
import * as api from 'src/backend/API';
// hooks
import { updateLocationApi } from './location';

export async function getMenusMinimalNoFilterByLocationApi(locationId: string) {
  try {
    const locationQuery = (await API.graphql(
      graphqlOperation(getLocationQuery, { id: locationId })
    )) as {
      data: any;
    };

    if (!locationQuery || !locationQuery.data || !locationQuery.data.getLocation)
      throw new Error('No menu found');

    const getMenusMinimal = /* GraphQL */ `
      query GetMenusMinimalByLocation($menuLocationId: ID!) {
        menuByLocation(menuLocationId: $menuLocationId) {
          items {
            id
            wineList
          }
        }
      }
    `;

    const menuByLocation = (await API.graphql(
      graphqlOperation(getMenusMinimal, {
        menuLocationId: locationId,
      })
    )) as {
      data: any;
    };

    if (
      !menuByLocation ||
      !menuByLocation.data ||
      !menuByLocation.data.menuByLocation ||
      !menuByLocation.data.menuByLocation.items
    )
      throw new Error('No menus found');

    const sortMenus = menuByLocation.data.menuByLocation.items.sort((a: api.Menu, b: api.Menu) => {
      const indexA = locationQuery.data.getLocation.menuSortOrder.indexOf(a.id);
      const indexB = locationQuery.data.getLocation.menuSortOrder.indexOf(b.id);
      return indexA - indexB;
    });

    return sortMenus as api.Menu[];
  } catch (err) {
    console.log('err', err);

    return null;
  }
}

export async function getMenusByLocationApi(locationId: string) {
  try {
    const locationQuery = (await API.graphql(
      graphqlOperation(getLocationQuery, { id: locationId })
    )) as {
      data: any;
    };

    if (!locationQuery || !locationQuery.data || !locationQuery.data.getLocation)
      throw new Error('No menu found');

    const menuByLocation = (await API.graphql(
      graphqlOperation(menuByLocationQuery, {
        menuLocationId: locationId,
        filter: {
          wineList: { ne: true },
        },
      })
    )) as {
      data: any;
    };

    if (
      !menuByLocation ||
      !menuByLocation.data ||
      !menuByLocation.data.menuByLocation ||
      !menuByLocation.data.menuByLocation.items
    )
      throw new Error('No menus found');

    const sortMenus = menuByLocation.data.menuByLocation.items.sort((a: api.Menu, b: api.Menu) => {
      const indexA = locationQuery.data.getLocation.menuSortOrder.indexOf(a.id);
      const indexB = locationQuery.data.getLocation.menuSortOrder.indexOf(b.id);
      return indexA - indexB;
    });

    return sortMenus as api.Menu[];
  } catch (err) {
    console.log('err', err);

    return null;
  }
}

export async function getMenusWineByLocationApi(locationId: string) {
  try {
    const locationQuery = (await API.graphql(
      graphqlOperation(getLocationQuery, { id: locationId })
    )) as {
      data: any;
    };

    if (!locationQuery || !locationQuery.data || !locationQuery.data.getLocation)
      throw new Error('No menu found');

    const menuByLocation = (await API.graphql(
      graphqlOperation(menuByLocationQuery, {
        menuLocationId: locationId,
        filter: {
          wineList: { eq: true },
        },
      })
    )) as {
      data: any;
    };

    if (
      !menuByLocation ||
      !menuByLocation.data ||
      !menuByLocation.data.menuByLocation ||
      !menuByLocation.data.menuByLocation.items
    )
      throw new Error('No menus found');

    const sortMenus = menuByLocation.data.menuByLocation.items.sort((a: api.Menu, b: api.Menu) => {
      const indexA = locationQuery.data.getLocation.menuSortOrder.indexOf(a.id);
      const indexB = locationQuery.data.getLocation.menuSortOrder.indexOf(b.id);
      return indexA - indexB;
    });

    return sortMenus as api.Menu[];
  } catch (err) {
    console.log('err', err);

    return null;
  }
}

export async function updateMenusApi(newMenusData: api.Menu[]) {
  try {
    if (newMenusData.length > 0) {
      // eslint-disable-next-line guard-for-in
      for (const newMenuData in newMenusData) {
        // eslint-disable-next-line no-await-in-loop
        const updateMenu = await API.graphql<GraphQLQuery<any>>({
          query: mutations.updateMenu,
          variables: {
            input: {
              ...newMenusData[newMenuData],
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        if (!updateMenu || !updateMenu.data) throw new Error('Update error');
      }
    }
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function getMenuApi(menuId: string) {
  try {
    const data = (await API.graphql(graphqlOperation(getMenuQuery, { id: menuId }))) as {
      data: any;
    };
    if (!data || !data.data || !data.data.getMenu) throw new Error('No menu found');

    return data.data.getMenu;
  } catch (err) {
    return null;
  }
}

export async function createMenuApi(menuId: string, createData: any) {
  try {
    const createMenu = await API.graphql<GraphQLQuery<any>>({
      query: mutations.createMenu,
      variables: {
        input: {
          id: menuId,
          categorySortOrder: [],
          nestedModifiers: true,
          ...createData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (!createMenu.data) throw new Error('Error create menu');

    const order: string[] = createMenu.data.createMenu.location.menuSortOrder;

    order.push(menuId);

    const updateSortOrder = await updateLocationApi(createMenu.data.createMenu.location.id, {
      menuSortOrder: order,
    });

    if (updateSortOrder) {
      return true;
    }
    throw new Error('Update error');
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function updateMenuApi(menuId: string, updateData: any) {
  try {
    const updateMenuQuery = await API.graphql<GraphQLQuery<any>>({
      query: mutations.updateMenu,
      variables: {
        input: {
          id: menuId,
          ...updateData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (updateMenuQuery && updateMenuQuery.data) {
      return true;
    }
    throw new Error('Update error');
  } catch (err) {
    return false;
  }
}

export async function deleteMenuApi(menuId: string) {
  try {
    const deleteMenu = await API.graphql<GraphQLQuery<any>>({
      query: mutations.deleteMenu,
      variables: {
        input: {
          id: menuId,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (!deleteMenu.data) throw new Error('Error delete menu');

    const order: string[] = deleteMenu.data.deleteMenu.location.menuSortOrder;

    const updateSortOrder = await updateLocationApi(deleteMenu.data.deleteMenu.location.id, {
      menuSortOrder: order.filter((stringa) => stringa !== menuId),
    });

    if (updateSortOrder) {
      return true;
    }

    throw new Error('Update error');
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { Auth } from '@aws-amplify/auth';
// api
import { ApiQueries } from 'src/backend';
//
import { AuthContext } from './auth-context';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';
import { getRoleAndIds } from '../../roles';
//
import { useSettingsAuthContext } from '../../settings/context';
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const authSettings = useSettingsAuthContext();

  const initialize = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      if (currentUser) {
        const currentUserGroups =
          currentUser.signInUserSession.accessToken.payload['cognito:groups'];

        const { role, locationIds, locations, roles } = await getRoleAndIds(currentUserGroups);
        const user = await ApiQueries.getUserApi(currentUser.attributes.sub)
          .promise()
          .then((data: any) => data)
          .catch(() => null);

        if (user) {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: {
                ...user,
                displayName:
                  user.firstName && user.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : user.businessName,
                role,
                locationIds,
                locations,
                roles,
              },
            },
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: null,
            },
          });
        }
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error('error', error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      const currentUser = await Auth.signIn(email, password);

      const currentUserGroups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];

      const { role, locationIds, locations, roles } = await getRoleAndIds(currentUserGroups);
      if (authSettings.canReset) authSettings.onReset();

      const user = await ApiQueries.getUserApi(currentUser.attributes.sub)
        .promise()
        .then((data: any) => data)
        .catch(() => null);

      dispatch({
        type: Types.INITIAL,
        payload: {
          user: {
            ...user,
            displayName:
              user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : user.businessName,
            role,
            locationIds,
            locations,
            roles,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authSettings]
  );

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      if (authSettings.canReset) authSettings.onReset();

      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
        },
      });
    },
    [authSettings]
  );

  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (email: string, code: string) => {
    await Auth.confirmSignUp(email, code);
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (email: string) => {
    await Auth.resendSignUp(email);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    if (authSettings.canReset) authSettings.onReset();

    await Auth.signOut();
    dispatch({
      type: Types.LOGOUT,
    });
  }, [authSettings]);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await Auth.forgotPassword(email);
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (email: string, code: string, password: string) => {
    await Auth.forgotPasswordSubmit(email, code, password);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'amplify',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      register,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

// api
import { ApiTypes } from 'src/backend';

export default function getValueCurrentLanguage(
  value: string,
  translations: (ApiTypes.Translation | null)[] | null | undefined,
  locationDefaultLanguage: string | undefined,
  currentLanguage: string
) {
  if (!locationDefaultLanguage) return value;
  if (locationDefaultLanguage === currentLanguage) return value;

  if (!translations) return value;

  const indexTranslation = translations.findIndex(
    (translation: any) => translation.language === currentLanguage
  );

  if (indexTranslation <= -1 || !translations[indexTranslation]) return value;

  return translations[indexTranslation]!.body;
}

import { m } from 'framer-motion';
import { useEffect, useCallback } from 'react';
// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useAuthContext } from 'src/auth/hooks';
// api
import { API } from 'aws-amplify';
import { ApiTypes } from 'src/backend';
import { onUpdateLocationById } from 'src/backend/subscriptions';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// assets
import { ForbiddenIllustration } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
//
import { useSettingsAuthContext } from '../settings/context';

import RoleTypes from '../roles';
// ----------------------------------------------------------------------

interface SubscriptionValue<T> {
  value: { data: T };
}
type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: RoleTypes[];
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  locationNeed?: boolean;
  enableOnTrial?: boolean;
};

export default function RoleBasedGuard({
  hasContent,
  roles,
  children,
  sx,
  locationNeed,
  enableOnTrial,
}: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { user } = useAuthContext();
  const router = useRouter();
  const authSettings = useSettingsAuthContext();

  const currentRole = user?.role; // admin;

  const redirectSelectLocation = useCallback(
    (returnPath?: string) => {
      const searchParams = new URLSearchParams({
        returnTo: returnPath ? `${window.location.origin}${returnPath}` : window.location.href,
      }).toString();
      const selectLocationPath = paths.dashboard.location.deafult;
      const href = `${selectLocationPath}?${searchParams}`;
      router.replace(href);
    },
    [router]
  );

  const check = useCallback(() => {
    if (currentRole === RoleTypes.ROLE_0000) {
      router.replace(paths.auth.root);
    }
    if (typeof locationNeed !== 'undefined' && authSettings.selectedLocation === null) {
      redirectSelectLocation();
    }
    if (
      (currentRole === RoleTypes.ROLE_0100 || currentRole === RoleTypes.ROLE_0190) &&
      enableOnTrial !== true
    ) {
      if (
        authSettings.selectedLocation &&
        authSettings.selectedLocation.locationStatus !== ApiTypes.LocationStatus.active
      ) {
        // router.replace(paths.dashboard.location.account.billing.tab('plan'));
        router.replace(paths.dashboard.root);
      }
    }
  }, [
    authSettings.selectedLocation,
    currentRole,
    enableOnTrial,
    locationNeed,
    redirectSelectLocation,
    router,
  ]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (authSettings && authSettings.selectedLocation && authSettings.selectedLocation.locationId) {
      const subscription = API.graphql(
        {
          query: onUpdateLocationById,
          variables: { id: authSettings.selectedLocation.locationId },
        }
        // @ts-ignore
      ).subscribe({
        next: (response: SubscriptionValue<ApiTypes.OnUpdateLocationByIdSubscription>) => {
          if (
            response &&
            response.value &&
            response.value.data &&
            response.value.data.onUpdateLocationById
          ) {
            if (
              response.value.data.onUpdateLocationById.status !==
              authSettings.selectedLocation?.locationStatus
            ) {
              if (
                response.value.data.onUpdateLocationById.status !== ApiTypes.LocationStatus.active
              ) {
                redirectSelectLocation();
              } else {
                redirectSelectLocation(paths.dashboard.root);
              }
            }
          }
        },
      });

      return () => subscription.unsubscribe();
    }
    return function unsubscribe() {
      return null;
    };
  }, [authSettings, redirectSelectLocation, router]);

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}

/* eslint-disable react/style-prop-object */
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function OrdersCurrentMonthIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 1080 1080"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="m461.8,882.8l305.5,38.1c47.8,6,91.4-28,97.4-75.8l44.6-358.3c6.2-50-29.2-95.5-79.2-101.7l-312.5-38.9c-41-5.1-78.3,24-83.4,65l-46.9,376.1c-5.8,46.8,27.5,89.7,74.5,95.5"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m430.8,879l305.5,38c47.8,6,91.4-28,97.4-75.8l44.6-358.3c6.2-50-29.2-95.5-79.2-101.7l-312.5-38.9c-41-5.1-78.3,24-83.4,65l-46.9,376.1c-5.9,46.9,27.5,89.7,74.5,95.6"
        style={{ fill: PRIMARY_MAIN }}
      />
      <path
        d="m875.9,501.7s-52.7,370.5-112.8,404.3c0,0-316.6-64.5-425.1-68.9l61.1-397.5,476.8,62.1Z"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m399.1,439.6l476.8,62.2s-15.3,313.2-176.5,389.5c-7.1,3.3-15.1,4-22.7,1.9-56.1-15.2-309.7-82.7-428-95.4-6.6-.7-9.4-8.8-4.6-13.5,31.6-30.2,109.4-124,155-344.7"
        style={{ fill: '#f4f4f5' }}
      />
      <path
        d="m740.1,428.8c-2.1,10.1-9.4,17.2-16.3,15.8s-10.9-10.8-8.8-20.9c2.1-10.1,9.4-17.2,16.3-15.8s10.9,10.8,8.8,20.9"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m761.3,285.1c-36.9,0-66.9,37.6-66.9,83.9,0,28.9,11.7,54.3,29.5,69.4,2.7,2.3,6.6,2.5,9.5.4,0,0,5.4-3.4,6.4-11.5.7-5.5-1.2-11-4.7-15.2-8.6-10.6-14.1-26.1-14.1-43,0-31,18.5-57.2,40.3-57.2,11.5,0,22,7.3,29.4,18.6l18.4-20c-12.1-15.7-29.1-25.4-47.8-25.4"
        style={{ fill: '#fff' }}
      />
      <path
        d="m800.6,381.5l26.4,3.4c.8-5.1,1.2-10.4,1.2-15.9,0-22.8-7.3-43.5-19.2-58.6l-18.4,20c6.7,10.3,10.9,23.9,10.9,38.6.2,4.3-.2,8.5-.9,12.5"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m486.7,397.1c-2.1,10.1-9.4,17.2-16.3,15.8s-10.9-10.8-8.8-20.9,9.4-17.2,16.3-15.8,10.8,10.8,8.8,20.9"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m507.9,253.4c-36.9,0-66.9,37.6-66.9,83.9,0,28.9,11.7,54.3,29.5,69.4,2.7,2.3,6.6,2.5,9.5.4,0,0,5.4-3.4,6.4-11.5.7-5.5-1.2-11-4.7-15.2-8.6-10.6-14.1-26.1-14.1-43,0-31,18.5-57.2,40.3-57.2,11.5,0,22,7.3,29.4,18.6l18.4-20c-12.2-15.7-29.1-25.4-47.8-25.4"
        style={{ fill: '#fff' }}
      />
      <path
        d="m547.2,349.8l26.4,3.4c.8-5.1,1.2-10.4,1.2-15.9,0-22.8-7.3-43.5-19.2-58.6l-18.4,20c6.7,10.3,10.9,23.9,10.9,38.6.1,4.3-.3,8.4-.9,12.5"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m560,409.8c-2.1,10.1-9.4,17.2-16.3,15.8s-10.9-10.8-8.8-20.9c2.1-10.1,9.4-17.2,16.3-15.8s10.8,10.7,8.8,20.9"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m581.2,266.1c-36.9,0-66.9,37.6-66.9,83.9,0,28.9,11.7,54.3,29.5,69.4,2.7,2.3,6.6,2.5,9.5.4,0,0,5.4-3.4,6.4-11.5.7-5.5-1.2-11-4.7-15.2-8.6-10.6-14.1-26.1-14.1-43,0-31,18.5-57.2,40.3-57.2,11.5,0,22,7.3,29.4,18.6l18.4-20c-12.2-15.7-29.1-25.4-47.8-25.4"
        style={{ fill: '#fff' }}
      />
      <path
        d="m620.3,358.9l27.2,3.4c.7-5.6.7-10.1.7-12.3,0-22.8-7.3-43.5-19.2-58.6l-18.4,20c6.7,10.3,10.9,23.9,10.9,38.6,0,2-.4,5.5-1.2,8.9"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m875.9,501.7s-27.2,309-328,318c-6.7.2-13.3-.8-19.5-3.1-50.8-18.9-308-112.8-466.8-138.3-5.9-.9-5.8-9.4.1-10.2,93.4-12.8,298.7-60,337.3-228.6"
        style={{ fill: '#fff' }}
      />
      <path
        d="m813.4,441.5c-2.1,10.1-9.4,17.2-16.3,15.8s-10.9-10.8-8.8-20.9c2.1-10.1,9.4-17.2,16.3-15.8s10.9,10.7,8.8,20.9"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m834.7,297.8c-36.9,0-66.9,37.6-66.9,83.9,0,28.9,11.7,54.3,29.5,69.4,2.7,2.3,6.6,2.5,9.5.4,0,0,5.4-3.4,6.4-11.5.7-5.5-1.2-11-4.7-15.2-8.6-10.6-14.1-26.1-14.1-43,0-31,18.5-57.2,40.3-57.2,11.5,0,22,7.3,29.4,18.6l18.4-20c-12.2-15.7-29.1-25.4-47.8-25.4"
        style={{ fill: '#fff' }}
      />
      <path
        d="m870.4,400.2s4.3,2.5,11.2,9.1,10.4,11.7,10.4,11.7c9.6-15.4,9.6-34,9.6-39.4,0-22.8-7.3-43.5-19.2-58.6l-18.4,20c6.7,10.3,10.9,23.9,10.9,38.6,0,4.3-1.9,14.6-4.5,18.6"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m296.3,550s-199.4-64.1-211.1-269.5c0,0,84.3-158.5,269.4-211.3,0,0-13.5,142.7,70.7,194.1-.1-.1-146.6,110.7-129,286.7"
        style={{ fill: '#fff' }}
      />
      <path d="m502.9,593.3c-7.9-9.7-11.8-18.4-11.7-26.1.1-7.7,1.6-14.5,4.4-20.2,6.2-12.7,17.3-21.2,33.2-25.7,15.8-4.5,34.2-2.6,55.5,5.7,21.4,8.3,36.2,19.3,44.4,32.9,8.3,13.7,9.9,27,4.7,39.9-2.4,5.8-6.6,11.4-12.6,16.8-6.1,5.4-15.6,8.5-28.7,9.2,9.1,9.1,14.8,18.8,17.1,28.9,2.3,10.2,1.4,20.3-2.8,30.2-6.4,14.8-19.3,24.8-38.8,29.7-19.6,4.9-42.7,2.4-69.1-7.4-26.2-9.8-43.3-22.2-51.2-37.5-7.8-15.2-7.7-30,0-44.6,5.1-9.7,12.5-17.5,22.1-23.2,9.6-5.9,20.8-8.7,33.5-8.6h0Zm11.2,86.4c10.5,3.9,19.8,4.6,28,2.1s14.2-8.1,18.2-16.8c4.1-9,4.2-16.9.4-23.8-3.8-6.9-10.7-12.2-20.6-16-9.9-3.8-18.9-4.4-27-2-8.1,2.4-14.3,8.1-18.7,17-4.3,8.6-4.7,16.3-1.2,23.2,3.5,6.9,10.5,12.4,20.9,16.3h0Zm38.1-80.9c8.9,3.4,16.8,4,23.4,1.8,6.7-2.2,11.4-6.5,14.2-12.9,3-7,3-13.3-.1-19.1s-9-10.3-17.7-13.7c-8.6-3.3-16.2-3.9-22.8-1.9-6.7,2-11.6,6.5-14.8,13.5-3,6.4-3,12.5,0,18.4,3.1,5.9,9,10.6,17.8,13.9h0Z" />
      <path
        d="m283.8,313.2c-9.7,1.3-19.1,3.9-28.3,7.8-7.4,3.1-16,7.9-25.8,14.5-10.1,6.7-18.1,13-23.7,19.1l-15.4-25c18.2-18.2,39.7-30.5,64.1-37.1,14.7-3.7,25.3-5,32.2-3.9l-31.9-61.4,13.6-18.1,46,87-10.2,16c-4.3-.6-11.1-.2-20.6,1.1h0Z"
        style={{ fill: '#4d4d4d' }}
      />
    </Box>
  );
}

export default memo(OrdersCurrentMonthIllustration);

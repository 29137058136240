// api
import * as api from 'src/backend/API';

interface OptionalParam {
  key: string;
  values: { oldValue: any; newValue: any }[];
}

export const formatGraphql = (originalObject: any, optionalParams?: OptionalParam[]) => {
  const formattedObject = { ...originalObject };

  if (optionalParams) {
    optionalParams.forEach((param) => {
      const { key, values } = param;

      if (formattedObject[key] !== undefined) {
        values.forEach((change) => {
          const { oldValue, newValue } = change;
          if (formattedObject[key] === oldValue) {
            formattedObject[key] = newValue;
          }
        });
      }
    });
  }

  return Object.fromEntries(
    Object.entries(formattedObject).filter(([_, value]) => value !== undefined)
  );
};

export const getProductCategoriesId = (product: api.Product | undefined): string[] => {
  if (product && product.categories && product.categories.items) {
    const categoryIDs = product.categories.items.map((cp: api.CategoriesProducts | null) => {
      if (cp && typeof cp.categoryID === 'string') {
        return cp.categoryID;
      }
      return null;
    });

    return categoryIDs.filter((id: string | null): id is string => id !== null) as string[];
  }
  return [];
};

export function generatePLUFromName(name: string) {
  // Trasforma il nome in un acronimo
  const acronym = name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');

  // Genera una stringa casuale per aggiungere al PLU
  const randomString = Math.random().toString(36).substring(2, 6).toUpperCase();

  // Componi il PLU
  const PLU = `P-${acronym}-${randomString}-2`;

  return PLU;
}

export function formatModifierGroups(arrayInput: (api.Product | null)[]): any[] {
  return [];
  /*
  // Assicurati che l'array di input sia definito
  if (!Array.isArray(arrayInput)) {
    console.error('Input non valido. Atteso un array.');
    return [];
  }

  // Elabora ogni oggetto in arrayInput
  const risultato = arrayInput.map((oggettoInput) => {
    // Assicurati che l'oggetto di input abbia la struttura attesa
    if (
      !oggettoInput ||
      !oggettoInput.modifierGroup ||
      !oggettoInput.modifierGroup.modifiers ||
      !oggettoInput.modifierGroup.modifiers.items
    ) {
      console.error('Oggetto di input non valido.');
      return [];
    }

    // Estrai le informazioni necessarie dall'oggetto di input
    const {
      modifierGroup: {
        id,
        name,
        modifiers: { items },
      },
    } = oggettoInput;

    // Costruisci il nuovo oggetto con la struttura desiderata
    return {
      modifierGroup: {
        id,
        name,
        modifiers: {
          items: items.map(
            (item: ApiTypes.ModifierGroupsModifiers | null) =>
              item && {
                id: item.id,
                modifier: {
                  id: item.modifier.id,
                  name: item.modifier.name,
                  price: item.modifier.price,
                },
              }
          ),
        },
      },
    };
  });

  return risultato;
  */
}

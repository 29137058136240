import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
// hooks
import { useLocalStorage } from 'src/hooks/use-local-storage';
//
import { SettingsAuthValueProps } from '../types';
import { SettingsAuthContext } from './settings-auth-context';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'user-settings';

type SettingAuthProviderProps = {
  children: React.ReactNode;
  defaultSettings: SettingsAuthValueProps;
};

export function SettingAuthProvider({ children, defaultSettings }: SettingAuthProviderProps) {
  const { state, update, reset } = useLocalStorage(STORAGE_KEY, defaultSettings);

  const canReset = !isEqual(state, defaultSettings);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      onUpdate: update,
      // Reset
      canReset,
      onReset: reset,
    }),
    [reset, update, state, canReset]
  );

  return (
    <SettingsAuthContext.Provider value={memoizedValue}>{children}</SettingsAuthContext.Provider>
  );
}

import { _mock } from './_mock';

// ----------------------------------------------------------------------

export const ORDER_STATUS_OPTIONS = [
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'refunded', label: 'Refunded' },
];

export const _orders = [...Array(1)].map((_, index) => {
  const shipping = 10;

  const discount = 10;

  const taxes = 10;

  const items = [
    {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
      sku: '16H9UR0',
      quantity: 1,
      name: 'Insalata Mediterranea',
      coverUrl:
        'https://www.oggi.it/cucina/wp-content/uploads/sites/19/2023/08/INSALATA-MEDITERRANEA-470x377.jpg',
      price: 85,
    },

    {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
      sku: '16H9UR1',
      quantity: 15,
      name: 'Tramezzini',
      coverUrl: 'https://www.giallozafferano.it/images/20-2043/Tramezzini_780x520_wm.jpg',
      price: 3.5,
    },

    {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      sku: '16H9UR2',
      quantity: 7,
      name: 'Coca Cola in Lattina',
      coverUrl:
        'https://www.topbevande.it/images/thumbs/0085545_coca-cola-original-33cl-confezione-da-24-barattoli-lattina_780.jpeg',
      price: 3,
    },

    {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
      sku: '16H9UR3',
      quantity: 1,
      name: 'Servizio Ghiaccio',
      coverUrl: 'https://icetechice.com/blog/wp-content/uploads/2016/11/0-4.jpg',
      price: 25,
    },
  ];
  const totalQuantity = items.reduce((accumulator, item) => accumulator + item.quantity, 0);

  const subTotal = items.reduce((accumulator, item) => accumulator + item.price * item.quantity, 0);

  const totalAmount = subTotal - shipping - discount + taxes;

  const customer = {
    id: _mock.id(1),
    name: 'Barilla SPA',
    email: 'info@barilla.com',
    avatarUrl: _mock.image.avatar(1),
    ipAddress: '192.158.1.38',
  };

  const delivery = {
    shipBy: 'DHL',
    speedy: 'Standard',
    trackingNumber: 'SPX037739199373',
  };

  const history = {
    orderTime: _mock.time(1),
    paymentTime: _mock.time(2),
    deliveryTime: _mock.time(3),
    completionTime: _mock.time(4),
    timeline: [
      { title: 'Delivery successful', time: _mock.time(1) },
      { title: 'Transporting to [2]', time: _mock.time(2) },
      { title: 'Transporting to [1]', time: _mock.time(3) },
      {
        title: 'The shipping unit has picked up the goods',
        time: _mock.time(4),
      },
      { title: 'Order has been created', time: _mock.time(5) },
    ],
  };

  console.log('history', history.orderTime);

  return {
    id: _mock.id(index),
    orderNumber: `#601${index}`,
    createdAt: new Date('2024-07-31T12:31:12.000Z'),
    taxes,
    items,
    history,
    subTotal,
    shipping,
    discount,
    customer,
    delivery,
    totalAmount,
    totalQuantity,
    shippingAddress: {
      fullAddress: '19034 Verna Unions Apt. 164 - Honolulu, RI / 87535',
      phoneNumber: '365-374-4961',
    },
    payment: {
      cardType: 'mastercard',
      cardNumber: '**** **** **** 5678',
    },
    status:
      (index % 2 && 'completed') ||
      (index % 3 && 'pending') ||
      (index % 4 && 'cancelled') ||
      'refunded',
  };
});

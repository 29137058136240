import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
import RoleTypes from 'src/auth/roles';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard'));
// MENU
const PageMenu = lazy(() => import('src/pages/dashboard/products/menu/list'));
const MenuEditPage = lazy(() => import('src/pages/dashboard/products/menu/edit'));
// MENU
const PageWinesList = lazy(() => import('src/pages/dashboard/products/wines/list'));
const WinesEditPage = lazy(() => import('src/pages/dashboard/products/wines/edit'));
// CATEGORY
const PageCategory = lazy(() => import('src/pages/dashboard/products/menu/category/list'));
const CategoryEditPage = lazy(() => import('src/pages/dashboard/products/menu/category/edit'));
const CategoryProductsPage = lazy(
  () => import('src/pages/dashboard/products/menu/category/products')
);
// PRODUCT
const PageProduct = lazy(() => import('src/pages/dashboard/products/products/list-products'));
const PageVariant = lazy(() => import('src/pages/dashboard/products/products/list-variants'));
const PageModifiersGroup = lazy(
  () => import('src/pages/dashboard/products/products/list-modifiers-group')
);
const ProductEditPage = lazy(() => import('src/pages/dashboard/products/products/edit-products'));
const VariantEditPage = lazy(() => import('src/pages/dashboard/products/products/edit-variants'));
const ModifiersGroupEditPage = lazy(
  () => import('src/pages/dashboard/products/products/edit-modifiers-group')
);
// LOCATION
const LocationAccountProfilePage = lazy(() => import('src/pages/dashboard/account/profile'));
const LocationAccountBillingPage = lazy(() => import('src/pages/dashboard/account/billing'));
const LocationAccountTranslationsPage = lazy(
  () => import('src/pages/dashboard/account/translations')
);
const LocationAccountSettingsPage = lazy(() => import('src/pages/dashboard/account/settings'));
const SelectLocation = lazy(() => import('src/pages/dashboard/account/select'));
// POST
const PostPage = lazy(() => import('src/pages/dashboard/post'));
// ANALYTICS
const AnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const AnalyticsCorporatePage = lazy(() => import('src/pages/dashboard/analytics-corporate'));
// QRCODE
const QrCodePage = lazy(() => import('src/pages/dashboard/qrcode'));
const QrCodeCorporatePage = lazy(() => import('src/pages/dashboard/qrcode-corporate'));
// EXPORT
const ExportPage = lazy(() => import('src/pages/dashboard/export'));
// AFFILIATED
const AssociatedLocationsView = lazy(() => import('src/pages/dashboard/associated/locations'));
const AssociatedPeopleView = lazy(() => import('src/pages/dashboard/associated/people'));
// ORDERS
const OrdersPage = lazy(() => import('src/pages/dashboard/orders'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <RoleBasedGuard
            roles={[RoleTypes.ROLE_0100, RoleTypes.ROLE_0190, RoleTypes.ROLE_0200]}
            hasContent
            locationNeed
            enableOnTrial
          >
            <IndexPage />
          </RoleBasedGuard>
        ),
        index: true,
      },
      {
        path: 'products',
        children: [
          {
            path: 'menu',
            children: [
              {
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <PageMenu />
                  </RoleBasedGuard>
                ),
                index: true,
              },
              {
                path: ':menuId/categories',

                children: [
                  {
                    element: (
                      <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                        <PageCategory />
                      </RoleBasedGuard>
                    ),
                    index: true,
                  },
                  {
                    path: 'new',
                    element: (
                      <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                        <CategoryEditPage />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: ':categoryId/edit',
                    element: (
                      <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                        <CategoryEditPage />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: ':categoryId/products',

                    children: [
                      {
                        element: (
                          <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                            <CategoryProductsPage />
                          </RoleBasedGuard>
                        ),
                        index: true,
                      },
                      {
                        path: ':id/edit',
                        element: (
                          <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                            <ProductEditPage />
                          </RoleBasedGuard>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'new',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <MenuEditPage />
                  </RoleBasedGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <MenuEditPage />
                  </RoleBasedGuard>
                ),
              },
            ],
          },

          {
            path: 'wines',
            children: [
              {
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <PageWinesList />
                  </RoleBasedGuard>
                ),
                index: true,
              },
              {
                path: ':menuId/categories',

                children: [
                  {
                    element: (
                      <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                        <PageCategory />
                      </RoleBasedGuard>
                    ),
                    index: true,
                  },
                  {
                    path: 'new',
                    element: (
                      <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                        <CategoryEditPage />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: ':categoryId/edit',
                    element: (
                      <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                        <CategoryEditPage />
                      </RoleBasedGuard>
                    ),
                  },
                  {
                    path: ':categoryId/products',

                    children: [
                      {
                        element: (
                          <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                            <CategoryProductsPage />
                          </RoleBasedGuard>
                        ),
                        index: true,
                      },
                      {
                        path: ':id/edit',
                        element: (
                          <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                            <ProductEditPage />
                          </RoleBasedGuard>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'new',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <WinesEditPage />
                  </RoleBasedGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <WinesEditPage />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: 'product',
            children: [
              {
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <PageProduct />
                  </RoleBasedGuard>
                ),
                index: true,
              },
              {
                path: ':id/edit',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <ProductEditPage />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <ProductEditPage />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: 'variant',
            children: [
              {
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <PageVariant />
                  </RoleBasedGuard>
                ),
                index: true,
              },
              {
                path: ':id/edit',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <VariantEditPage />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <VariantEditPage />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: 'modifiersGroup',
            children: [
              {
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <PageModifiersGroup />
                  </RoleBasedGuard>
                ),
                index: true,
              },
              {
                path: ':id/edit',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <ModifiersGroupEditPage />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <ModifiersGroupEditPage />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'location',
        children: [
          {
            path: 'account',
            children: [
              {
                path: 'profile',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <LocationAccountProfilePage />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'billing',
                element: (
                  <RoleBasedGuard
                    roles={[RoleTypes.ROLE_0100, RoleTypes.ROLE_0200]}
                    hasContent
                    locationNeed
                    enableOnTrial
                  >
                    <LocationAccountBillingPage />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'translations',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <LocationAccountTranslationsPage />
                  </RoleBasedGuard>
                ),
              },
              {
                path: 'settings',
                element: (
                  <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                    <LocationAccountSettingsPage />
                  </RoleBasedGuard>
                ),
              },
            ],
          },
          {
            path: 'select',
            element: (
              <RoleBasedGuard
                roles={[RoleTypes.ROLE_0100, RoleTypes.ROLE_0190, RoleTypes.ROLE_0200]}
                hasContent
              >
                <SelectLocation />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'select/:businessId/:locationId',
            element: (
              <RoleBasedGuard
                roles={[RoleTypes.ROLE_0100, RoleTypes.ROLE_0190, RoleTypes.ROLE_0200]}
                hasContent
              >
                <SelectLocation />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        element: (
          <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
            <PostPage />
          </RoleBasedGuard>
        ),
        path: 'post',
      },
      {
        path: 'analytics',
        children: [
          {
            element: (
              <RoleBasedGuard
                roles={[RoleTypes.ROLE_0100, RoleTypes.ROLE_0200]}
                hasContent
                locationNeed
                enableOnTrial
              >
                <AnalyticsPage />
              </RoleBasedGuard>
            ),

            index: true,
          },
          {
            element: (
              <RoleBasedGuard
                roles={[RoleTypes.ROLE_0100, RoleTypes.ROLE_0200]}
                hasContent
                locationNeed
              >
                <AnalyticsCorporatePage />
              </RoleBasedGuard>
            ),
            path: 'corporate',
          },
        ],
      },
      {
        path: 'orders',
        children: [
          {
            element: (
              <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed enableOnTrial>
                <OrdersPage />
              </RoleBasedGuard>
            ),

            index: true,
          },
        ],
      },
      {
        path: 'qrcode',
        children: [
          {
            element: (
              <RoleBasedGuard
                roles={[RoleTypes.ROLE_0100, RoleTypes.ROLE_0190]}
                hasContent
                locationNeed
                enableOnTrial
              >
                <QrCodePage />
              </RoleBasedGuard>
            ),

            index: true,
          },
          {
            element: (
              <RoleBasedGuard roles={[RoleTypes.ROLE_0200]} hasContent locationNeed>
                <QrCodeCorporatePage />
              </RoleBasedGuard>
            ),
            path: 'corporate',
          },
        ],
      },
      {
        path: 'export',
        children: [
          {
            element: (
              <RoleBasedGuard roles={[RoleTypes.ROLE_0100]} hasContent locationNeed>
                <ExportPage />
              </RoleBasedGuard>
            ),

            index: true,
          },
        ],
      },
      {
        path: 'associated',
        children: [
          {
            path: 'locations',
            element: (
              <RoleBasedGuard roles={[RoleTypes.ROLE_0200]} hasContent locationNeed>
                <AssociatedLocationsView />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'people',
            element: (
              <RoleBasedGuard roles={[RoleTypes.ROLE_0200]} hasContent locationNeed>
                <AssociatedPeopleView />
              </RoleBasedGuard>
            ),
          },
        ],
      },
    ],
  },
];

export const onUpdateLocationById = /* GraphQL */ `
  subscription onUpdateLocationById($id: ID!) {
    onUpdateLocationById(id: $id) {
      id
      status
    }
  }
`;

export const OnUpdateOrderById = /* GraphQL */ `
  subscription OnUpdateOrderById($id: ID!) {
    onUpdateOrderById(id: $id) {
      id
      orderItemsSortOrder
      subTotal
      total
      businessId
      locationId
      paid
      status
      table {
        id
        name
      }
      orderItems {
        items {
          id
          name
          quantity
          total
          orderItemSubItemsId
          payment
          subItems {
            items {
              id
              name
              quantity
              total
              orderItemSubItemsId
              payment
              subItems {
                items {
                  id
                  name
                  quantity
                  total
                  orderItemSubItemsId
                  payment
                  subItems {
                    items {
                      id
                      name
                      quantity
                      total
                      orderItemSubItemsId
                      payment
                      subItems {
                        items {
                          id
                          name
                          quantity
                          total
                          orderItemSubItemsId
                          payment
                          subItems {
                            items {
                              id
                              name
                              quantity
                              total
                              orderItemSubItemsId
                              payment
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const newOrder = /* GraphQL */ `mutation NewOrder($cart: LambdaCartType!) {
  newOrder(cart: $cart) {
    ... on NewOrderResponseSuccess {
      orderId
      code
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.NewOrderMutationVariables,
  APITypes.NewOrderMutation
>;
export const registerLocation = /* GraphQL */ `mutation RegisterLocation($registerLocationBody: LambdaRegisterLocationBody!) {
  registerLocation(registerLocationBody: $registerLocationBody) {
    ... on RegisterLocationResponseSuccess {
      locationId
      code
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.RegisterLocationMutationVariables,
  APITypes.RegisterLocationMutation
>;
export const stripeAccountSession = /* GraphQL */ `mutation StripeAccountSession($locationID: ID!) {
  stripeAccountSession(locationID: $locationID) {
    ... on StripeAccountSessionResponseSuccess {
      clientSecret
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.StripeAccountSessionMutationVariables,
  APITypes.StripeAccountSessionMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $locationId: String!
  $amount: Int!
  $paymentId: String!
  $username: String
  $invoiceUserAttribues: InvoiceUserAttributes
) {
  createInvoice(
    locationId: $locationId
    amount: $amount
    paymentId: $paymentId
    username: $username
    invoiceUserAttribues: $invoiceUserAttribues
  ) {
    ... on CreateInvoiceResponseSuccess {
      invoiceId
      code
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const sendInvoice = /* GraphQL */ `mutation SendInvoice($paymentID: String!) {
  sendInvoice(paymentID: $paymentID) {
    ... on SendInvoiceResponseSuccess {
      send
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.SendInvoiceMutationVariables,
  APITypes.SendInvoiceMutation
>;
export const sendReceipt = /* GraphQL */ `mutation SendReceipt($paymentID: String!) {
  sendReceipt(paymentID: $paymentID) {
    ... on SendReceiptResponseSuccess {
      send
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.SendReceiptMutationVariables,
  APITypes.SendReceiptMutation
>;
export const removeCard = /* GraphQL */ `mutation RemoveCard($paymentMethodId: String!) {
  removeCard(paymentMethodId: $paymentMethodId) {
    ... on RemoveCardSuccess {
      success
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.RemoveCardMutationVariables,
  APITypes.RemoveCardMutation
>;
export const createBusiness = /* GraphQL */ `mutation CreateBusiness(
  $input: CreateBusinessInput!
  $condition: ModelBusinessConditionInput
) {
  createBusiness(input: $input, condition: $condition) {
    id
    name
    locations {
      nextToken
      __typename
    }
    corporate {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessMutationVariables,
  APITypes.CreateBusinessMutation
>;
export const updateBusiness = /* GraphQL */ `mutation UpdateBusiness(
  $input: UpdateBusinessInput!
  $condition: ModelBusinessConditionInput
) {
  updateBusiness(input: $input, condition: $condition) {
    id
    name
    locations {
      nextToken
      __typename
    }
    corporate {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessMutationVariables,
  APITypes.UpdateBusinessMutation
>;
export const deleteBusiness = /* GraphQL */ `mutation DeleteBusiness(
  $input: DeleteBusinessInput!
  $condition: ModelBusinessConditionInput
) {
  deleteBusiness(input: $input, condition: $condition) {
    id
    name
    locations {
      nextToken
      __typename
    }
    corporate {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessMutationVariables,
  APITypes.DeleteBusinessMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    email
    type
    coin
    totalCoin
    stripeCustomerId
    association {
      nextToken
      __typename
    }
    orders {
      nextToken
      __typename
    }
    firstName
    businessName
    taxCode
    sdi
    lastName
    fiscalCode
    address {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    gender
    dob {
      day
      month
      year
      __typename
    }
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const deleteAssociated = /* GraphQL */ `mutation DeleteAssociated(
  $input: DeleteAssociatedInput!
  $condition: ModelAssociatedConditionInput
) {
  deleteAssociated(input: $input, condition: $condition) {
    id
    userId
    corporateID
    userEmail
    user {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    presences {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAssociatedMutationVariables,
  APITypes.DeleteAssociatedMutation
>;
export const createCorporateLocations = /* GraphQL */ `mutation CreateCorporateLocations(
  $input: CreateCorporateLocationsInput!
  $condition: ModelCorporateLocationsConditionInput
) {
  createCorporateLocations(input: $input, condition: $condition) {
    id
    locationID
    corporateID
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCorporateLocationsMutationVariables,
  APITypes.CreateCorporateLocationsMutation
>;
export const updateCorporateLocations = /* GraphQL */ `mutation UpdateCorporateLocations(
  $input: UpdateCorporateLocationsInput!
  $condition: ModelCorporateLocationsConditionInput
) {
  updateCorporateLocations(input: $input, condition: $condition) {
    id
    locationID
    corporateID
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCorporateLocationsMutationVariables,
  APITypes.UpdateCorporateLocationsMutation
>;
export const deleteCorporateLocations = /* GraphQL */ `mutation DeleteCorporateLocations(
  $input: DeleteCorporateLocationsInput!
  $condition: ModelCorporateLocationsConditionInput
) {
  deleteCorporateLocations(input: $input, condition: $condition) {
    id
    locationID
    corporateID
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCorporateLocationsMutationVariables,
  APITypes.DeleteCorporateLocationsMutation
>;
export const createAssociatedPresence = /* GraphQL */ `mutation CreateAssociatedPresence(
  $input: CreateAssociatedPresenceInput!
  $condition: ModelAssociatedPresenceConditionInput
) {
  createAssociatedPresence(input: $input, condition: $condition) {
    id
    associatedID
    locationID
    corporateID
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    associated {
      id
      userId
      corporateID
      userEmail
      status
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssociatedPresenceMutationVariables,
  APITypes.CreateAssociatedPresenceMutation
>;
export const updateAssociatedPresence = /* GraphQL */ `mutation UpdateAssociatedPresence(
  $input: UpdateAssociatedPresenceInput!
  $condition: ModelAssociatedPresenceConditionInput
) {
  updateAssociatedPresence(input: $input, condition: $condition) {
    id
    associatedID
    locationID
    corporateID
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    associated {
      id
      userId
      corporateID
      userEmail
      status
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssociatedPresenceMutationVariables,
  APITypes.UpdateAssociatedPresenceMutation
>;
export const deleteAssociatedPresence = /* GraphQL */ `mutation DeleteAssociatedPresence(
  $input: DeleteAssociatedPresenceInput!
  $condition: ModelAssociatedPresenceConditionInput
) {
  deleteAssociatedPresence(input: $input, condition: $condition) {
    id
    associatedID
    locationID
    corporateID
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    associated {
      id
      userId
      corporateID
      userEmail
      status
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAssociatedPresenceMutationVariables,
  APITypes.DeleteAssociatedPresenceMutation
>;
export const createOrderItem = /* GraphQL */ `mutation CreateOrderItem(
  $input: CreateOrderItemInput!
  $condition: ModelOrderItemConditionInput
) {
  createOrderItem(input: $input, condition: $condition) {
    id
    plu
    name
    quantity
    price
    total
    type
    payment
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    subItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    orderItemSubItemsId
    orderOrderItemsId
    orderItemProductId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderItemMutationVariables,
  APITypes.CreateOrderItemMutation
>;
export const updateOrderItem = /* GraphQL */ `mutation UpdateOrderItem(
  $input: UpdateOrderItemInput!
  $condition: ModelOrderItemConditionInput
) {
  updateOrderItem(input: $input, condition: $condition) {
    id
    plu
    name
    quantity
    price
    total
    type
    payment
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    subItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    orderItemSubItemsId
    orderOrderItemsId
    orderItemProductId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderItemMutationVariables,
  APITypes.UpdateOrderItemMutation
>;
export const deleteOrderItem = /* GraphQL */ `mutation DeleteOrderItem(
  $input: DeleteOrderItemInput!
  $condition: ModelOrderItemConditionInput
) {
  deleteOrderItem(input: $input, condition: $condition) {
    id
    plu
    name
    quantity
    price
    total
    type
    payment
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    subItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    orderItemSubItemsId
    orderOrderItemsId
    orderItemProductId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderItemMutationVariables,
  APITypes.DeleteOrderItemMutation
>;
export const createPayment = /* GraphQL */ `mutation CreatePayment(
  $input: CreatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  createPayment(input: $input, condition: $condition) {
    id
    orderID
    orderIDRistoQuick
    status
    userID
    useCoin
    userEmail
    customerName
    paymentID
    paymentAmount
    invoiceID
    order {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      deliveryCost
      phoneNumber
      createdAt
      updatedAt
      orderTableId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentMutationVariables,
  APITypes.CreatePaymentMutation
>;
export const updatePayment = /* GraphQL */ `mutation UpdatePayment(
  $input: UpdatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  updatePayment(input: $input, condition: $condition) {
    id
    orderID
    orderIDRistoQuick
    status
    userID
    useCoin
    userEmail
    customerName
    paymentID
    paymentAmount
    invoiceID
    order {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      deliveryCost
      phoneNumber
      createdAt
      updatedAt
      orderTableId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentMutationVariables,
  APITypes.UpdatePaymentMutation
>;
export const deletePayment = /* GraphQL */ `mutation DeletePayment(
  $input: DeletePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  deletePayment(input: $input, condition: $condition) {
    id
    orderID
    orderIDRistoQuick
    status
    userID
    useCoin
    userEmail
    customerName
    paymentID
    paymentAmount
    invoiceID
    order {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      deliveryCost
      phoneNumber
      createdAt
      updatedAt
      orderTableId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentMutationVariables,
  APITypes.DeletePaymentMutation
>;
export const createIntegrationGeneral = /* GraphQL */ `mutation CreateIntegrationGeneral(
  $input: CreateIntegrationGeneralInput!
  $condition: ModelIntegrationGeneralConditionInput
) {
  createIntegrationGeneral(input: $input, condition: $condition) {
    id
    latestVersion
    latestLink
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIntegrationGeneralMutationVariables,
  APITypes.CreateIntegrationGeneralMutation
>;
export const updateIntegrationGeneral = /* GraphQL */ `mutation UpdateIntegrationGeneral(
  $input: UpdateIntegrationGeneralInput!
  $condition: ModelIntegrationGeneralConditionInput
) {
  updateIntegrationGeneral(input: $input, condition: $condition) {
    id
    latestVersion
    latestLink
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIntegrationGeneralMutationVariables,
  APITypes.UpdateIntegrationGeneralMutation
>;
export const deleteIntegrationGeneral = /* GraphQL */ `mutation DeleteIntegrationGeneral(
  $input: DeleteIntegrationGeneralInput!
  $condition: ModelIntegrationGeneralConditionInput
) {
  deleteIntegrationGeneral(input: $input, condition: $condition) {
    id
    latestVersion
    latestLink
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIntegrationGeneralMutationVariables,
  APITypes.DeleteIntegrationGeneralMutation
>;
export const createIntegration = /* GraphQL */ `mutation CreateIntegration(
  $input: CreateIntegrationInput!
  $condition: ModelIntegrationConditionInput
) {
  createIntegration(input: $input, condition: $condition) {
    id
    run
    integrationGeneralId
    integrationGeneral {
      id
      latestVersion
      latestLink
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIntegrationMutationVariables,
  APITypes.CreateIntegrationMutation
>;
export const updateIntegration = /* GraphQL */ `mutation UpdateIntegration(
  $input: UpdateIntegrationInput!
  $condition: ModelIntegrationConditionInput
) {
  updateIntegration(input: $input, condition: $condition) {
    id
    run
    integrationGeneralId
    integrationGeneral {
      id
      latestVersion
      latestLink
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIntegrationMutationVariables,
  APITypes.UpdateIntegrationMutation
>;
export const deleteIntegration = /* GraphQL */ `mutation DeleteIntegration(
  $input: DeleteIntegrationInput!
  $condition: ModelIntegrationConditionInput
) {
  deleteIntegration(input: $input, condition: $condition) {
    id
    run
    integrationGeneralId
    integrationGeneral {
      id
      latestVersion
      latestLink
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIntegrationMutationVariables,
  APITypes.DeleteIntegrationMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    email
    type
    coin
    totalCoin
    stripeCustomerId
    association {
      nextToken
      __typename
    }
    orders {
      nextToken
      __typename
    }
    firstName
    businessName
    taxCode
    sdi
    lastName
    fiscalCode
    address {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    gender
    dob {
      day
      month
      year
      __typename
    }
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    email
    type
    coin
    totalCoin
    stripeCustomerId
    association {
      nextToken
      __typename
    }
    orders {
      nextToken
      __typename
    }
    firstName
    businessName
    taxCode
    sdi
    lastName
    fiscalCode
    address {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    gender
    dob {
      day
      month
      year
      __typename
    }
    phoneNumber
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const createAssociated = /* GraphQL */ `mutation CreateAssociated(
  $input: CreateAssociatedInput!
  $condition: ModelAssociatedConditionInput
) {
  createAssociated(input: $input, condition: $condition) {
    id
    userId
    corporateID
    userEmail
    user {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    presences {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssociatedMutationVariables,
  APITypes.CreateAssociatedMutation
>;
export const updateAssociated = /* GraphQL */ `mutation UpdateAssociated(
  $input: UpdateAssociatedInput!
  $condition: ModelAssociatedConditionInput
) {
  updateAssociated(input: $input, condition: $condition) {
    id
    userId
    corporateID
    userEmail
    user {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    corporate {
      id
      businessCorporateId
      name
      createdAt
      updatedAt
      __typename
    }
    presences {
      nextToken
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssociatedMutationVariables,
  APITypes.UpdateAssociatedMutation
>;
export const createCorporate = /* GraphQL */ `mutation CreateCorporate(
  $input: CreateCorporateInput!
  $condition: ModelCorporateConditionInput
) {
  createCorporate(input: $input, condition: $condition) {
    id
    businessCorporateId
    name
    associates {
      nextToken
      __typename
    }
    associatedLocations {
      nextToken
      __typename
    }
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    presences {
      nextToken
      __typename
    }
    stripeInfo {
      stripeCostumer
      stripeAccount
      stripeSubscriptionType
      stripeSubscriptionStatus
      stripeDefaultPaymentMethod
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCorporateMutationVariables,
  APITypes.CreateCorporateMutation
>;
export const updateCorporate = /* GraphQL */ `mutation UpdateCorporate(
  $input: UpdateCorporateInput!
  $condition: ModelCorporateConditionInput
) {
  updateCorporate(input: $input, condition: $condition) {
    id
    businessCorporateId
    name
    associates {
      nextToken
      __typename
    }
    associatedLocations {
      nextToken
      __typename
    }
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    presences {
      nextToken
      __typename
    }
    stripeInfo {
      stripeCostumer
      stripeAccount
      stripeSubscriptionType
      stripeSubscriptionStatus
      stripeDefaultPaymentMethod
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCorporateMutationVariables,
  APITypes.UpdateCorporateMutation
>;
export const deleteCorporate = /* GraphQL */ `mutation DeleteCorporate(
  $input: DeleteCorporateInput!
  $condition: ModelCorporateConditionInput
) {
  deleteCorporate(input: $input, condition: $condition) {
    id
    businessCorporateId
    name
    associates {
      nextToken
      __typename
    }
    associatedLocations {
      nextToken
      __typename
    }
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    presences {
      nextToken
      __typename
    }
    stripeInfo {
      stripeCostumer
      stripeAccount
      stripeSubscriptionType
      stripeSubscriptionStatus
      stripeDefaultPaymentMethod
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCorporateMutationVariables,
  APITypes.DeleteCorporateMutation
>;
export const createLocation = /* GraphQL */ `mutation CreateLocation(
  $input: CreateLocationInput!
  $condition: ModelLocationConditionInput
) {
  createLocation(input: $input, condition: $condition) {
    id
    name
    images
    logo
    bio
    bioTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    gallery {
      image
      name
      __typename
    }
    posts {
      nextToken
      __typename
    }
    stripeInfo {
      stripeCostumer
      stripeAccount
      stripeSubscriptionType
      stripeSubscriptionStatus
      stripeDefaultPaymentMethod
      __typename
    }
    invoiceInfo {
      taxCode
      broadcastFormat
      businessName
      taxRegime
      __typename
    }
    openingDay {
      __typename
    }
    deliverectId
    status
    timeZone
    menu {
      nextToken
      __typename
    }
    menuSortOrder
    floors {
      nextToken
      __typename
    }
    credit
    selfOrdering
    externalLinks {
      link
      label
      icon
      __typename
    }
    template
    color
    showCurrency
    showWineList
    address {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    phoneNumber
    social {
      link
      label
      icon
      __typename
    }
    email
    languageDefault
    languagesEnabled
    translationsInProgress
    coverPrice
    deliveryCost
    generalVatRate
    businessLocationsId
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    associatedCorporate {
      nextToken
      __typename
    }
    presences {
      nextToken
      __typename
    }
    bankDetails {
      accountHolder
      iban
      bic
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLocationMutationVariables,
  APITypes.CreateLocationMutation
>;
export const updateLocation = /* GraphQL */ `mutation UpdateLocation(
  $input: UpdateLocationInput!
  $condition: ModelLocationConditionInput
) {
  updateLocation(input: $input, condition: $condition) {
    id
    name
    images
    logo
    bio
    bioTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    gallery {
      image
      name
      __typename
    }
    posts {
      nextToken
      __typename
    }
    stripeInfo {
      stripeCostumer
      stripeAccount
      stripeSubscriptionType
      stripeSubscriptionStatus
      stripeDefaultPaymentMethod
      __typename
    }
    invoiceInfo {
      taxCode
      broadcastFormat
      businessName
      taxRegime
      __typename
    }
    openingDay {
      __typename
    }
    deliverectId
    status
    timeZone
    menu {
      nextToken
      __typename
    }
    menuSortOrder
    floors {
      nextToken
      __typename
    }
    credit
    selfOrdering
    externalLinks {
      link
      label
      icon
      __typename
    }
    template
    color
    showCurrency
    showWineList
    address {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    phoneNumber
    social {
      link
      label
      icon
      __typename
    }
    email
    languageDefault
    languagesEnabled
    translationsInProgress
    coverPrice
    deliveryCost
    generalVatRate
    businessLocationsId
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    associatedCorporate {
      nextToken
      __typename
    }
    presences {
      nextToken
      __typename
    }
    bankDetails {
      accountHolder
      iban
      bic
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLocationMutationVariables,
  APITypes.UpdateLocationMutation
>;
export const deleteLocation = /* GraphQL */ `mutation DeleteLocation(
  $input: DeleteLocationInput!
  $condition: ModelLocationConditionInput
) {
  deleteLocation(input: $input, condition: $condition) {
    id
    name
    images
    logo
    bio
    bioTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    gallery {
      image
      name
      __typename
    }
    posts {
      nextToken
      __typename
    }
    stripeInfo {
      stripeCostumer
      stripeAccount
      stripeSubscriptionType
      stripeSubscriptionStatus
      stripeDefaultPaymentMethod
      __typename
    }
    invoiceInfo {
      taxCode
      broadcastFormat
      businessName
      taxRegime
      __typename
    }
    openingDay {
      __typename
    }
    deliverectId
    status
    timeZone
    menu {
      nextToken
      __typename
    }
    menuSortOrder
    floors {
      nextToken
      __typename
    }
    credit
    selfOrdering
    externalLinks {
      link
      label
      icon
      __typename
    }
    template
    color
    showCurrency
    showWineList
    address {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    phoneNumber
    social {
      link
      label
      icon
      __typename
    }
    email
    languageDefault
    languagesEnabled
    translationsInProgress
    coverPrice
    deliveryCost
    generalVatRate
    businessLocationsId
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    associatedCorporate {
      nextToken
      __typename
    }
    presences {
      nextToken
      __typename
    }
    bankDetails {
      accountHolder
      iban
      bic
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLocationMutationVariables,
  APITypes.DeleteLocationMutation
>;
export const createPost = /* GraphQL */ `mutation CreatePost(
  $input: CreatePostInput!
  $condition: ModelPostConditionInput
) {
  createPost(input: $input, condition: $condition) {
    id
    image
    name
    nameTranslations {
      language
      body
      __typename
    }
    userId
    userName
    postLocationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePostMutationVariables,
  APITypes.CreatePostMutation
>;
export const updatePost = /* GraphQL */ `mutation UpdatePost(
  $input: UpdatePostInput!
  $condition: ModelPostConditionInput
) {
  updatePost(input: $input, condition: $condition) {
    id
    image
    name
    nameTranslations {
      language
      body
      __typename
    }
    userId
    userName
    postLocationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePostMutationVariables,
  APITypes.UpdatePostMutation
>;
export const deletePost = /* GraphQL */ `mutation DeletePost(
  $input: DeletePostInput!
  $condition: ModelPostConditionInput
) {
  deletePost(input: $input, condition: $condition) {
    id
    image
    name
    nameTranslations {
      language
      body
      __typename
    }
    userId
    userName
    postLocationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePostMutationVariables,
  APITypes.DeletePostMutation
>;
export const createMenu = /* GraphQL */ `mutation CreateMenu(
  $input: CreateMenuInput!
  $condition: ModelMenuConditionInput
) {
  createMenu(input: $input, condition: $condition) {
    id
    businessId
    menuLocationId
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    dense
    type
    nestedModifiers
    image
    currency
    availabilities {
      dayOfWeek
      startTime
      endTime
      __typename
    }
    disabled
    wineList
    onlyAssociated
    categorySortOrder
    categories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMenuMutationVariables,
  APITypes.CreateMenuMutation
>;
export const updateMenu = /* GraphQL */ `mutation UpdateMenu(
  $input: UpdateMenuInput!
  $condition: ModelMenuConditionInput
) {
  updateMenu(input: $input, condition: $condition) {
    id
    businessId
    menuLocationId
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    dense
    type
    nestedModifiers
    image
    currency
    availabilities {
      dayOfWeek
      startTime
      endTime
      __typename
    }
    disabled
    wineList
    onlyAssociated
    categorySortOrder
    categories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMenuMutationVariables,
  APITypes.UpdateMenuMutation
>;
export const deleteMenu = /* GraphQL */ `mutation DeleteMenu(
  $input: DeleteMenuInput!
  $condition: ModelMenuConditionInput
) {
  deleteMenu(input: $input, condition: $condition) {
    id
    businessId
    menuLocationId
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    dense
    type
    nestedModifiers
    image
    currency
    availabilities {
      dayOfWeek
      startTime
      endTime
      __typename
    }
    disabled
    wineList
    onlyAssociated
    categorySortOrder
    categories {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMenuMutationVariables,
  APITypes.DeleteMenuMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    businessId
    menuCategoriesId
    locationId
    menu {
      id
      businessId
      menuLocationId
      name
      description
      dense
      type
      nestedModifiers
      image
      currency
      disabled
      wineList
      onlyAssociated
      categorySortOrder
      createdAt
      updatedAt
      __typename
    }
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    dense
    image
    posCategoryType
    posCategoryId
    posLocationId
    availabilities {
      dayOfWeek
      startTime
      endTime
      __typename
    }
    level
    sortedChannelProductIds
    subProductSortOrder
    disabled
    wineList
    subCategories {
      nextToken
      __typename
    }
    products {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    categorySubCategoriesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    businessId
    menuCategoriesId
    locationId
    menu {
      id
      businessId
      menuLocationId
      name
      description
      dense
      type
      nestedModifiers
      image
      currency
      disabled
      wineList
      onlyAssociated
      categorySortOrder
      createdAt
      updatedAt
      __typename
    }
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    dense
    image
    posCategoryType
    posCategoryId
    posLocationId
    availabilities {
      dayOfWeek
      startTime
      endTime
      __typename
    }
    level
    sortedChannelProductIds
    subProductSortOrder
    disabled
    wineList
    subCategories {
      nextToken
      __typename
    }
    products {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    categorySubCategoriesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    businessId
    menuCategoriesId
    locationId
    menu {
      id
      businessId
      menuLocationId
      name
      description
      dense
      type
      nestedModifiers
      image
      currency
      disabled
      wineList
      onlyAssociated
      categorySortOrder
      createdAt
      updatedAt
      __typename
    }
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    dense
    image
    posCategoryType
    posCategoryId
    posLocationId
    availabilities {
      dayOfWeek
      startTime
      endTime
      __typename
    }
    level
    sortedChannelProductIds
    subProductSortOrder
    disabled
    wineList
    subCategories {
      nextToken
      __typename
    }
    products {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    categorySubCategoriesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createProduct = /* GraphQL */ `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    businessId
    categories {
      nextToken
      __typename
    }
    locationId
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    dense
    image
    capacityUsages
    deliveryTax
    eatInTax
    takeawayTax
    max
    min
    isVariant
    multiMax
    multiply
    plu
    disabled
    snoozeStart
    snoozeEnd
    price
    posCategoryIds
    posProductCategoryId
    posProductId
    productTags
    productType
    isWine
    wineInfo {
      caption
      year
      perfectFor
      typology
      country
      region
      alcoholPercentage
      servingTemperature
      color
      perfume
      taste
      producer
      productionMethod
      __typename
    }
    priceType
    subProductSortOrder
    subProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductMutationVariables,
  APITypes.CreateProductMutation
>;
export const updateProduct = /* GraphQL */ `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    businessId
    categories {
      nextToken
      __typename
    }
    locationId
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    dense
    image
    capacityUsages
    deliveryTax
    eatInTax
    takeawayTax
    max
    min
    isVariant
    multiMax
    multiply
    plu
    disabled
    snoozeStart
    snoozeEnd
    price
    posCategoryIds
    posProductCategoryId
    posProductId
    productTags
    productType
    isWine
    wineInfo {
      caption
      year
      perfectFor
      typology
      country
      region
      alcoholPercentage
      servingTemperature
      color
      perfume
      taste
      producer
      productionMethod
      __typename
    }
    priceType
    subProductSortOrder
    subProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductMutationVariables,
  APITypes.UpdateProductMutation
>;
export const deleteProduct = /* GraphQL */ `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    businessId
    categories {
      nextToken
      __typename
    }
    locationId
    name
    nameTranslations {
      language
      body
      __typename
    }
    description
    descriptionTranslations {
      language
      body
      __typename
    }
    dense
    image
    capacityUsages
    deliveryTax
    eatInTax
    takeawayTax
    max
    min
    isVariant
    multiMax
    multiply
    plu
    disabled
    snoozeStart
    snoozeEnd
    price
    posCategoryIds
    posProductCategoryId
    posProductId
    productTags
    productType
    isWine
    wineInfo {
      caption
      year
      perfectFor
      typology
      country
      region
      alcoholPercentage
      servingTemperature
      color
      perfume
      taste
      producer
      productionMethod
      __typename
    }
    priceType
    subProductSortOrder
    subProducts {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductMutationVariables,
  APITypes.DeleteProductMutation
>;
export const createCategoriesProducts = /* GraphQL */ `mutation CreateCategoriesProducts(
  $input: CreateCategoriesProductsInput!
  $condition: ModelCategoriesProductsConditionInput
) {
  createCategoriesProducts(input: $input, condition: $condition) {
    id
    locationId
    productID
    categoryID
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    category {
      id
      businessId
      menuCategoriesId
      locationId
      name
      description
      dense
      image
      posCategoryType
      posCategoryId
      posLocationId
      level
      sortedChannelProductIds
      subProductSortOrder
      disabled
      wineList
      createdAt
      updatedAt
      categorySubCategoriesId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoriesProductsMutationVariables,
  APITypes.CreateCategoriesProductsMutation
>;
export const updateCategoriesProducts = /* GraphQL */ `mutation UpdateCategoriesProducts(
  $input: UpdateCategoriesProductsInput!
  $condition: ModelCategoriesProductsConditionInput
) {
  updateCategoriesProducts(input: $input, condition: $condition) {
    id
    locationId
    productID
    categoryID
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    category {
      id
      businessId
      menuCategoriesId
      locationId
      name
      description
      dense
      image
      posCategoryType
      posCategoryId
      posLocationId
      level
      sortedChannelProductIds
      subProductSortOrder
      disabled
      wineList
      createdAt
      updatedAt
      categorySubCategoriesId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoriesProductsMutationVariables,
  APITypes.UpdateCategoriesProductsMutation
>;
export const deleteCategoriesProducts = /* GraphQL */ `mutation DeleteCategoriesProducts(
  $input: DeleteCategoriesProductsInput!
  $condition: ModelCategoriesProductsConditionInput
) {
  deleteCategoriesProducts(input: $input, condition: $condition) {
    id
    locationId
    productID
    categoryID
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    category {
      id
      businessId
      menuCategoriesId
      locationId
      name
      description
      dense
      image
      posCategoryType
      posCategoryId
      posLocationId
      level
      sortedChannelProductIds
      subProductSortOrder
      disabled
      wineList
      createdAt
      updatedAt
      categorySubCategoriesId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoriesProductsMutationVariables,
  APITypes.DeleteCategoriesProductsMutation
>;
export const createSubProducts = /* GraphQL */ `mutation CreateSubProducts(
  $input: CreateSubProductsInput!
  $condition: ModelSubProductsConditionInput
) {
  createSubProducts(input: $input, condition: $condition) {
    id
    businessId
    locationId
    productId
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    products {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubProductsMutationVariables,
  APITypes.CreateSubProductsMutation
>;
export const updateSubProducts = /* GraphQL */ `mutation UpdateSubProducts(
  $input: UpdateSubProductsInput!
  $condition: ModelSubProductsConditionInput
) {
  updateSubProducts(input: $input, condition: $condition) {
    id
    businessId
    locationId
    productId
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    products {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubProductsMutationVariables,
  APITypes.UpdateSubProductsMutation
>;
export const deleteSubProducts = /* GraphQL */ `mutation DeleteSubProducts(
  $input: DeleteSubProductsInput!
  $condition: ModelSubProductsConditionInput
) {
  deleteSubProducts(input: $input, condition: $condition) {
    id
    businessId
    locationId
    productId
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    products {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubProductsMutationVariables,
  APITypes.DeleteSubProductsMutation
>;
export const createProductsSubProducts = /* GraphQL */ `mutation CreateProductsSubProducts(
  $input: CreateProductsSubProductsInput!
  $condition: ModelProductsSubProductsConditionInput
) {
  createProductsSubProducts(input: $input, condition: $condition) {
    id
    locationId
    productID
    subProductsID
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    subProducts {
      id
      businessId
      locationId
      productId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductsSubProductsMutationVariables,
  APITypes.CreateProductsSubProductsMutation
>;
export const updateProductsSubProducts = /* GraphQL */ `mutation UpdateProductsSubProducts(
  $input: UpdateProductsSubProductsInput!
  $condition: ModelProductsSubProductsConditionInput
) {
  updateProductsSubProducts(input: $input, condition: $condition) {
    id
    locationId
    productID
    subProductsID
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    subProducts {
      id
      businessId
      locationId
      productId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductsSubProductsMutationVariables,
  APITypes.UpdateProductsSubProductsMutation
>;
export const deleteProductsSubProducts = /* GraphQL */ `mutation DeleteProductsSubProducts(
  $input: DeleteProductsSubProductsInput!
  $condition: ModelProductsSubProductsConditionInput
) {
  deleteProductsSubProducts(input: $input, condition: $condition) {
    id
    locationId
    productID
    subProductsID
    product {
      id
      businessId
      locationId
      name
      description
      dense
      image
      capacityUsages
      deliveryTax
      eatInTax
      takeawayTax
      max
      min
      isVariant
      multiMax
      multiply
      plu
      disabled
      snoozeStart
      snoozeEnd
      price
      posCategoryIds
      posProductCategoryId
      posProductId
      productTags
      productType
      isWine
      priceType
      subProductSortOrder
      createdAt
      updatedAt
      __typename
    }
    subProducts {
      id
      businessId
      locationId
      productId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductsSubProductsMutationVariables,
  APITypes.DeleteProductsSubProductsMutation
>;
export const createFloor = /* GraphQL */ `mutation CreateFloor(
  $input: CreateFloorInput!
  $condition: ModelFloorConditionInput
) {
  createFloor(input: $input, condition: $condition) {
    id
    businessId
    locationFloorsId
    name
    tables {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFloorMutationVariables,
  APITypes.CreateFloorMutation
>;
export const updateFloor = /* GraphQL */ `mutation UpdateFloor(
  $input: UpdateFloorInput!
  $condition: ModelFloorConditionInput
) {
  updateFloor(input: $input, condition: $condition) {
    id
    businessId
    locationFloorsId
    name
    tables {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFloorMutationVariables,
  APITypes.UpdateFloorMutation
>;
export const deleteFloor = /* GraphQL */ `mutation DeleteFloor(
  $input: DeleteFloorInput!
  $condition: ModelFloorConditionInput
) {
  deleteFloor(input: $input, condition: $condition) {
    id
    businessId
    locationFloorsId
    name
    tables {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFloorMutationVariables,
  APITypes.DeleteFloorMutation
>;
export const createTable = /* GraphQL */ `mutation CreateTable(
  $input: CreateTableInput!
  $condition: ModelTableConditionInput
) {
  createTable(input: $input, condition: $condition) {
    id
    businessId
    locationId
    name
    seats
    activeOrder {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      deliveryCost
      phoneNumber
      createdAt
      updatedAt
      orderTableId
      __typename
    }
    createdAt
    updatedAt
    floorTablesId
    tableActiveOrderId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTableMutationVariables,
  APITypes.CreateTableMutation
>;
export const updateTable = /* GraphQL */ `mutation UpdateTable(
  $input: UpdateTableInput!
  $condition: ModelTableConditionInput
) {
  updateTable(input: $input, condition: $condition) {
    id
    businessId
    locationId
    name
    seats
    activeOrder {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      deliveryCost
      phoneNumber
      createdAt
      updatedAt
      orderTableId
      __typename
    }
    createdAt
    updatedAt
    floorTablesId
    tableActiveOrderId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTableMutationVariables,
  APITypes.UpdateTableMutation
>;
export const deleteTable = /* GraphQL */ `mutation DeleteTable(
  $input: DeleteTableInput!
  $condition: ModelTableConditionInput
) {
  deleteTable(input: $input, condition: $condition) {
    id
    businessId
    locationId
    name
    seats
    activeOrder {
      id
      businessId
      locationId
      note
      type
      orderItemsSortOrder
      subTotal
      total
      paid
      ristoQuickStatus
      status
      userId
      generalVatRate
      deliveryCost
      phoneNumber
      createdAt
      updatedAt
      orderTableId
      __typename
    }
    createdAt
    updatedAt
    floorTablesId
    tableActiveOrderId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTableMutationVariables,
  APITypes.DeleteTableMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    businessId
    locationId
    table {
      id
      businessId
      locationId
      name
      seats
      createdAt
      updatedAt
      floorTablesId
      tableActiveOrderId
      __typename
    }
    destination {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    note
    billingInfo {
      email
      phoneNumber
      firstName
      lastName
      companyName
      vatNumber
      sdi
      __typename
    }
    type
    orderItems {
      nextToken
      __typename
    }
    orderItemsSortOrder
    subTotal
    total
    paid
    deliverectParams {
      id
      type
      isAlreadyPaid
      paymentType
      paymentAmount
      decimalDigits
      status
      __typename
    }
    ristoQuickStatus
    status
    lastPaymentsRistoQuick {
      nextToken
      __typename
    }
    payments {
      nextToken
      __typename
    }
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    userId
    user {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    generalVatRate
    deliveryCost
    phoneNumber
    createdAt
    updatedAt
    orderTableId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    businessId
    locationId
    table {
      id
      businessId
      locationId
      name
      seats
      createdAt
      updatedAt
      floorTablesId
      tableActiveOrderId
      __typename
    }
    destination {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    note
    billingInfo {
      email
      phoneNumber
      firstName
      lastName
      companyName
      vatNumber
      sdi
      __typename
    }
    type
    orderItems {
      nextToken
      __typename
    }
    orderItemsSortOrder
    subTotal
    total
    paid
    deliverectParams {
      id
      type
      isAlreadyPaid
      paymentType
      paymentAmount
      decimalDigits
      status
      __typename
    }
    ristoQuickStatus
    status
    lastPaymentsRistoQuick {
      nextToken
      __typename
    }
    payments {
      nextToken
      __typename
    }
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    userId
    user {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    generalVatRate
    deliveryCost
    phoneNumber
    createdAt
    updatedAt
    orderTableId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    businessId
    locationId
    table {
      id
      businessId
      locationId
      name
      seats
      createdAt
      updatedAt
      floorTablesId
      tableActiveOrderId
      __typename
    }
    destination {
      street
      houseNumber
      postalCode
      city
      province
      country
      __typename
    }
    note
    billingInfo {
      email
      phoneNumber
      firstName
      lastName
      companyName
      vatNumber
      sdi
      __typename
    }
    type
    orderItems {
      nextToken
      __typename
    }
    orderItemsSortOrder
    subTotal
    total
    paid
    deliverectParams {
      id
      type
      isAlreadyPaid
      paymentType
      paymentAmount
      decimalDigits
      status
      __typename
    }
    ristoQuickStatus
    status
    lastPaymentsRistoQuick {
      nextToken
      __typename
    }
    payments {
      nextToken
      __typename
    }
    location {
      id
      name
      images
      logo
      bio
      description
      deliverectId
      status
      timeZone
      menuSortOrder
      credit
      selfOrdering
      template
      color
      showCurrency
      showWineList
      phoneNumber
      email
      languageDefault
      languagesEnabled
      translationsInProgress
      coverPrice
      deliveryCost
      generalVatRate
      businessLocationsId
      createdAt
      updatedAt
      __typename
    }
    business {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    userId
    user {
      id
      email
      type
      coin
      totalCoin
      stripeCustomerId
      firstName
      businessName
      taxCode
      sdi
      lastName
      fiscalCode
      gender
      phoneNumber
      createdAt
      updatedAt
      __typename
    }
    generalVatRate
    deliveryCost
    phoneNumber
    createdAt
    updatedAt
    orderTableId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const payOrder = /* GraphQL */ `mutation PayOrder(
  $orderID: ID!
  $payAmount: Int!
  $useCoin: Boolean!
  $invoiceUserAttribues: InvoiceUserAttributes
) {
  payOrder(
    orderID: $orderID
    payAmount: $payAmount
    useCoin: $useCoin
    invoiceUserAttribues: $invoiceUserAttribues
  ) {
    ... on PayOrderResponseSuccess {
      orderID
      clientSecret
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.PayOrderMutationVariables,
  APITypes.PayOrderMutation
>;
export const stripeUpdateAccount = /* GraphQL */ `mutation StripeUpdateAccount($data: StripeAccountData!) {
  stripeUpdateAccount(data: $data) {
    ... on StripeUpdateAccountResponseSuccess {
      success
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.StripeUpdateAccountMutationVariables,
  APITypes.StripeUpdateAccountMutation
>;
export const stripeHandlePaymentMethod = /* GraphQL */ `mutation StripeHandlePaymentMethod(
  $type: Crud!
  $paymentMethodId: String!
  $locationId: String
  $defaultSource: Boolean
) {
  stripeHandlePaymentMethod(
    type: $type
    paymentMethodId: $paymentMethodId
    locationId: $locationId
    defaultSource: $defaultSource
  ) {
    ... on StripeHandlePaymentMethodResponseSuccess {
      success
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.StripeHandlePaymentMethodMutationVariables,
  APITypes.StripeHandlePaymentMethodMutation
>;
export const stripeHandleCancelSubscription = /* GraphQL */ `mutation StripeHandleCancelSubscription($type: Crud!, $locationId: String) {
  stripeHandleCancelSubscription(type: $type, locationId: $locationId) {
    ... on StripeHandleCancelSubscriptionResponseSuccess {
      success
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.StripeHandleCancelSubscriptionMutationVariables,
  APITypes.StripeHandleCancelSubscriptionMutation
>;
export const stripeChangePlanSubscription = /* GraphQL */ `mutation StripeChangePlanSubscription(
  $subscriptionType: StripeSubscriptionType!
  $locationId: String
) {
  stripeChangePlanSubscription(
    subscriptionType: $subscriptionType
    locationId: $locationId
  ) {
    ... on StripeChangePlanSubscriptionResponseSuccess {
      success
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.StripeChangePlanSubscriptionMutationVariables,
  APITypes.StripeChangePlanSubscriptionMutation
>;
export const translateLocation = /* GraphQL */ `mutation TranslateLocation($locationID: String!, $languages: [String]!) {
  translateLocation(locationID: $locationID, languages: $languages) {
    ... on TranslateLocationResponseSuccess {
      success
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.TranslateLocationMutationVariables,
  APITypes.TranslateLocationMutation
>;
export const storePresenceKey = /* GraphQL */ `mutation StorePresenceKey($locationID: String!, $presenceKey: String!) {
  storePresenceKey(locationID: $locationID, presenceKey: $presenceKey) {
    ... on StorePresenceKeyResponseSuccess {
      userId
      status
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.StorePresenceKeyMutationVariables,
  APITypes.StorePresenceKeyMutation
>;
export const corporateInviteAssociateds = /* GraphQL */ `mutation CorporateInviteAssociateds(
  $corporateID: String!
  $userEmails: [String!]!
) {
  corporateInviteAssociateds(
    corporateID: $corporateID
    userEmails: $userEmails
  ) {
    ... on CorporateInviteAssociatedsResponseSuccess {
      success
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.CorporateInviteAssociatedsMutationVariables,
  APITypes.CorporateInviteAssociatedsMutation
>;
export const createOrderNotPaid = /* GraphQL */ `mutation CreateOrderNotPaid(
  $locationID: String!
  $serviceType: OrderType!
  $billingInfo: BillingInfoInput!
  $orderItems: [OrderItemInput!]!
  $note: String
) {
  createOrderNotPaid(
    locationID: $locationID
    serviceType: $serviceType
    billingInfo: $billingInfo
    orderItems: $orderItems
    note: $note
  ) {
    ... on CreateOrderNotPaidResponseSuccess {
      orderId
      success
    }
    ... on ErrorResponse {
      error
      code
    }
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderNotPaidMutationVariables,
  APITypes.CreateOrderNotPaidMutation
>;

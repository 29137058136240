import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import * as api from 'src/backend/API';
import { listCategories } from 'src/backend/graphql/queries';
import { getCategoryQuery, getMenuQuery } from 'src/backend/queries';
import * as mutations from 'src/backend/graphql/mutations';
import { callGraphQL } from 'src/utils/api';
// hooks
import { updateMenuApi } from './menu';

export async function getCategoriesByMenuApi(menuId: string) {
  try {
    const menuQuery = (await API.graphql(graphqlOperation(getMenuQuery, { id: menuId }))) as {
      data: any;
    };

    if (!menuQuery || !menuQuery.data || !menuQuery.data.getMenu) throw new Error('No menu found');

    const sortCategories = menuQuery.data.getMenu.categories.items.sort(
      (a: api.Menu, b: api.Menu) => {
        const indexA = menuQuery.data.getMenu.categorySortOrder.indexOf(a.id);
        const indexB = menuQuery.data.getMenu.categorySortOrder.indexOf(b.id);
        return indexA - indexB;
      }
    );

    return sortCategories as api.Category[];
  } catch (err) {
    console.log('err', err);

    return null;
  }
}

export async function updateCategoriesApi(newCategoriesData: api.Category[]) {
  try {
    if (newCategoriesData.length > 0) {
      // eslint-disable-next-line guard-for-in
      for (const newCategoryData in newCategoriesData) {
        // eslint-disable-next-line no-await-in-loop
        const updateCategory = await API.graphql<GraphQLQuery<any>>({
          query: mutations.updateCategory,
          variables: {
            input: {
              ...newCategoriesData[newCategoryData],
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        if (!updateCategory || !updateCategory.data) throw new Error('Update error');
      }
    }
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function getCategoryApi(categoryId: string) {
  try {
    const data = (await API.graphql(graphqlOperation(getCategoryQuery, { id: categoryId }))) as {
      data: any;
    };
    if (!data || !data.data || !data.data.getCategory) throw new Error('No category found');

    return data.data.getCategory;
  } catch (err) {
    return null;
  }
}

export async function createCategoryApi(categoryId: string, createData: any) {
  try {
    const createCategory = await API.graphql<GraphQLQuery<any>>({
      query: mutations.createCategory,
      variables: {
        input: {
          id: categoryId,
          subProductSortOrder: [],
          ...createData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    console.log('createCategory', createCategory);
    if (!createCategory.data) throw new Error('Error create category');

    const order: string[] = createCategory.data.createCategory.menu.categorySortOrder;

    order.push(categoryId);

    const updateSortOrder = await updateMenuApi(createCategory.data.createCategory.menu.id, {
      categorySortOrder: order,
    });

    if (updateSortOrder) {
      return true;
    }
    throw new Error('Update error');
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function updateCategoryApi(categoryId: string, updateData: any) {
  try {
    const updateCategoryQuery = await API.graphql<GraphQLQuery<any>>({
      query: mutations.updateCategory,
      variables: {
        input: {
          id: categoryId,
          ...updateData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (updateCategoryQuery && updateCategoryQuery.data) {
      return true;
    }
    throw new Error('Update error');
  } catch (err) {
    return false;
  }
}

export async function deleteCategoryApi(categoryId: string) {
  try {
    const deleteCategory = await API.graphql<GraphQLQuery<any>>({
      query: mutations.deleteCategory,
      variables: {
        input: {
          id: categoryId,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (!deleteCategory.data) throw new Error('Error create menu');

    const order: string[] = deleteCategory.data.deleteCategory.menu.categorySortOrder;

    const updateSortOrder = await updateMenuApi(deleteCategory.data.deleteCategory.menu.id, {
      categorySortOrder: order.filter((stringa) => stringa !== categoryId),
    });

    if (updateSortOrder) {
      return true;
    }
    throw new Error('Update error');
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function getCategoriesByLocation(locationId: string) {
  try {
    const listAllCategory = await callGraphQL(listCategories, {
      filter: { locationId: { eq: locationId } },
    });
    if (!listAllCategory || !listAllCategory.data || !listAllCategory.data.listCategories)
      throw new Error('No categories found');

    return listAllCategory.data.listCategories.items;
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

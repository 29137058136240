import { API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { Predictions } from '@aws-amplify/predictions';

export async function callGraphQL(query: any, variables: any = {}, count: any = null) {
  const tempVariables = { ...variables };

  try {
    const response = await API.graphql<GraphQLQuery<any>>({
      query,
      variables: tempVariables,
    });

    if (!response.data) return response;

    const nextTokenValue = response.data?.[Object.keys(response.data)[0]]?.nextToken;

    if (nextTokenValue) {
      tempVariables.nextToken = nextTokenValue;
      const nextResponse: any = await callGraphQL(query, tempVariables);

      return {
        data: {
          [Object.keys(response.data)[0]]: {
            items: response.data?.[Object.keys(response.data)[0]]?.items.concat(
              nextResponse.data?.[Object.keys(nextResponse.data)[0]]?.items
            ),
            nextToken: nextResponse.data?.[Object.keys(nextResponse.data)[0]]?.nextToken,
            __typename: nextResponse.data?.[Object.keys(nextResponse.data)[0]]?.__typename,
          },
        },
      };
    }
    return response;
  } catch (error) {
    return error;
  }
}

export async function translateAndSaveItems(items: any, language: any, update: any) {
  // eslint-disable-next-line guard-for-in
  for (const item in items) {
    // eslint-disable-next-line no-await-in-loop
    const tradName = await Predictions.convert({
      translateText: {
        source: {
          text: items[item].name,
          language: 'it',
        },
        targetLanguage: language,
      },
    });

    let tradDesc = null;
    if (items[item].description) {
      // eslint-disable-next-line no-await-in-loop
      tradDesc = await Predictions.convert({
        translateText: {
          source: {
            text: items[item].description!,
            language: 'it',
          },
          targetLanguage: language,
        },
      });
    }

    const currentTradName = items[item].nameTranslations || [];

    // Trova l'indice dell'oggetto con la stessa lingua
    const indexTradName = currentTradName.findIndex(
      (itemTradName: any) => itemTradName.language === language
    );
    // Se l'oggetto con la stessa lingua esiste
    if (indexTradName !== -1) {
      currentTradName.splice(indexTradName, 1); // Rimuovi l'elemento
    }
    currentTradName.push({
      language,
      body: tradName.text,
      __typename: 'Translation',
    });

    const currentTradDesc = items[item].descriptionTranslations || [];

    if (tradDesc) {
      // Trova l'indice dell'oggetto con la stessa lingua
      const indexTradDesc = currentTradDesc.findIndex(
        (itemTradDesc: any) => itemTradDesc.language === language
      );
      // Se l'oggetto con la stessa lingua esiste
      if (indexTradDesc !== -1) {
        currentTradDesc.splice(indexTradDesc, 1); // Rimuovi l'elemento
      }
      currentTradDesc.push({
        language,
        body: tradDesc.text,
        __typename: 'Translation',
      });
    }

    const newItem = {
      nameTranslations: currentTradName.map((nameTranslations: any) => ({
        language: nameTranslations?.language,
        body: nameTranslations?.body,
      })),
      descriptionTranslations: currentTradDesc
        ? currentTradDesc.map((descTranslations: any) => ({
            language: descTranslations?.language,
            body: descTranslations?.body,
          }))
        : [],
    };

    // eslint-disable-next-line no-await-in-loop
    await update(newItem, items[item].id);
    console.log(item, items.length);
  }
}

export async function translateAndSaveItem(item: any, language: any, update: any) {
  console.log('item', item);
  console.log('language', language);
  // eslint-disable-next-line no-await-in-loop
  const tradName = await Predictions.convert({
    translateText: {
      source: {
        text: item.name,
        language: 'auto',
      },
      targetLanguage: language,
    },
  });

  let tradDesc = null;
  if (item.description) {
    // eslint-disable-next-line no-await-in-loop
    tradDesc = await Predictions.convert({
      translateText: {
        source: {
          text: item.description!,
          language: 'auto',
        },
        targetLanguage: language,
      },
    });
  }

  const currentTradName = item.nameTranslations || [];

  // Trova l'indice dell'oggetto con la stessa lingua
  const indexTradName = currentTradName.findIndex(
    (itemTradName: any) => itemTradName.language === language
  );
  // Se l'oggetto con la stessa lingua esiste
  if (indexTradName !== -1) {
    currentTradName.splice(indexTradName, 1); // Rimuovi l'elemento
  }
  currentTradName.push({
    language,
    body: tradName.text,
    __typename: 'Translation',
  });

  const currentTradDesc = item.descriptionTranslations || [];

  if (tradDesc) {
    // Trova l'indice dell'oggetto con la stessa lingua
    const indexTradDesc = currentTradDesc.findIndex(
      (itemTradDesc: any) => itemTradDesc.language === language
    );
    // Se l'oggetto con la stessa lingua esiste
    if (indexTradDesc !== -1) {
      currentTradDesc.splice(indexTradDesc, 1); // Rimuovi l'elemento
    }
    currentTradDesc.push({
      language,
      body: tradDesc.text,
      __typename: 'Translation',
    });
  }

  const newItem = {
    nameTranslations: currentTradName.map((nameTranslations: any) => ({
      language: nameTranslations?.language,
      body: nameTranslations?.body,
    })),
    descriptionTranslations: currentTradDesc
      ? currentTradDesc.map((descTranslations: any) => ({
          language: descTranslations?.language,
          body: descTranslations?.body,
        }))
      : [],
  };
  return newItem;
}

import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { getUserQuery } from 'src/backend/queries';
import * as api from 'src/backend/API';
import * as queries from 'src/backend/graphql/queries';
import * as mutations from 'src/backend/graphql/mutations';

export async function getUserApi(userId: string) {
  try {
    const userQuery = (await API.graphql({
      ...graphqlOperation(getUserQuery, {
        id: userId,
      }),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as {
      data: any;
    };

    console.log('userQuery', userQuery);

    if (!userQuery || !userQuery.data || !userQuery.data.getUser) throw new Error('No user found');

    return userQuery.data.getUser as api.User;
  } catch (err) {
    console.log('err', err);

    return null;
  }
}

export async function updateUserAttributesApi(userId: string, updateData: any) {
  try {
    console.log('userId', userId);
    console.log('updateData', updateData);
    const updateUser = await API.graphql<GraphQLQuery<any>>({
      query: mutations.updateUser,
      variables: {
        input: {
          id: userId,
          ...updateData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (updateUser && updateUser.data) {
      return updateUser.data.updateUser;
    }
    throw new Error('Update error');
  } catch (err) {
    console.log('err', err);
    return null;
  }
}

export async function getUserPresenceKey() {
  try {
    const getPresenceKeyQuery = (await API.graphql({
      ...graphqlOperation(queries.getPresenceKey),
    })) as any;

    console.log('getPresenceKeyQuery', getPresenceKeyQuery.data.getPresenceKey);

    if (
      !getPresenceKeyQuery ||
      !getPresenceKeyQuery.data ||
      !getPresenceKeyQuery.data.getPresenceKey
    )
      throw new Error('Error on get presence key');

    return getPresenceKeyQuery.data.getPresenceKey;
  } catch (err) {
    return null;
  }
}

export async function getUserPaymentMethods() {
  try {
    const getUserPaymentMethodsQuery = (await API.graphql({
      ...graphqlOperation(queries.getUserPaymentMethods),
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as any;

    console.log('getPresenceKeyQuery', getUserPaymentMethodsQuery.data.getPresenceKey);

    if (
      !getUserPaymentMethodsQuery ||
      !getUserPaymentMethodsQuery.data ||
      !getUserPaymentMethodsQuery.data.getUserPaymentMethods
    )
      throw new Error('Error on get user payment methods');

    return getUserPaymentMethodsQuery.data.getUserPaymentMethods;
  } catch (err) {
    return null;
  }
}

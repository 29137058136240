import { AUTH_PATH } from 'src/config-global';

// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: AUTH_PATH,
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  page404: '/404',
  // AUTH
  auth: {
    root: ROOTS.AUTH,
    login: `${ROOTS.AUTH}/login`,
    verify: `${ROOTS.AUTH}/verify`,
    register: `${ROOTS.AUTH}/register`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    products: {
      root: `${ROOTS.DASHBOARD}/products`,
      menu: {
        root: `${ROOTS.DASHBOARD}/products/menu`,
        new: `${ROOTS.DASHBOARD}/products/menu/new`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/products/menu/${id}/edit`,
        categories: {
          root: (menuId: string) => `${ROOTS.DASHBOARD}/products/menu/${menuId}/categories`,
          new: (menuId: string) => `${ROOTS.DASHBOARD}/products/menu/${menuId}/categories/new`,
          edit: (menuId: string, id: string) =>
            `${ROOTS.DASHBOARD}/products/menu/${menuId}/categories/${id}/edit`,
          products: {
            root: (menuId: string, categoryId: string) =>
              `${ROOTS.DASHBOARD}/products/menu/${menuId}/categories/${categoryId}/products`,
            edit: (menuId: string, categoryId: string, id: string) =>
              `${ROOTS.DASHBOARD}/products/menu/${menuId}/categories/${categoryId}/products/${id}/edit`,
          },
        },
      },

      wines: {
        root: `${ROOTS.DASHBOARD}/products/wines`,
        new: `${ROOTS.DASHBOARD}/products/wines/new`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/products/wines/${id}/edit`,
        categories: {
          root: (menuId: string) => `${ROOTS.DASHBOARD}/products/wines/${menuId}/categories`,
          new: (menuId: string) => `${ROOTS.DASHBOARD}/products/wines/${menuId}/categories/new`,
          edit: (menuId: string, id: string) =>
            `${ROOTS.DASHBOARD}/products/wines/${menuId}/categories/${id}/edit`,
          products: {
            root: (menuId: string, categoryId: string) =>
              `${ROOTS.DASHBOARD}/products/wines/${menuId}/categories/${categoryId}/products`,
            edit: (menuId: string, categoryId: string, id: string) =>
              `${ROOTS.DASHBOARD}/products/wines/${menuId}/categories/${categoryId}/products/${id}/edit`,
          },
        },
      },

      product: {
        root: `${ROOTS.DASHBOARD}/products/product`,
        new: `${ROOTS.DASHBOARD}/products/product/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/products/product/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/products/product/${id}/edit`,
      },
      variant: {
        root: `${ROOTS.DASHBOARD}/products/variant`,
        new: `${ROOTS.DASHBOARD}/products/variant/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/products/variant/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/products/variant/${id}/edit`,
      },
      modifiersGroup: {
        root: `${ROOTS.DASHBOARD}/products/modifiersGroup`,
        new: `${ROOTS.DASHBOARD}/products/modifiersGroup/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/products/modifiersGroup/${id}`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/products/modifiersGroup/${id}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
    },
    analytics: {
      root: `${ROOTS.DASHBOARD}/analytics`,
      corporate: `${ROOTS.DASHBOARD}/analytics/corporate`,
    },
    orders: {
      root: `${ROOTS.DASHBOARD}/orders`,
    },
    qrcode: {
      root: `${ROOTS.DASHBOARD}/qrcode`,
      corporate: `${ROOTS.DASHBOARD}/qrcode/corporate`,
    },
    export: {
      root: `${ROOTS.DASHBOARD}/export`,
    },
    location: {
      root: `${ROOTS.DASHBOARD}/location`,
      deafult: `${ROOTS.DASHBOARD}/location/select`,
      select: (businessId: string, locationId: string) =>
        `${ROOTS.DASHBOARD}/location/select/${businessId}/${locationId}`,
      account: {
        root: `${ROOTS.DASHBOARD}/location/account`,
        profile: {
          root: `${ROOTS.DASHBOARD}/location/account/profile`,
          tab: (tab: string) => `${ROOTS.DASHBOARD}/location/account/profile?tab=${tab}`,
        },
        billing: {
          root: `${ROOTS.DASHBOARD}/location/account/billing`,
          tab: (tab: string) => `${ROOTS.DASHBOARD}/location/account/billing?tab=${tab}`,
        },
        translations: `${ROOTS.DASHBOARD}/location/account/translations`,
        settings: `${ROOTS.DASHBOARD}/location/account/settings`,
      },
    },
    associated: {
      root: `${ROOTS.DASHBOARD}/associated`,
      locations: {
        root: `${ROOTS.DASHBOARD}/associated/locations`,
      },
      people: {
        root: `${ROOTS.DASHBOARD}/associated/people`,
      },
    },
  },
};

import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/hooks';
import { useSettingsAuthContext } from 'src/auth/settings';
import { NavListProps } from 'src/components/nav-section';
// api
import { ApiTypes } from 'src/backend';

const overviewPath = (user: any, selectedLocation: any, t: any, onUpdate: any) => {
  const locPath: { subheader: string; items: NavListProps[] } = {
    subheader: t('overview'),
    items: [
      {
        title: t('products'),
        icon: <Iconify icon="tabler:tag" />,
        path: paths.dashboard.products.root,
        disabled: selectedLocation?.locationStatus !== ApiTypes.LocationStatus.active,
        children: [
          {
            title: t('menu'),
            path: paths.dashboard.products.menu.root,
          },
          {
            title: t('wines'),
            path: paths.dashboard.products.wines.root,
          },
          {
            title: t('products'),
            path: paths.dashboard.products.product.root,
          },
          {
            title: 'Gruppi di varianti',
            path: paths.dashboard.products.variant.root,
          },

          /* {
            title: 'Gruppi di modificatori',
            path: paths.dashboard.products.modifiersGroup.root,
          }, */
        ],
      },
      {
        title: 'post',
        icon: <Iconify icon="tabler:photo" />,
        path: paths.dashboard.post.root,
        disabled: selectedLocation?.locationStatus !== ApiTypes.LocationStatus.active,
      },
      {
        title: 'analytics',
        icon: <Iconify icon="tabler:chart-histogram" />,
        path: paths.dashboard.analytics.root,
      },
      {
        title: 'QR Code',
        icon: <Iconify icon="tabler:qrcode" />,
        path: paths.dashboard.qrcode.root,
      },
      {
        title: 'Export',
        icon: <Iconify icon="tabler:file-export" />,
        path: paths.dashboard.export.root,
        disabled: selectedLocation?.locationStatus !== ApiTypes.LocationStatus.active,
      },
      {
        title: 'Orders',
        icon: <Iconify icon="tabler:shopping-cart" />,
        path: paths.dashboard.orders.root,
        disabled: selectedLocation?.locationStatus !== ApiTypes.LocationStatus.active,
      },
    ],
  };

  if (user && user.locations && selectedLocation && selectedLocation.locationId) {
    let select = user.locations.find((l: any) => l.locationId === selectedLocation.locationId);
    if (!select) {
      onUpdate('selectedLocation', {
        businessId: user.locations[0].businessId,
        locationId: user.locations[0].locationId,
      });

      select = user.locations[0];
    }
    let home: NavListProps;
    if (user.locations.length > 1) {
      const child = user.locations.map((l: any) => ({
        title: l.locationName,
        path:
          select.locationId === l.locationId
            ? paths.dashboard.root
            : paths.dashboard.location.select(l.businessId, l.locationId),
      }));
      home = {
        title: 'Home',
        path: paths.dashboard.location.select(select.businessId, select.locationId),
        icon: <Iconify icon="tabler:home" />,
        children: child,
      };
    } else {
      home = {
        title: 'Home',
        path: paths.dashboard.location.select(select.businessId, select.locationId),
        icon: <Iconify icon="tabler:home" />,
      };
    }

    locPath.items.unshift(home);
  }

  return locPath;
};

export function Role0100Navigation() {
  const { user } = useAuthContext();
  const authSettings = useSettingsAuthContext();
  const { t } = useLocales();

  const data: { subheader: string; items: NavListProps[] }[] = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------

      overviewPath(user, authSettings.selectedLocation, t, authSettings.onUpdate),

      // LOCATION
      // ----------------------------------------------------------------------
      {
        subheader: 'settings',
        items: [
          {
            title: 'Account',
            path: paths.dashboard.location.account.root,
            icon: <Iconify icon="tabler:user" />,
            children: [
              {
                title: 'profile',
                path: paths.dashboard.location.account.profile.root,
                disabled:
                  authSettings.selectedLocation?.locationStatus !== ApiTypes.LocationStatus.active,
              },
              {
                title: 'billing',
                path: paths.dashboard.location.account.billing.root,
              },
              {
                title: 'translations',
                path: paths.dashboard.location.account.translations,
                disabled:
                  authSettings.selectedLocation?.locationStatus !== ApiTypes.LocationStatus.active,
              },
              {
                title: 'settings',
                path: paths.dashboard.location.account.settings,
                disabled:
                  authSettings.selectedLocation?.locationStatus !== ApiTypes.LocationStatus.active,
              },
            ],
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, authSettings.selectedLocation]
  );

  return data;
}

import { useState, useEffect, useCallback } from 'react';

const apiMiddleware = (apiFunction: any) => ({
  promise: (...args: any) => apiFunction(...args),

  useHook: (...hookArgs: any) => {
    const [state, setState] = useState<{ loading: boolean; error: any; data: any }>({
      loading: true,
      error: null,
      data: null,
    });

    // Definisci una funzione "fetchData" con useCallback per memorizzarla
    // e prevenire la creazione di una nuova funzione ad ogni render.
    // Questo aiuta a mantenere stabile la lista delle dipendenze dell'`useEffect`.
    const fetchData = useCallback(() => {
      setState({ loading: true, error: null, data: null });
      apiFunction(...hookArgs)
        .then((data: any) => setState({ loading: false, data, error: null }))
        .catch((error: any) => setState({ loading: false, data: null, error }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...hookArgs]); // Le dipendenze includono tutti gli argomenti passati all'hook.

    useEffect(() => {
      fetchData();
    }, [fetchData]); // La dipendenza ora è solo `fetchData`.

    // Espone `fetchData` insieme allo stato per permettere ai componenti di rifare la query.
    return { ...state, refetch: fetchData };
  },
});

export default apiMiddleware;

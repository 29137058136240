import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { getLocationPostQuery } from 'src/backend/queries';
import * as mutations from 'src/backend/graphql/mutations';

export async function getPostsByLocationApi(locationId: string) {
  try {
    const location = (await API.graphql(
      graphqlOperation(getLocationPostQuery, { id: locationId })
    )) as {
      data: any;
    };
    if (!location || !location.data || !location.data.getLocation)
      throw new Error('No location found');

    return location.data.getLocation.posts || [];
  } catch (err) {
    return null;
  }
}

export async function createPostApi(postId: string, createData: any) {
  try {
    const createPost = await API.graphql<GraphQLQuery<any>>({
      query: mutations.createPost,
      variables: {
        input: {
          id: postId,
          ...createData,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    console.log('createPost', createPost);
    if (!createPost.data) throw new Error('Error create post');

    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

export async function deletePostApi(postId: string) {
  try {
    const deletePost = await API.graphql<GraphQLQuery<any>>({
      query: mutations.deletePost,
      variables: {
        input: {
          id: postId,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });

    if (!deletePost.data) throw new Error('Error delete post');

    console.log('post', deletePost);
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
}

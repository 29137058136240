/* eslint-disable react/style-prop-object */
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function OrdersDayIllustration({ ...other }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 1080 1080"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="m389.3,833.4l304.4,37.9c47.6,5.9,91.1-27.9,97-75.5l44.5-357c6.2-49.8-29.1-95.2-78.9-101.4l-311.4-38.8c-40.8-5.1-78.1,23.9-83.1,64.7l-46.7,374.7c-5.8,46.8,27.4,89.5,74.2,95.4"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m358.4,829.5l304.4,37.9c47.6,5.9,91.1-27.9,97-75.5l44.5-357c6.2-49.8-29.1-95.2-78.9-101.4l-311.4-38.8c-40.8-5.1-78.1,23.9-83.1,64.7l-46.7,374.7c-5.8,46.9,27.4,89.6,74.2,95.4"
        style={{ fill: PRIMARY_MAIN }}
      />
      <path
        d="m802,453.6s-52.5,369.2-112.4,402.8c0,0-315.5-64.3-423.6-68.7l60.9-396.1,475.1,62h0Z"
        style={{ fill: '#f4f4f5' }}
      />
      <path
        d="m326.9,391.7l475.1,62s-15.3,312.1-175.9,388.2c-7,3.3-15.1,4-22.6,1.9-55.9-15.1-308.6-82.4-426.5-95-6.6-.7-9.4-8.8-4.6-13.4,31.4-30.3,109-123.7,154.5-343.7"
        style={{ fill: '#fff' }}
      />
      <path
        d="m666.7,380.9c-2,10.1-9.3,17.1-16.2,15.7s-10.9-10.7-8.8-20.8,9.3-17.1,16.2-15.7c6.9,1.5,10.8,10.8,8.8,20.8"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m687.8,237.8c-36.8,0-66.7,37.5-66.7,83.6,0,28.8,11.7,54.1,29.4,69.1,2.7,2.3,6.6,2.5,9.5.4,0,0,5.4-3.4,6.4-11.5.7-5.4-1.2-10.9-4.7-15.2-8.5-10.5-14-26-14-42.9,0-30.9,18.4-57,40.2-57,11.4,0,21.9,7.2,29.3,18.5l18.3-19.9c-12.2-15.5-29-25.1-47.7-25.1"
        style={{ fill: '#fff' }}
      />
      <path
        d="m727,333.8l26.3,3.3c.8-5.1,1.2-10.4,1.2-15.8,0-22.7-7.3-43.4-19.1-58.5l-18.3,19.9c6.7,10.2,10.9,23.8,10.9,38.5,0,4.4-.4,8.6-1,12.6"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m739.7,393.6c-2,10.1-9.3,17.1-16.2,15.7s-10.9-10.7-8.8-20.8,9.3-17.1,16.2-15.7,10.9,10.7,8.8,20.8"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m760.9,250.4c-36.8,0-66.7,37.5-66.7,83.6,0,28.8,11.7,54.1,29.4,69.1,2.7,2.3,6.6,2.5,9.5.4,0,0,5.4-3.4,6.4-11.5.7-5.4-1.2-10.9-4.7-15.2-8.5-10.5-14-26-14-42.9,0-30.9,18.4-57,40.2-57,11.4,0,21.9,7.2,29.3,18.5l18.3-19.9c-12.2-15.4-29.1-25.1-47.7-25.1"
        style={{ fill: '#fff' }}
      />
      <path
        d="m796.5,352.5s4.3,2.5,11.2,9.1c7,6.6,10.3,11.7,10.3,11.7,9.6-15.4,9.6-33.9,9.6-39.3,0-22.7-7.3-43.4-19.1-58.5l-18.3,19.9c6.7,10.2,10.9,23.8,10.9,38.5-.1,4.4-2,14.6-4.6,18.6"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m414.1,349.3c-2,10.1-9.3,17.1-16.2,15.7-6.9-1.4-10.9-10.7-8.8-20.8s9.3-17.1,16.2-15.7c6.9,1.4,10.9,10.7,8.8,20.8"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m435.3,206.1c-36.8,0-66.7,37.5-66.7,83.6,0,28.8,11.7,54.1,29.4,69.1,2.7,2.3,6.6,2.5,9.5.4,0,0,5.4-3.4,6.4-11.5.7-5.4-1.2-10.9-4.6-15.2-8.5-10.5-14-26-14-42.9,0-30.9,18.4-57.1,40.2-57.1,11.4,0,21.9,7.2,29.3,18.5l18.3-19.9c-12.3-15.3-29.2-25-47.8-25"
        style={{ fill: '#fff' }}
      />
      <path
        d="m474.4,302.2l26.4,3.3c.8-5.1,1.2-10.4,1.2-15.8,0-22.7-7.3-43.4-19.1-58.4l-18.3,19.9c6.7,10.2,10.9,23.8,10.9,38.5-.1,4.3-.4,8.4-1.1,12.5"
        style={{ fill: '#bdc0c2' }}
      />
      <path
        d="m487.2,362c-2,10.1-9.3,17.1-16.2,15.7-6.9-1.4-10.9-10.7-8.8-20.8s9.3-17.1,16.2-15.7c6.9,1.4,10.8,10.7,8.8,20.8"
        style={{ fill: PRIMARY_DARK }}
      />
      <path
        d="m508.3,218.8c-36.8,0-66.7,37.5-66.7,83.6,0,28.8,11.7,54.1,29.4,69.1,2.7,2.3,6.6,2.5,9.5.4,0,0,5.4-3.4,6.4-11.5.7-5.4-1.2-10.9-4.6-15.2-8.5-10.5-14-26-14-42.9,0-30.9,18.4-57.1,40.2-57.1,11.4,0,21.9,7.2,29.3,18.5l18.3-19.9c-12.3-15.4-29.1-25-47.8-25"
        style={{ fill: '#fff' }}
      />
      <path
        d="m547.2,311.3l27.1,3.4c.7-5.6.7-10.1.7-12.3,0-22.7-7.3-43.4-19.1-58.4l-18.3,19.9c6.7,10.2,10.9,23.8,10.9,38.5,0,2-.5,5.4-1.3,8.9"
        style={{ fill: '#bdc0c2' }}
      />
      <path d="m475.87,495.65l-50.31,190.22-37.97-9.39,34.69-131.18-24.68-6.1,7.14-26.98c1.96.21,3.67.34,5.07.37,7.88.18,14.81-1.65,20.8-5.48,4.1-2.62,7.72-6.57,10.87-11.85,1.85-3.17,3-5.59,3.44-7.26l30.95,7.65Z" />
      <path d="m524.04,623.69c22.56-7.62,37.38-13.42,44.47-17.39,10.91-6.23,17.64-14.38,20.22-24.45,2.1-8.2,1.34-15.66-2.28-22.37-3.62-6.71-10.1-11.27-19.42-13.65-12.77-3.27-22.57-1.16-29.39,6.3-3.93,4.31-7.53,11.84-10.8,22.59l-40.81-10.43c4.9-16.31,11.6-28.79,20.1-37.44,16.15-16.47,38.53-21.05,67.14-13.73,22.61,5.78,39.15,16.06,49.62,30.84,10.46,14.78,13.31,31.53,8.52,50.24-3.67,14.35-11.65,25.88-23.95,34.6-8.09,5.81-20.38,11.45-36.88,16.92l-19.59,6.52c-12.26,4.08-20.72,7.14-25.37,9.18-4.66,2.04-8.82,4.72-12.5,8.05l93.25,23.85-8.58,33.55-146.29-37.41c3.95-13.8,10.48-25.66,19.61-35.57,9.29-11.39,26.93-21.45,52.92-30.19Z" />
    </Box>
  );
}

export default memo(OrdersDayIllustration);

import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/hooks';
import { useSettingsAuthContext } from 'src/auth/settings';
import { NavListProps } from 'src/components/nav-section';

const overviewPath = (user: any, selectedLocation: any, t: any, onUpdate: any) => {
  const locPath: { subheader: string; items: NavListProps[] } = {
    subheader: t('overview'),
    items: [
      {
        title: 'QR Code',
        icon: <Iconify icon="tabler:qrcode" />,
        path: paths.dashboard.qrcode.root,
      },
    ],
  };

  if (user && user.locations && selectedLocation && selectedLocation.locationId) {
    let select = user.locations.find((l: any) => l.locationId === selectedLocation.locationId);
    if (!select) {
      onUpdate('selectedLocation', {
        businessId: user.locations[0].businessId,
        locationId: user.locations[0].locationId,
      });

      select = user.locations[0];
    }
    let home: NavListProps;
    if (user.locations.length > 1) {
      const child = user.locations.map((l: any) => ({
        title: l.locationName,
        path:
          select.locationId === l.locationId
            ? paths.dashboard.root
            : paths.dashboard.location.select(l.businessId, l.locationId),
      }));
      home = {
        title: 'Home',
        path: paths.dashboard.location.select(select.businessId, select.locationId),
        icon: <Iconify icon="tabler:home" />,
        children: child,
      };
    } else {
      home = {
        title: 'Home',
        path: paths.dashboard.location.select(select.businessId, select.locationId),
        icon: <Iconify icon="tabler:home" />,
      };
    }

    locPath.items.unshift(home);
  }

  return locPath;
};

export function Role0190Navigation() {
  const { user } = useAuthContext();
  const authSettings = useSettingsAuthContext();
  const { t } = useLocales();

  const data: { subheader: string; items: NavListProps[] }[] = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------

      overviewPath(user, authSettings.selectedLocation, t, authSettings.onUpdate),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, authSettings.selectedLocation]
  );

  return data;
}

import { useAuthContext } from 'src/auth/hooks';
import RoleTypes from 'src/auth/roles';
import { Role0100Navigation } from './navigation/role_0100-navigation';
import { Role0190Navigation } from './navigation/role_0190-navigation';
import { Role0200Navigation } from './navigation/role_0200-navigation';

export function useNavData() {
  const { user } = useAuthContext();

  console.log('user', user);

  switch (user?.role) {
    case RoleTypes.ROLE_0100:
      return Role0100Navigation();
    case RoleTypes.ROLE_0190:
      return Role0190Navigation();
    case RoleTypes.ROLE_0200:
      return Role0200Navigation();
    default:
      return Role0100Navigation();
  }
}

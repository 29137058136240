import { createContext, useContext } from 'react';
//
import { SettingsAuthContextProps } from '../types';

// ----------------------------------------------------------------------

export const SettingsAuthContext = createContext({} as SettingsAuthContextProps);

export const useSettingsAuthContext = () => {
  const context = useContext(SettingsAuthContext);

  if (!context) throw new Error('useSettingsAuthContext must be use inside SettingsAuthProvider');

  return context;
};
